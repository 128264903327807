// Libraries
import React, { useContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../settings/AxiosSetup';

// components
import { SET_ALL as SET_ALL_COMMON } from '../context/actions/commonActions';
import { setData, getData } from '../functions/storage';

// Global
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_CART } from '../context/actions/winkelActions';

// Functions
// Waarde van een useState variabele krijgen als dit vanuit een useEffect gevonden moet worden
export const __getCurrentHookValue = async (setHookFunction) => {
  return new Promise((resolve) => {
    setHookFunction(prev => {
      resolve(prev)
      return prev;
    })
  })
};

  //Opent de url in een nieuw tablad, als deze url geen # is
  export const openUrlNewTab = (url) => {
    if(url == "#"){
      return false;
    }
    window.open(url, "_blank");
  }

export const apiSetter = async (jsonData) => {
    const cartDispatch = useContext(GlobalDispatcher).cartDispatch
    //// console.log(jsonData);
    const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';

    const response = await api.get('winkelwagen?code=' + code + '&json=' + encodeURIComponent(JSON.stringify(jsonData)));
    // console.log(response.data);
    if (response.status == 401) {
        try {
            const response = await api.post('uitloggen?code=' + code);
            // console.log(response.data);
            if (response.status === 401) {
              await AsyncStorage.setItem('ingelogd', 'false');
              await AsyncStorage.removeItem('bearerToken');
              await AsyncStorage.removeItem('cartItems');
              navigation.push('EersteScherm');
            }
            if (response.status == 429) {
                // console.log('Overloaded, wait a couple of seconds and try again.');
                alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
            } else if (response.data.success) {
              await AsyncStorage.setItem('ingelogd', 'false');
              await AsyncStorage.removeItem('bearerToken');
              await AsyncStorage.removeItem('cartItems');
              navigation.push('EersteScherm');
            };
        }
        catch (err) {
            // console.log(err);
        };
        return;
    } else if (response.status == 429) {
        // console.log('Overloaded, wait a couple of seconds and try again.');
        alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
        return;
    } if (response.data.success) {
        //// console.log('Gelukt');
        const winkelwagen = response.data.data;
        // console.log(winkelwagen);

        cartDispatch({ type: SET_CART, value: winkelwagen });
        //return winkelwagen;
    } else {
        // console.log('Niet gelukt')
    }
}

// vervangen van variabelen in teksten
// alles tussen [] wordt vervangen door de mee gegeven variabeln
// op volgorde waarin ze staan
export const textReplace = ( text, arrVars ) => {

  let newText = "";
  let tmpArr = text.split('[');

  for ( var i = 0; i < tmpArr.length; i++ ){
    let tmp = tmpArr[i];
    // de rest van de placeholder verwijderen
    let n = tmp.indexOf(']');
    if (n) tmp = tmp.substr( n + 1 ); 

    if ( i == tmpArr.length - 1){
      //laatste element, niets meer toevogen
      newText += tmp;
    }
    else {
      if( i < arrVars.length ){
        newText += tmp + arrVars[ i ];
      }
      else {
        newText += tmp + '[ onvoldoende variabelen ]';        
      }
    }
  }
  return newText;
}

export const apiFavoriet = async (deal_id) => {
    // console.log("set favoriet op deal: " + deal_id);
    //Alleen winkelwagen api uitvoeren als de gebruiker is ingelogd
    const loggedIn = await AsyncStorage.getItem('ingelogd');
    if(loggedIn === 'true'){
        const response = await api.post('favoriet?code=L3Ks2@d$FD5FD@$dsqJ9jzQS$2za2!sS^dsA9@zLh$jfSA5rz&deal_id=' + deal_id);
        // console.log(response.data);
      if (response.status === 401) {
        await AsyncStorage.setItem('ingelogd', 'false');
        await AsyncStorage.removeItem('bearerToken');
        navigation.push('Home');
      }
      if (response.status == 429) {
        // console.log('Overloaded, wait a couple of seconds and try again.');
        alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
        return;
      }
    }
}

// van deze array, maken we een object
// gebaseerd op scherm.onderdeel
export const adjustHelpTekst = ( text ) => {

  var obj = {};

  for ( var i=0; i < text.length; i++ ) {
    let screen = text[i].txt_screen;
    let name = text[i].txt_name;
    let nameAudio = text[i].txt_name + 'Audio';
    let val = text[i].txd_text;
    let audio = text[i].txd_audio_url;

    var scr = { ...obj[screen], [name]: val, [nameAudio]: audio }
    obj = { ...obj, [screen]: scr };
  }

  return obj;
}

export const adjustFaq = ( faq ) => {
  var newArray = [];

  for( var i = 0; i < faq.length; i++ ){
    let categorie = faq[i].fce_naam;
    let vragen = faq[i].faqs;
    var src = {questions: vragen }

    newArray.push({categorie: categorie, src});
  }

  return newArray;  
}

// maakt een array met delen van de oorspronkelijke
// array met maximaal het aantal aangegeven elementen
export const arrangeArray = (array, maxElements) => {

  var arrayIn = [ ...array, array[0] ];
  var newArray = [];

  for( var i = 0; i < arrayIn.length; i+=maxElements){
    newArray.push(arrayIn.slice(i, i + maxElements))
  }

  return newArray;
}


// breidt de url eventueel uit met de basis url voor de server
// mag een enkele uri zijn of een array
export const adjustUri = ( uri ) => {

  if ( !Array.isArray( uri ) ){
    return adjustUriHelper ( uri );
  }

  // Het is een array, dus alle elementen aanpassen
  for ( var i = 0; i < uri.length; i++ ){
    uri[i] = adjustUriHelper ( uri[i] );
  }
  return uri;
}

  // alleen voor interne aaanroepen
  // past een enkele uri aan
  const adjustUriHelper = ( uri ) => {
    // controleren of het een object is
    if ( typeof uri === 'object' ){
      uri = uri.img_url;
    }
    // als uri al kompleet is, deze terugsturen
    if ( uri.toLowerCase().startsWith( global.app_url ) ) return uri;
    if ( uri.toLowerCase().startsWith( 'file' ) ) return uri;

    // checken of uri met / begint
    if ( !uri.startsWith( '/') ) uri = "/" + uri;global.app_url
    // basis url toevoegen
    return global.app_url + uri;
  }