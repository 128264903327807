import React, { useContext, useMemo, useState } from 'react';
import { Text, View, Image, TouchableOpacity, Picker} from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import api from '../settings/AxiosSetup';

// Components
import IconSetter from './iconSetter';

// Styling
import Colors from '../styles/colors';

// Functions 
import { apiSetter } from '../functions/common';

// Global
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_CART, SET_TOTAAL_BEDRAG } from '../context/actions/winkelActions';

const OrderItem = ({ title, image, abboMelding, price, orderDate, minimaleLeeftijdMelding, boughtFor, variatieNaam, aantal, id, bestelBool, maxAantal, aantalProduct, navigation, variatie_id, setApiTrigger, index }) => {

    const cartContext = useContext(GlobalContext).cartState;
    const cartDispatch = useContext(GlobalDispatcher).cartDispatch;

    //let editableArray = cartItems.winkelwagen;
    //let sponsor_bedrag = cartItems.sponsor_bedrag;

    const aantalOpties = [1,2,3,4,5,6,7,8,9,10];
    const [selectedValue, setSelectedValue] = useState(aantal);
    const { styles, deviceSize } = useStyles();

    //// console.log(setApiTrigger);
    // console.log("test");

    const deleteItem = (index) => {
        let cartItems = cartContext.cartItems;
        // console.log(cartItems);

        cartItems.winkelwagen.splice(index, 1);
        /*  
        editableArray.map((item) => {
            if (item.product_id === id) {
                if (item.variatie_id === variatie_id) {
                    let cartIndex = editableArray.indexOf(item);
                    editableArray.splice(cartIndex, 1);
                }
            }
        })*/

        /*
        let completeCart = {
            winkelwagen: editableArray,
            sponsor_bedrag: sponsor_bedrag
        }*/

        // console.log("Delete item");
        // console.log(cartItems);

        setWinkelwagen(cartItems);

        //cartDispatch({ type: SET_CART, value: cartItems})
        //setApiTrigger();
        //navigation.push('Winkelmand');
    }

    const saveAantal = (aantal, index) => {
        if(bestelBool){
            return;
        }
        let cartItems = cartContext.cartItems;
        // console.log(cartItems);

        cartItems.winkelwagen[index].aantal = parseInt(aantal);

        /*
        editableArray.map((item) => {
            if(item.product_id === id){
                if(item.variatie_id === variatie_id){
                let cartIndex = editableArray.indexOf(item);
                editableArray[cartIndex].aantal = parseInt(selectedValue);
                }
            }
        })*/

        //cartItems.winkelwagen.splice(index, 1);
        /*
        let completeCart = {
            winkelwagen: editableArray,
            sponsor_bedrag: sponsor_bedrag
        }

        // console.log("save aantal");
        // console.log(completeCart);*/
        //apiSetter(cartItems);
        setWinkelwagen(cartItems);


        //setApiTrigger();
        
        setSelectedValue(aantal);
        //cartDispatch({ type: SET_CART, value: cartItems})
    }

    /*useMemo(() => {
        saveAantal()
    },[selectedValue]);*/

    const setWinkelwagen = async (cartData) => {
        const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
        const response = await api.get('winkelwagen?code=' + code + '&json=' + encodeURIComponent(JSON.stringify(cartData)));
        // console.log(response.data);
        if (response.status == 401) {
            try {
                const response = await api.post('uitloggen?code=' + code);
                // console.log(response.data);
                if (response.status === 401) {
                    await AsyncStorage.setItem('ingelogd', 'false');
                    await AsyncStorage.removeItem('bearerToken');
                    await AsyncStorage.removeItem('cartItems');
                    navigation.push('EersteScherm');
                }
                if (response.status == 429) {
                    // console.log('Overloaded, wait a couple of seconds and try again.');
                    alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
                } else if (response.data.success) {
                    await AsyncStorage.setItem('ingelogd', 'false');
                    await AsyncStorage.removeItem('bearerToken');
                    await AsyncStorage.removeItem('cartItems');
                    navigation.push('EersteScherm');
                };
            }
            catch (err) {
                // console.log(err);
            };
            return;
        } else if (response.status == 429) {
            // console.log('Overloaded, wait a couple of seconds and try again.');
            alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
            return;
        } if (response.data.success) {
            //// console.log('Winkelwagen opslaan gelukt');
            const winkelwagen = response.data.data;
            //// console.log(winkelwagen);

            cartDispatch({ type: SET_CART, value: winkelwagen });
            //return winkelwagen;
        } else {
            // console.log('Niet gelukt')
        }
    }

    return(
        <View style={styles('card')}>
            <View style={styles('imageColumn')}>
                <Image source={{ uri: global.app_url + image }} style={styles('imageStyle')}/>
            </View>
            <View style={styles('productInfo')}>
                <Text style={styles('title')}>{title}</Text>
                <View style={styles('orderMeta')}>
                    { bestelBool ? <Text style={styles('meta')}>Besteld op: {orderDate}</Text> : null }
                    { boughtFor ? <Text style={styles('meta')}>Gekocht voor: {boughtFor}</Text> : null }
                    { variatieNaam ? <Text style={styles('meta')}>Gekozen variatie: {variatieNaam}</Text> : null }
                    { abboMelding ? <Text style={{ fontSize: 16, color: 'red', marginVertical: 6}}>{abboMelding}</Text> : null}
                    { minimaleLeeftijdMelding ? <Text style={{ fontSize: 16, color: 'red', marginVertical: 6}}>{minimaleLeeftijdMelding}</Text> : null}
                </View>
            </View>
            <View style={styles('productPrice')}>
                {aantal ?                         <Picker
                            selectedValue={selectedValue}
                            style={styles('pickerStyle')}
                            onValueChange={(itemValue, itemIndex) => saveAantal(itemValue, index)}
                        >
                        
                        { aantalOpties ?
                            aantalOpties.slice(0, maxAantal).map((item, index) => {
                                return <Picker.Item 
                                    key={index} 
                                    label={item}
                                    value={item}
                                />
                            }) : null
                        }
                        </Picker>: null}
                        {bestelBool ? <Text style={[styles('aantal'), {marginRight: 10}]}>{'Aantal: ' + aantalProduct }</Text> : null}
                <View style={{ minWidth: 40, justifyContent: 'center'}}><Text style={styles('price')}>{price}</Text></View>
               {bestelBool ? null : <TouchableOpacity onPress={() => deleteItem(index)} style={{ justifyContent: 'center', marginLeft: 10}}><IconSetter family='Fontello' name='close' size={16} color={'red'}/></TouchableOpacity>}
            </View>
        </View>
    );
};

const useStyles = CreateResponsiveStyle(
    {
        card: {
            width: '100%',
            borderWidth: 1,
            borderColor: '#7F959F',
            marginBottom: 20
        },
        imageColumn: {
            width: '100%',
            padding: 10,
            alignItems: 'center',
            position:'relative'
        },
        imageStyle: {
            width: '100%',
            height: 150,
            resizeMode: 'contain'
        },
        productInfo: {
            paddingHorizontal: 35,
        },
        orderMeta: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'flex-start'
        },
        title: {
            fontSize: 21,
            fontWeight: 'bold'
        },
        meta: {
            fontSize: 16,
            marginVertical: 6,
            marginRight: 35,
            color: '#5A5A5A'
        },
        productPrice: {
            padding: 20,
            flexDirection: 'row',
            textAlign: 'right',
            justifyContent: 'center',
            width: '60%'
        },
        price: {
            fontSize: 21,
            fontWeight: 'bold',
            alignSelf: 'center'
        },
        aantal: {
            fontSize: 18,
            fontWeight: '500',
            alignSelf: 'center'
        },
        pickerStyle: {
            height: 50, 
            width: '40%', 
            padding: 10, 
            marginRight: 10 
        }
    },
    {
        [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
        },
        [DEVICE_SIZES.MEDIUM_DEVICE]: {
            card: {
                width: '100%',
                borderWidth: 1,
                borderColor: '#7F959F',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20
            },
            imageColumn: {
                width: '15%',
                borderRightWidth: 1,
                borderColor: '#7F959F',
            },
            productInfo: {
                width: '50%'
            },
            productPrice: {
                paddingRight: 35,
                justifyContent: 'flex-end',
                width: '35%'
            },
            pickerStyle: {
                height: 50, 
                width: '30%', 
                padding: 10, 
                marginRight: 10 
            }
        },
        [DEVICE_SIZES.LARGE_DEVICE]: {
            card: {
                width: '100%',
                borderWidth: 1,
                borderColor: '#7F959F',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20
            },
            imageColumn: {
                width: '15%',
                borderRightWidth: 1,
                borderColor: '#7F959F',
            },
            productInfo: {
                width: '50%'
            },
            productPrice: {
                paddingRight: 35,
                textAlign: 'right',
                justifyContent: 'flex-end',
                width: '35%'
            },
            pickerStyle: {
                height: 50, 
                width: '20%', 
                padding: 10, 
                marginRight: 10 
            }
        },
        [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
            card: {
                width: '100%',
                borderWidth: 1,
                borderColor: '#7F959F',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20
            },
            imageColumn: {
                width: '10%',
                borderRightWidth: 1,
                borderColor: '#7F959F',
            },
            productInfo: {
                width: '60%',
            },
            productPrice: {
                paddingRight: 35,
                textAlign: 'right',
                justifyContent: 'flex-end',
                width: '30%'
            },
            pickerStyle: {
                height: 50, 
                width: '20%', 
                padding: 10, 
                marginRight: 10 
            }
        },
    },
  )
  

export default OrderItem;
