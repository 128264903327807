import React, { useState, useCallback, useRef, useEffect, Component, createRef, useContext } from 'react';
import { View, StyleSheet, Text, Image, TouchableOpacity, Button, Dimensions } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as FileSystem from 'expo-file-system'
import * as ImagePicker from 'expo-image-picker';
import IconSetter from './iconSetter';

// Global
import { GlobalDispatcher, GlobalContext } from '../context/globalState';

//Icons
import { FontAwesome } from '@expo/vector-icons'; 
 
//Slider
import Slider from '@react-native-community/slider';

//Cropper
import AvatarEditor from 'react-avatar-editor';

// Styles
import Colors from '../styles/colors';
  
export default function ImageCrop({ showUserData, imageFile, croppedImage, setCropImage, setCroppedImage, voornaam, achternaam }) {

    const commonState = useContext( GlobalContext ).commonState;
    const userDispatch = useContext( GlobalDispatcher ).userDispatch;
    const [sizeError, setFileSizeError] = useState(false);
    const [changeImage, setChangeImage] = useState(false);
    const [image, setImage] = useState(imageFile);
    const [uploaded, setUploaded] = useState(false);
    const [canvasImage, setCanvasImage] = useState(croppedImage);
    const pasFotoHeight = commonState.afbeeldingHoogtePasfoto || 270;
    const pasFotoWidth = commonState.afbeeldingBreedtePasfoto || 210;
    const pickImage = async () => {

        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          base64: true,
          aspect: [4, 3]
        });


        if (!result.cancelled) {
            //Checking filesize for max 4MB
            const fileSize = result.uri.length * (3 / 4) - 2;
            // console.log(fileSize);
            if (fileSize > 4000000) {
                setFileSizeError(true);
            } else {
                setFileSizeError(false);
                setImage(result.uri);
                setCropImage(result.uri);
            }
        }   
    };

    //Variable for styles library
    const { styles, deviceSize } = useStyles();

    const cropperRef = createRef();
    const onClickSave = () => {
        if (cropperRef.current) {
          const canvas = cropperRef.current.getImage();
          const imageBase64 = canvas.toDataURL();
          setCanvasImage(imageBase64);
          setCroppedImage(imageBase64);
        }
      }

    const [zoom, setZoom] = useState(1);

      return (
        <View style={styles('imageUpload')}>
            {
                showUserData ?
                <View style={styles('cardInfo')}>
                    <Text style={styles('cardTitle')}>Zwemkaart 't Hemmerven</Text>
                    <Text style={styles('userData')}>Voornaam: {voornaam}</Text>
                    <Text style={styles('userData')}>Achternaam: {achternaam}</Text>
                    <Text style={{ marginTop: 20, color: '#6e6e6e' }}>1. Kies voor wijzig foto</Text>
                    <Text style={{ color: '#6e6e6e' }}>2. Kies het juiste gedeelte van de foto voor de pasfoto</Text>
                    <Text style={{ color: '#6e6e6e' }}>3. Klik op 'Foto Akkoord'</Text>
                </View>
                :
                null
            }
            <View>
                {
                    canvasImage ?
                    <View>
                        {uploaded ? <View style={{ zIndex: 99999, backgroundColor: '#00C853', borderRadius: 20, height: 30, width: 30, alignItems: 'center', justifyContent: 'center', position: 'absolute', right: 10, bottom: 55}}><IconSetter name='check' family='FontAwesome5' size={21} color='white'/></View> : null}
                        <Image style={{width: pasFotoWidth, height: pasFotoHeight, resizeMode: 'contain', borderWidth: 1, borderColor: '#62bae3'}} source={{uri: canvasImage}}/>
                        <TouchableOpacity 
                            onPress={() => {setCanvasImage(''), setImage(''), pickImage(), setUploaded(false)}}
                            style={styles('buttonChangeImage')}    
                        >
                            <Text style={styles('changeImageText')}><FontAwesome name="pencil-square-o" size={16} color="white" />Foto veranderen</Text>
                        </TouchableOpacity>
                    </View>
                    :
                    <View>
                    {
                        (!canvasImage && image) ?
                        <View style={styles('cropper')}>
                        <AvatarEditor
                            ref={cropperRef}
                            image={image}
                            width={pasFotoWidth}
                            height={pasFotoHeight}
                            border={1}
                            color={[98, 186, 227, 1]}
                            scale={zoom}
                            rotate={0}
                        />
                        <View style={styles('zoomSlider')}>
                            <Text>-</Text>
                            <Slider
                                style={{width: '100%', height: 40}}
                                minimumValue={1}
                                maximumValue={2}
                                minimumTrackTintColor="#6897ad"
                                maximumTrackTintColor="#62bae3"
                                onValueChange={(e) => setZoom(e)}
                            />
                            <Text>+</Text>
                        </View>
                        <View>
                            <TouchableOpacity onPress={() => {
                                onClickSave();
                                setUploaded(true);
                            }} style={styles('saveButton')}>
                                <Text style={styles('saveText')}>Foto Akkoord</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    :
                    <View style={styles('preview'), { width: pasFotoWidth, height: pasFotoHeight}}>
                        <TouchableOpacity onPress={() => {pickImage()}}>
                            <View style={{flexDirection :'row', alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={styles('previewText')}>Pasfoto voorbeeld</Text>
                            <Text style={{fontSize: 16, color: 'red'}}> *</Text>
                            </View>
                            <Text style={styles('uploadButton')}>Upload foto (max. 4 MB)</Text>
                        </TouchableOpacity>
                    </View>

                    }
                    </View>
                }
                {
                    sizeError ?
                    <Text style={styles('error')}>De maximale bestandsgrootte is 4 MB</Text>
                    :
                    null
                }
            </View>
          </View>
      );
  };
  

const useStyles = CreateResponsiveStyle(
    {
        imageUpload: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 5,
            padding: 25,
            borderWidth: 1,
            borderColor: 'black'
        },
        error: {
            color: 'red',
            marginTop: 10
        },
        cardInfo: {
            marginBottom: 25,
            maxWidth: 250
        },
        cardTitle: {
            color: '#62bae3',
            fontSize: 22,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginBottom: 15
        },
        userData: {
            fontSize: 18,
            fontWeight: 'bold',
            color: Colors[7],
            textTransform: 'uppercase',
            marginVertical: 5
        },
        previewText: {
            fontSize: 16,
            textAlign: 'center',
            color: Colors[7],
        },
        uploadButton: {
            textAlign: 'center',
            color: '#62bae3',
            fontSize: 16,
            marginTop: 10
        },
        zoomSlider: {
            maxWidth: 200,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        preview: {
            borderWidth: 1,
            padding: 10,
            borderColor: '#62bae3',
            backgroundColor: 'transparant',
            justifyContent: 'center',
            alignItems: 'center'
        },
        saveButton: {
            backgroundColor: '#00C853',
            padding: 10,
            width: '100%',
            textAlign: 'center'
        },
        saveText: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 14
        },
        buttonChangeImage: {
            marginTop: 10,
            backgroundColor: '#E21E34',
            padding: 10,
            width: '100%',
            textAlign: 'center'
        },
        changeImageText: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 14
        },
    },
    {
      [DEVICE_SIZES.MEDIUM_DEVICE]: {
        imageUpload: {
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
      },
      [DEVICE_SIZES.LARGE_DEVICE]: {
      },
      [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
        imageUpload: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
      },
    },
  )
