// Vaste kleuren
const Colors = [
  '#ffffff',
  '#022D41', 
  '#EFF8FC',
  '#ff9680',
  '#034541',
  '#5a92f6',
  '#43bdaf',
  '#022D41',
  '#62BAE3'
];

export default Colors;
