// Libraries
import React from 'react';

const fontfamilies = () => {
  return {
    'Regular': require('../fonts/Lato-Regular.ttf'),
    'Bold': require('../fonts/Lato-Bold.ttf'),
    'BoldItalic': require('../fonts/Lato-BoldItalic.ttf'),
    'Light': require('../fonts/Lato-Light.ttf'),
    'LightItalic': require('../fonts/Lato-LightItalic.ttf'),
    'Black': require('../fonts/Lato-Black.ttf'),
    'BlackItalic' : require('../fonts/Lato-BlackItalic.ttf'),
    'Italic' : require('../fonts/Lato-Italic.ttf'),
    'Thin': require('../fonts/Lato-Thin.ttf'),
    'ThinItalic' : require('../fonts/Lato-ThinItalic.ttf')
  }
};
  
export default fontfamilies;

