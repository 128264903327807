// Libraries
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity, TouchableWithoutFeedback, StyleSheet, Image, ActivityIndicator } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import api from '../settings/AxiosSetup';
import RadioGroup from 'react-native-radio-buttons-group';
import * as ImagePicker from 'expo-image-picker';

// Components
import { HVButton, HVCheckbox, HVInput, AltButton } from '../components/formObjects';
import ImageCrop from '../components/imageCrop';
import DropDownHeader from '../components/dropDown';
import IconSetter from '../components/iconSetter';

// Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState';
import { SET_PASFOTO, SET_PASFOTO_TO_CROP } from '../context/actions/commonActions';
import { SET_IMAGE } from '../context/actions/userActions';

// Styles
import Colors from '../styles/colors';


// SVG images
export default function GegevensAanvullen({ navigation, route}) {
    const params = route.params;
    var refresher = 0;
    if (params){
        refresher = params.refresher;
    }
    const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [secureToggle, setSecureToggle] = useState(true);
    const [secureToggleTwee, setSecureToggleTwee] = useState(true);
    const [password, setPassword] = useState('');
    const userDispatch = useContext( GlobalDispatcher ).userDispatch;
    const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
    const userCommon = useContext(GlobalContext).userState;
    const [posting, setPosting] = useState(false);
    const [droppedB, setDroppedB] = useState(false);
    const [value, setValue] = useState(0);
    const [disabled, setDisabled] = useState(false);

    // UserVariables
    const [userId, setUserId] = useState(null);
    const [gezinsNaam, setGezinsNaam] = useState('');
    const [image, setImage] = useState(null);
    const [telefoon, setTelefoon] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [surName, setSurName] = useState('');
    const [birthDate, setBirthDate] = useState('');

    // AdresGegevens
    const [straatnaam, setStraatnaam] = useState('');
    const [huisnummer, setHuisnummer] = useState('');
    const [toevoeging, setToevoeging] = useState('');
    const [postcode, setPostcode] = useState('');
    const [woonplaats, setWoonplaats] = useState('');

    const onChange = (e, whatToSet, regex) => {
        const input = e;
        if (regex.test(input) || input === "") {
            whatToSet(input);
        }
    };

    useEffect(() => {
        if(refresher === 1) {
            // console.log('mooi');
            setTimeout(() => {
              location.reload();
            }, 200);
          }
    },[])

    const fetchRegistreerData = async () => {
        const registered = await AsyncStorage.getItem('registered');
        const voornaam = await AsyncStorage.getItem('voornaam');
        const achternaam = await AsyncStorage.getItem('achternaam');
        const geboortedatum = await AsyncStorage.getItem('geboortedatum');
        const email = await AsyncStorage.getItem('email');
        setEmail(email);
        // console.log(registered, voornaam, achternaam, geboortedatum, email);
    }
    const fetchUserId = async () => {
        let userId = await AsyncStorage.getItem('userId');
        setUserId(userId);
        // console.log(userId);
    }
    useEffect(() => {
        fetchRegistreerData();
        fetchUserId();
    }, [])


    const radioButtonsData = [{
        id: 1, // acts as primary key, should be unique and non-empty string
        borderColor: Colors[7],
        size: 21,
        color: Colors[8],
        label: 'Ja',
        value: 1,
        selected: false
    }, {
        id: 2,
        borderColor: Colors[7],
        size: 21,
        color: Colors[8],
        label: 'Nee',
        value: 0,
        selected: false
    }]

    const [radioGroupOne, setRadioButtons] = useState(radioButtonsData);

    function onPressRadioButton(radioButtonsArray) {
        // console.log(radioButtonsArray);
        radioButtonsArray.map((options, index) => {
            if (options.selected) {
                setValue(options.value);
            }
        })
        setRadioButtons(radioButtonsArray);
    }

    const pickImage = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            base64: true,
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [5, 3],
            quality: 1,
        });

        // console.log(result);

        if (!result.cancelled) {
            setImage(result.uri);
        }
    };

    const [pasfoto, updatePasfoto] = useState('');
    const [croppedImage, updateCroppedImage] = useState('');

    const setCropImage = async (data) => {
        updatePasfoto(data);
        commonDispatch({type: SET_PASFOTO_TO_CROP, value: data});
    }
    const setCroppedImage = async (data) => {
        updateCroppedImage(data);
        setImage(data);
        commonDispatch({type: SET_PASFOTO, value: data});
    }

    const postGegevensAanvullen = async () => {
        try {
            const response = await api.post('gegevens/aanvullen/'+ userId +'?code=' + code + '&telefoon=' + telefoon + '&postcode=' + postcode + '&huisnummer=' + huisnummer + '&toevoeging=' + toevoeging + '&straat=' + straatnaam + '&woonplaats=' + woonplaats + '&gezin=' + value + '&gezinsnaam=' + gezinsNaam, ({ pasfoto: image}));
            // console.log(response.data);
            if (response.status == 401) {
                try {
                    const response = await api.post('uitloggen?code=' + code);
                    // console.log(response.data);

                    if (response.status === 401) {
                        await AsyncStorage.setItem('ingelogd', 'false');
                        await AsyncStorage.removeItem('bearerToken');
                        await AsyncStorage.removeItem('cartItems');
                        navigation.push('EersteScherm');
                    }
                    if (response.status == 429) {

                        // console.log('Overloaded, wait a couple of seconds and try again.');
                        alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');

                    } else if (response.data.success) {
                        await AsyncStorage.setItem('ingelogd', 'false');
                        await AsyncStorage.removeItem('bearerToken');
                        await AsyncStorage.removeItem('cartItems');
                        navigation.push('EersteScherm');
                    };
                }
                catch (err) {
                    // console.log(err);
                };
                return;
            } else if (response.status == 429) {

                // console.log('Overloaded, wait a couple of seconds and try again.');
                alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
                return;

            } else if (response.data.success) {
                if (value === 0) {
                        userDispatch({ type: SET_IMAGE, value: image})
                        await AsyncStorage.setItem('telefoon', telefoon);
                        await AsyncStorage.setItem('postcode', postcode);
                        await AsyncStorage.setItem('huisnummer', huisnummer);
                        await AsyncStorage.setItem('toevoeging', toevoeging);
                        await AsyncStorage.setItem('straatnaam', straatnaam);
                        await AsyncStorage.setItem('woonplaats', woonplaats);
                        await AsyncStorage.setItem('registered', 'true');
                        await AsyncStorage.setItem('ingelogd', 'true');
                        await AsyncStorage.setItem('bearerToken', response.data.data);
                        navigation.push('shopScreen');
                        // navigation.push('EmailBevestigen', { email: email})
                } else {
                        userDispatch({ type: SET_IMAGE, value: image})
                        await AsyncStorage.setItem('telefoon', telefoon);
                        await AsyncStorage.setItem('postcode', postcode);
                        await AsyncStorage.setItem('huisnummer', huisnummer);
                        await AsyncStorage.setItem('toevoeging', toevoeging);
                        await AsyncStorage.setItem('straatnaam', straatnaam);
                        await AsyncStorage.setItem('woonplaats', woonplaats);
                        await AsyncStorage.setItem('gezin', JSON.stringify(value));
                        await AsyncStorage.setItem('gezinsnaam', gezinsNaam);
                        navigation.push('GezinToevoegen', {
                            newUser: true,
                            userId: userId
                        });
                }
            } else {
                setError(true);
                setErrorMessage(response.data.error);
            };
        }
        catch (err) {
            // console.log(err);
        }
    };

    const { styles, deviceSize } = useStyles();

    return (
        <View>
            <View style={styles('main')}>
                <View style={styles('register')}>
                    {posting ? <View style={{ alignSelf: 'center', justifyContent: 'center', top: '30%' }}>{successMessage ? <Text style={{ fontFamily: 'Regular', fontSize: 16, color: Colors[8], marginVertical: 20, textAlign: 'center', marginBottom: 40 }}>{successMessage}</Text> : <Text style={{ fontFamily: 'Regular', fontSize: 16, fontWeight: '700', color: Colors[8], marginVertical: 20, marginBottom: 40 }}>{'Posting...'}</Text>}</View> : <>
                        <Text style={{ fontFamily: 'Bold', fontSize: 18, fontWeight: '700', color: Colors[8], marginVertical: 20 }}>{'STAP 2: GEGEVENS AANVULLEN'}</Text>
                        
                        <ImageCrop
                            showUserData={false}
                            imageFile=''
                            croppedImage=''
                            setCropImage={setCropImage}
                            setCroppedImage={setCroppedImage}
                            voornaam=''
                            achternaam=''
                        />

                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7] }}>{'Telefoonnummer'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <View style={{ marginVertical: -5 }}>
                            <HVInput onChangeText={(e) => onChange(e, setTelefoon, /^[0-9._-]{0,11}$/)} value={telefoon} border={true} placeHolder={'Telefoonnummer'} width={'100%'} />
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7] }}>{'Postcode'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <View style={{ marginVertical: -5 }}>
                            <HVInput onChangeText={(e) => onChange(e, setPostcode, /^[A-Za-z0-9._-]{0,6}$/)} value={postcode} border={true} placeHolder={'Postcode'} width={'100%'} />
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7] }}>{'Huisnummer'}</Text>
                                <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                            </View>
                            <View>
                                <Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7], marginRight: 78 }}>{'Toevoeging'}</Text>
                            </View>
                        </View>
                        <View style={{ marginVertical: -5, flexDirection: 'row', justifyContent: 'space-between' }}>
                            <HVInput onChangeText={(e) => onChange(e, setHuisnummer, /^[0-9]+$/)} value={huisnummer} border={true} placeHolder={'Huisnummer'} width={'58%'} />
                            <HVInput onChangeText={(input) => setToevoeging(input)} value={toevoeging} border={true} placeHolder={'Toevoeging'} width={'38%'} />
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7] }}>{'Straatnaam'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <View style={{ marginVertical: -5 }}>
                            <HVInput onChangeText={(e) => onChange(e, setStraatnaam, /^[A-Za-z0-9._-_ ]+$/)} value={straatnaam} border={true} placeHolder={'Straatnaam'} width={'100%'} />
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7] }}>{'Woonplaats'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <View style={{ marginVertical: -5 }}>
                            <HVInput onChangeText={(e) => onChange(e, setWoonplaats, /^[A-Za-z_-_ ]+$/)} value={woonplaats} border={true} placeHolder={'Woonplaats'} width={'100%'} />
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7] }}>{'Gezin (je kunt bij stap 3 gezinsleden toevoegen)'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <RadioGroup
                            containerStyle={{ marginVertical: 10, right: 10 }}
                            radioButtons={radioGroupOne}
                            onPress={onPressRadioButton}
                            layout={'row'}
                        />
                        {value === 1 ? <>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7] }}>{'Gezins naam'}</Text>
                                <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                            </View>
                            <View style={{ marginVertical: -5 }}>
                                <HVInput onChangeText={(e) => onChange(e, setGezinsNaam, /^[A-Za-z]+$/)} value={gezinsNaam} border={true} placeHolder={'Gezinsnaam'} width={'100%'} />
                            </View>
                        </> : null}
                        <View style={{ marginTop: 10, marginBottom: 10 }}>
                        {(errorMessage === 'Pasfoto is verplicht.') ? setErrorMessage('Pasfoto is verplicht. Indien u al een pasfoto heeft gekozen dient u wellicht ook nog op foto akkoord te klikken voordat u door kunt gaan.') : null}
                        {error ? <Text style={{ fontWeight: '700', fontSize: 14, color: 'red' }}>{errorMessage}</Text> : null}
                        { disabled ? <ActivityIndicator color={'white'} style={{ position: 'absolute', top: 15, alignSelf: 'center', zIndex: 999}} size={20}/> : null }
                            <HVButton caption={disabled ? ' ' : value === 0 ? 'Registratie afronden' : 'Door naar stap 3: Gezin gegevens aanvullen'} color={Colors[8]} width={'100%'} onPress={async () => [
                                postGegevensAanvullen(),
                                setDisabled(true),
                                setTimeout(() => {
                                    setDisabled(false)
                                }, 2000)
                                ]} disabled={disabled}/>
                        </View>
                        <TouchableOpacity style={{ zIndex: 2, flexDirection: 'row', alignSelf: 'center', position: 'absolute', bottom: 15 }} onPress={() => navigation.push('Registreer', { backed: true})}>
                            <View style={{ marginTop: 6, marginRight: 5 }}>
                                <IconSetter name='back' family='Fontello' size={12} color={Colors[8]} />
                            </View>
                            <Text style={{ color: Colors[8], fontFamily: 'Regular', fontSize: 16, fontWeight: '300' }}>{'Vorige stap'}</Text>
                        </TouchableOpacity>
                    </>}
                </View>

            </View>
        </View>
    )
}

const useStyles = CreateResponsiveStyle(
    {
        main: {
            display: 'flex',
            alignItems: 'center',
        },
        register: {
            marginTop: 50,
            backgroundColor: 'white', 
            padding: 35,
            width: '100%',
            maxWidth: 500
        }
    },
    {
      [DEVICE_SIZES.MEDIUM_DEVICE]: {
        
      },
      [DEVICE_SIZES.LARGE_DEVICE]: {
        
      },
      [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
        
      },
    },
)