// Libraries
import React, { useState, useContext } from 'react';
import { View, Text, TouchableOpacity, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

// Components
import { HVButton } from './formObjects';

// Styles
import Colors from '../styles/colors';

export default function AankoopModal(props) {
    const isModalvisibleMock = props.isModalVisible;
    const artikelNaam = props.artikelNaam;
    const navigation = props.navigation;
    const setFalse = props.setVisible;
    const { styles, deviceSize } = useStyles();

    
    const reload = () => {
        // console.log("reload");
        location.reload();
    }
    
    return (
        <Modal isVisible={isModalvisibleMock}
            onSwipeComplete={setFalse}
            swipeDirection={["left", "right", "up", "down"]}
            onBackButtonPress={setFalse}>
            <TouchableWithoutFeedback style={styler.modalBackground} onPress={setFalse}>
                <View>
                    <View style={[styler.modalPosition]}>
                        <TouchableWithoutFeedback>
                            <View style={styles('modalView')}>
                                <View style={styles('col1')}> 
                                    <Text style={{ fontSize: 18, fontWeight: '700', color: Colors[7]}}>Het artikel is toegevoegd aan je winkelmand</Text>
                                    <Text style={{ fontSize: 18, fontWeight: '700', color: Colors[7]}}>{artikelNaam}</Text>
                                </View>
                                <View style={styles('col2')}>
                                <View style={{ width: '80%'}}><HVButton caption={'Verder naar bestellen'} color={Colors[8]} width={'100%'} onPress={() => [ setFalse(), navigation.push('Winkelmand')]} /></View> 
                                    <View style={{flexDirection: 'row', marginRight: '10%', marginTop: 5}}>
                                        <Text style={{ fontSize: 14, marginRight: 5}}>Of</Text>
                                        <TouchableOpacity onPress={() => reload()}> 
                                            <Text style={{ fontSize: 14, color: Colors[8]}}>{'verder winkelen'}</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                        </TouchableWithoutFeedback>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    )
}

const styler = StyleSheet.create({
    modalBackground: {
        flexGrow: 1,
        position: 'absolute',
    },
    modalView: {
        backgroundColor: '#FFFFFF',
        marginTop: 20,
        borderRadius: 5,
        padding: 40,
        flexDirection: 'row'
    },
    modalPosition: {
        width: "60%",
        alignSelf: 'center',
    },
});

const useStyles = CreateResponsiveStyle(
    {
     modalView: {
        backgroundColor: '#FFFFFF',
        marginTop: 20,
        borderRadius: 5,
        padding: 40,
        flexDirection: 'column'
    },
    col1: { flexDirection: 'column', justifyContent: 'space-between', width: '100%'},
    col2: { flexDirection: 'column', justifyContent: 'space-between', width: '100%'}
    },
    {
        [DEVICE_SIZES.SMALL_DEVICE]: {
            modalView: {
                backgroundColor: '#FFFFFF',
                marginTop: 20,
                borderRadius: 5,
                padding: 40,
                flexDirection: 'column'
            },
            col1: { flexDirection: 'column', justifyContent: 'space-between', width: '100%'},
            col2: { flexDirection: 'column', justifyContent: 'space-between', width: '100%'}
        },
      [DEVICE_SIZES.MEDIUM_DEVICE]: {
        modalView: {
            backgroundColor: '#FFFFFF',
            marginTop: 20,
            borderRadius: 5,
            padding: 40,
            flexDirection: 'column'
        },
        col1: { flexDirection: 'column', justifyContent: 'space-between', width: '100%'},
        col2: { flexDirection: 'column', justifyContent: 'space-between', width: '100%'}
      },
      [DEVICE_SIZES.LARGE_DEVICE]: {
        modalView: {
            backgroundColor: '#FFFFFF',
            marginTop: 20,
            borderRadius: 5,
            padding: 40,
            flexDirection: 'column',
        },
        col1: { flexDirection: 'column', justifyContent: 'space-between', width: '100%'},
        col2: { flexDirection: 'column', justifyContent: 'space-between', width: '100%'}
      },
      [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
        modalView: {
            backgroundColor: '#FFFFFF',
            marginTop: 20,
            borderRadius: 5,
            padding: 40,
            flexDirection: 'row',
        },
        col1: { flexDirection: 'column', justifyContent: 'space-between', width: '60%'},
        col2: { flexDirection: 'column', justifyContent: 'space-between', width: '40%'}
      },
    },
  )
  