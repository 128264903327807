import React from 'react';
import { Ionicons, MaterialIcons, FontAwesome5, AntDesign, EvilIcons, FontAwesome, Entypo, MaterialCommunityIcons } from '@expo/vector-icons';

//Fontello
import { createIconSetFromFontello } from '@expo/vector-icons';
import fontelloConfig from '../fonts/fontello/config.json';
import { useFonts } from 'expo-font';

export default function IconSetter (props) {
    const family = props.family || null;
    const iconName = props.name;
    const size = props.size || 30;
    const color = props.color || '#0B0B61';

    if(family === "Ionicons"){
        return(
            <Ionicons name={iconName} size={size} color={color}/>
        );
    };
    if(family === "MaterialIcons"){
        return(
            <MaterialIcons name={iconName} size={size} color={color}/>
        );
    };
    if(family === "MaterialCommunityIcons"){
        return(
            <MaterialCommunityIcons name={iconName} size={size} color={color}/>
        );
    };
    if(family === "FontAwesome5"){
        return(
            <FontAwesome5 name={iconName} size={size} color={color}/>
        );
    };
    if(family === "Entypo"){
        return(
            <Entypo name={iconName} size={size} color={color}/>
        );
    };
    if(family === "AntDesign"){
        return(
            <AntDesign name={iconName} size={size} color={color}/>
        );
    };
    if(family === "FontAwesome"){
        return(
            <FontAwesome name={iconName} size={size} color={color}/>
        );
    };
    if(family === "EvilIcons"){
        return(
            <EvilIcons name={iconName} size={size} color={color}/>
        );
    };
    if(family === "Fontello"){
        //Stukje code om Fontello icons te gebruiken
        let fontello;
        const [loaded] = useFonts({
            fontello: require('../fonts/rd-icons.ttf'),
        });

        if(!loaded){
            return null;
        }

        const Icon = createIconSetFromFontello(fontelloConfig, 'fontello', fontello);
        return(
            <Icon name={iconName} size={size} color={color}/>
        )
    }
    return (
        null
    );

};