// Libraries
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity, TouchableWithoutFeedback, StyleSheet, Image, ActivityIndicator } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import api from '../settings/AxiosSetup';
import { TextInputMask } from 'react-native-masked-text'

// Components
import { HVButton, HVCheckbox, HVInput, AltButton } from '../components/formObjects';
import DropDownHeader from '../components/dropDown';
import IconSetter from '../components/iconSetter';

// Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState';
import { SET_REGISTREER } from '../context/actions/userActions';
import { SET_INSTELLINGEN } from '../context/actions/commonActions';

// Styles
import Colors from '../styles/colors';

// SVG images

export default function RegistreerScherm({ navigation, route }) {
    const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [secureToggle, setSecureToggle] = useState(true);
    const [secureToggleTwee, setSecureToggleTwee] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const userDispatch = useContext(GlobalDispatcher).userDispatch;
    const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
    const userCommon = useContext(GlobalContext).userState;
    const arrayPersonen = userCommon.arrayPersonen;
    const [posting, setPosting] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const paramHandler = async ()  => {
        if (route.params) {
            if (route.params.backed) {
                let firstName = await AsyncStorage.getItem('voornaam');
                setFirstName(firstName);
                let surName = await AsyncStorage.getItem('achternaam');
                setSurName(surName);
                let birthDate = await AsyncStorage.getItem('geboortedatum');
                setBirthDate(birthDate);
                let email = await AsyncStorage.getItem('email');
                setEmail(email);
                let password = await AsyncStorage.getItem('password');
                setPassword(password);
                setRePassword(password);
            }
        }
    }

    useEffect(() => {
        paramHandler();
    },[]);

    // UserVariables
    const [firstName, setFirstName] = useState('');
    const [surName, setSurName] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [rePassword, setRePassword] = useState('');

    const onChange = (e, whatToSet, regex) => {
        const input = e;
        console.log(input);
        if (regex.test(input) || input === "") {
            whatToSet(input);
            setError(false);
        }
    };



    const postRegistration = async () => {
        const regex = /(^[A-Za-zÀ-ȕ_ _-]+$)/;
        const str = firstName + surName;
        let t;

        // console.log(str);
        if ((t = regex.exec(str)) !== null) {
            // The result can be accessed through the `m`-variable.
            t.forEach((match, groupIndex) => {
                // console.log(`Found match, group ${groupIndex}: ${match}`);
            });

            const regex = /(^[^@]+@[^@]+\.[a-zA-Z]{2,}$)/;
            const str = email;
            let m;

            if ((m = regex.exec(str)) !== null) {
                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    console.log(`Found match, group ${groupIndex}: ${match}`);

                });

                var stringToValidate = birthDate;
                var rgexp = /(^(((0[1-9]|1[0-9]|2[0-8])[-](0[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
                var isValidDate = rgexp.test(stringToValidate);

                if (birthDate !== null) {
                    if (!isValidDate) {
                        setError(true);
                        setErrorMessage('Geen valide Datum');
                        setPosting(false);
                        return;
                    }
                }
                if (surName === '') {
                    setError(true);
                    setErrorMessage('Achternaam is verplicht');
                    setPosting(false);
                    return;
                }
                if (firstName === '' && surName === '') {
                    setError(true);
                    setErrorMessage('Namen zijn verplicht');
                    setPosting(false);
                    return;
                } else if (firstName === '' && surName === '' && email === '' && password === '' && rePassword === '') {
                    setError(true);
                    setErrorMessage('Alle velden met een rode * zijn verplicht');
                    setPosting(false);
                    return;
                } else if (email === '' && password === '' || password === '' && rePassword === '') {
                    setError(true);
                    setErrorMessage('Alle velden met een rode * zijn verplicht');
                    setPosting(false);
                    return;
                } else if (password === '' || rePassword === '') {
                    setError(true);
                    setErrorMessage('Wachtwoord is verplicht');
                    setPosting(false);
                    return;
                } else if (password != rePassword) {
                    setError(true);
                    setErrorMessage('Wachtwoorden komen niet overeen');
                    setPosting(false);
                    return;
                } else {
                    setError(false);
                    try {
                        const response = await api.post('registreer?code='+ code + '&voornaam='+ firstName +'&achternaam='+ surName +'&geboortedatum='+ birthDate +'&email='+ email +'&wachtwoord='+ encodeURIComponent(password) +'&wachtwoord_herhalen='+ encodeURIComponent(rePassword));
                        console.log(response.data);
                        if (response.status == 401) {
                            try {
                                const response = await api.post('uitloggen?code=' + code);
                                if (response.status === 401) {
                                    await AsyncStorage.setItem('ingelogd', 'false');
                                    await AsyncStorage.removeItem('bearerToken');
                                    await AsyncStorage.removeItem('cartItems');
                                    navigation.push('EersteScherm');
                                }
                                if (response.status == 429) {

                                    // console.log('Overloaded, wait a couple of seconds and try again.');
                                    alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');

                                } else if (response.data.success) {
                                    await AsyncStorage.setItem('ingelogd', 'false');
                                    await AsyncStorage.removeItem('bearerToken');
                                    await AsyncStorage.removeItem('cartItems');
                                    navigation.push('EersteScherm');
                                };
                            }
                            catch (err) {
                                // console.log(err);
                            };
                            return;
                        } else if (response.status == 429) {

                            // console.log('Overloaded, wait a couple of seconds and try again.');
                            alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
                            return;

                        } else if (response.data.success) {
                            setError(false);
                            await AsyncStorage.setItem('userId', response.data.data.gebruiker_id);
                            await AsyncStorage.setItem('registered', 'true');
                            await AsyncStorage.setItem('voornaam', firstName);
                            await AsyncStorage.setItem('achternaam', surName);
                            await AsyncStorage.setItem('geboortedatum', birthDate);
                            await AsyncStorage.setItem('email', email);
                            await AsyncStorage.setItem('password', password);
                            const instellingen = await api.get('instelling?code=' + code);
                            if (instellingen.status === 429) {
                                // console.log('Overloaded, wait a couple of seconds and try again.');
                                alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
                            }
                            if (instellingen.status === 500) {
                                // console.log('Er is iets fout gegaan');
                            }
                            if (instellingen.data.success) {
                                commonDispatch({ type: SET_INSTELLINGEN, maxGezin: instellingen.data.data[0].waarde, pasFotoBreedte: instellingen.data.data[1].waarde, pasFotoHoogte: instellingen.data.data[2].waarde, factuurBedrag: instellingen.data.data[3].waarde });
                            }
                            navigation.push('GegevensAanvullen', { refresher: 1});

                        } else {
                            setError(true);
                            setPosting(false);
                            setErrorMessage(response.data.error);
                        };
                    }
                    catch (err) {
                        // console.log(err);
                    }
                }
            } else {
                if (email === '' && password === '' || email === '' && rePassword === '') {
                    setError(true);
                    setErrorMessage('Alle velden met een rode * zijn verplicht');
                    return;
                } else if (email === '') {
                    setError(true);
                    setErrorMessage('Email is verplicht');
                    return;
                }
                setError(true);
                setErrorMessage('Email adres is ongeldig');
                return;
            }
        } else {
            if (firstName === '' && surName === '') {
                setError(true);
                setErrorMessage('Alle velden met een rode * zijn verplicht');
                return;
            } else if (firstName === '') {
                setError(true);
                setErrorMessage('Voornaam is verplicht');
            }
            setError(true);
            setErrorMessage('Er is iets fout gegaan.');
        }
    };

    const { styles, deviceSize } = useStyles();

    return (
        <View>
            <View style={styles('main')}>
                <View style={styles('register')}>
                    <View style={{ height: 40, marginBottom: 30, flexDirection: 'row', justifyContent: 'space-evenly', borderRadius: 5 }}>
                        <TouchableOpacity onPress={() => navigation.push('Login')} style={{ width: '50%', backgroundColor: Colors[8], height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={{ color: 'white' }}>{'Inloggen'}</Text>
                        </TouchableOpacity>
                        <View style={{ width: '50%', backgroundColor: '#d7f1f7', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: Colors[8] }}>Registreren</Text>
                        </View>
                    </View>
                    <>
                        <Text style={{ fontFamily: 'Bold', fontSize: 18, fontWeight: '700', color: Colors[8], marginTop: 20, marginBottom: 5 }}>{'STAP 1: PERSOONLIJKE INFORMATIE'}</Text>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'Voornaam'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <View style={{ marginVertical: -5 }}>
                            <HVInput onChangeText={(e) => onChange(e, setFirstName, /(^[A-Za-zÀ-ȕ_ _-]+$)/)} value={firstName} border={true} placeHolder={'Voornaam'} width={'100%'} />
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'Achternaam'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <View style={{ marginVertical: -5 }}>
                            <HVInput onChangeText={(e) => onChange(e, setSurName, /(^[A-Za-zÀ-ȕ_ _-]+$)/)} value={surName} border={true} placeHolder={'Achternaam'} width={'100%'} />
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'Geboortedatum'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <View style={{ marginVertical: -5 }}>
                            <TextInputMask
                                type={'datetime'}
                                style={styles('inputWithBorder')}
                                options={{
                                    format: 'DD-MM-YYYY'
                                }}
                                value={birthDate}
                                onChangeText={(e) => onChange(e, setBirthDate, /^[0-9._-]{0,10}$/)}
                                placeholder={'DD-MM-JJJJ'}
                            />
                            {/* <HVInput onChangeText={(e) => onChange(e, setBirthDate, /^[0-9._-]{0,10}$/)} value={birthDate} border={true} placeHolder={'DD-MM-JJJJ'} width={'100%'} /> */}
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'E-mailadres'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <View style={{ marginVertical: -5 }}>
                            <HVInput onChangeText={(input) => setEmail(input)} value={email} border={true} placeHolder={'E-mailadres'} width={'100%'} />
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'Wachtwoord'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <View style={{ marginVertical: -5 }}>
                            <HVInput onChangeText={(input) => onChange(input, setPassword, /^[a-zA-Z0-9._!@$%^*()#&.-]+$/)} register={true} value={password} password={true} secureToggle={secureToggle} onPress={() => setSecureToggle(!secureToggle)} border={true} placeHolder={'Wachtwoord'} width={'100%'} />
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'Herhaal wachtwoord'}</Text>
                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                        </View>
                        <View style={{ marginVertical: -5 }}>
                            <HVInput onChangeText={(input) => onChange(input, setRePassword, /^[a-zA-Z0-9._!@$%^*()#&.-]+$/)} value={rePassword} password={true} secureToggle={secureToggleTwee} onPress={() => setSecureToggleTwee(!secureToggleTwee)} border={true} placeHolder={'Wachtwoord'} width={'100%'} />
                        </View>
                        <View style={{ marginTop: 10, marginBottom: 10 }}>
                        {error ? <Text style={{ fontWeight: '700', fontSize: 14, color: 'red' }}>{errorMessage}</Text> : null}
                        { disabled ? <ActivityIndicator color={'white'} style={{ position: 'absolute', top: 15, alignSelf: 'center', zIndex: 999}} size={20}/> : null }
                            <HVButton caption={disabled ? ' ' : 'Door naar stap 2: Gegevens aanvullen'} color={Colors[8]} width={'100%'} onPress={() => [
                                postRegistration(), 
                                setDisabled(true),
                                setTimeout(() => {
                                    setDisabled(false)
                                }, 1000)
                                ]} disabled={disabled}/>
                        </View>
                        <TouchableOpacity style={{ zIndex: 2, flexDirection: 'row', alignSelf: 'center', position: 'absolute', bottom: 15 }} onPress={() => navigation.push('Login')}>
                            <View style={{ marginTop: 6, marginRight: 5 }}>
                                <IconSetter name='back' family='Fontello' size={12} color={Colors[8]} />
                            </View>
                            <Text style={{ color: Colors[8], fontFamily: 'Regular', fontSize: 16, fontWeight: '300' }}>{'Vorige stap'}</Text>
                        </TouchableOpacity>
                    </>
                </View>

            </View>
        </View>
    )
}

const useStyles = CreateResponsiveStyle(
    {
        main: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F2F2F2',
            paddingBottom: 50
        },
        register: {
            marginTop: 50,
            backgroundColor: 'white', 
            padding: 35,
            width: '100%',
            maxWidth: 500
        },
        modalBackground: {
            flexGrow: 1,
            position: 'absolute',
        },
        modalView: {
            backgroundColor: '#FFFFFF',
            marginTop: 20,
            
            paddingTop: 30,
            paddingHorizontal: 20,
            paddingBottom: 0,
        },
        modalPosition: {
            minWidth: "30%",
            alignSelf: 'center',
        },
        inputWithBorder: {
            fontFamily: 'Regular', fontSize: 15,
            backgroundColor: 'white',
            color: 'black',
            marginVertical: 10,
            padding: 10,
            borderWidth: 1.5, 
            borderColor: Colors[1]
        },
    },
    {
      [DEVICE_SIZES.MEDIUM_DEVICE]: {
        
      },
      [DEVICE_SIZES.LARGE_DEVICE]: {
        
      },
      [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
        
      },
    },
)