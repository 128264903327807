// Libraries
import React from 'react';

// Components
import { setData } from '../../functions/storage';

// maak hier alle constanten aan die je in actions wilt gebruiken
export const SET_ALL = 'SET_ALL';
export const SET_MENU_FOCUSED = 'MENU_FOCUSED';
export const SET_CURRENT_REGIO = 'SET_CURRENT_REGIO';
export const SET_CURRENT_REGIO_ID = 'SET_CURRENT_REGIO_ID';
export const SET_REGIOS = 'SET_REGIOS';
export const SET_REGIO_COLOR = 'SET_REGIO_COLOR';
export const SET_TAB_VISIBILITY = 'SET_TAB_VISIBILITY';
export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LANGUAGE_AND_DEVICE = 'SET_LANGUAGE_AND_DEVICE';
export const SET_TIME_STAMP = 'SET_TIME_STAMP';
export const SET_TRANSACTION_CODE = 'SET_TRANSACTION_CODE';
export const SET_REGIO_SECONDARY_COLOR = 'SET_REGIO_SECONDARY_COLOR';
export const SET_PASFOTO = 'SET_PASFOTO';
export const SET_PASFOTO_TO_CROP = 'SET_PASFOTO_TO_CROP';
export const SET_INSTELLINGEN = 'SET_INSTELLINGEN';

// Alle initiële waardes
export const commonInitialState = {
  deviceId: '',
  code: 'L3Ks2@d$FD5FD@$dsqJ9jzQS$2za2!sS^dsA9@zLh$jfSA5rz',
  pasfoto: '',
  pasfotoToCrop: '',
  maxAantalGezinsleden: 8,
  afbeeldingBreedtePasfoto: 210,
  afbeeldingHoogtePasfoto: 270,
  factuurBedrag: 100,
}

// actions, om fouten te voorkomen gebruik je uitsluitende constanten
export const commonActions = ( state, action ) => {

  switch ( action.type ) {
    case SET_INSTELLINGEN:
      // // console.log(action.maxGezin, action.pasFotoBreedte, action.pasFotoHoogte, action.factuurBedrag);
      setData('maxAantalGezinsleden', action.maxGezin);
      setData('afbeeldingBreedtePasfoto', action.pasFotoBreedte);
      setData('afbeeldingHoogtePasfoto', action.pasFotoHoogte);
      setData('factuurBedrag', action.factuurBedrag);
      return{ ...state, maxAantalGezinsleden: action.maxGezin, afbeeldingBreedtePasfoto: action.pasFotoBreedte, afbeeldingHoogtePasfoto: action.pasFotoHoogte, factuurBedrag: action.factuurBedrag }
    case SET_PASFOTO:
      return{ ...state, pasfoto: action.value}
    case SET_PASFOTO_TO_CROP:
      return{ ...state, pasfotoToCrop: action.value}
    default:
      return state;
  }
}
