// Libraries
import React, { useState, useContext } from 'react';
import { Dimensions, StyleSheet, ScrollView, TouchableOpacity, Image, Text, View, ImageBackground, ActivityIndicator } from 'react-native';
import api from '../settings/AxiosSetup';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Styles
import Colors from '../styles/colors';

//Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState.jsx';

// SVG Images

// Components
import { HVButton, HVInput } from '../components/formObjects';
import DropDownHeader from '../components/dropDown';

export default function WachtwoordVergeten({ navigation }) {
  const commonContext = useContext(GlobalContext).commonState;
  const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';

  // LoginVariables
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // UserVariables
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(false);

  // WW Vergeten
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const eenmaligeCode = 'e';
  const [newPassword, setNewPassword] = useState(eenmaligeCode ? eenmaligeCode.length > 2 ? true : false : false);

  const wwVergeten = async () => {
    try {
      const response = await api.post('wachtwoord/vergeten?code=' + code + '&email=' + email);
      // console.log(response.data);
      if (response.status == 429) {

        // console.log('Overloaded, wait a couple of seconds and try again.');
        alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');

      }
      if (response.data.success) {
        setSuccessMessage(response.data.message);
        navigation.push('wwHerstellen');
      };
    } catch (err) {
      // console.log(err);
    }
  }
  return (
    <View>
      <View>
        <View style={{ zIndex: 2, width: 480, backgroundColor: 'white', padding: 50 }}>
          <Text style={{ color: Colors[8], fontWeight: '700', paddingBottom: 20, fontFamily: 'Regular', fontSize: 26, alignSelf: 'center' }}>{'WACHTWOORD VERGETEN'}</Text>
          {errorMessage || successMessage ? <Text style={{ fontWeight: '700', fontSize: 14, color: successMessage ? 'green' : 'red', alignSelf: 'center' }}>{successMessage ? successMessage : errorMessage}</Text> : null}
          <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7], textAlign: 'center' }}>{'Zodra je hier je Email verstuurt, krijg je een mail in je inbox. In deze mail zit een code waarmee je je wachtwoord opnieuw kan instellen. Deze code voer je bij het volgende scherm in en vernieuwd daarna je wachtwoord.'}</Text>
          <View style={{ flexDirection: 'row', alignItems: 'flex-start ' }}>
            <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'E-mailadres'}</Text>
            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
          </View>
          <View style={{ marginBottom: 10, marginTop: -5 }}>
            <HVInput onChangeText={(input) => [setEmail(input), setError(false)]} value={email} border={true} placeHolder={'E-mailadres'} width={'100%'} />
          </View>
          <View style={{ marginTop: 10, marginBottom: 10 }}>
            {disabled ? <ActivityIndicator color={'white'} style={{ position: 'absolute', top: 15, alignSelf: 'center', zIndex: 999 }} size={20} /> : null}
            <HVButton caption={disabled ? ' ' : 'Email versturen'} color={Colors[8]} width={'100%'} onPress={() => [
              wwVergeten(),
              setDisabled(true),
              setTimeout(() => {
                setDisabled(false)
              }, 1000)
            ]} disabled={disabled} />
          </View>
          <TouchableOpacity style={{ marginTop: 30 }} onPress={() => navigation.push('Login')}>
            <Text style={{ color: Colors[7], fontFamily: 'Regular', fontSize: 16, alignSelf: 'center' }}>Terug naar inloggen</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  title: {
    fontFamily: 'Regular', fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
