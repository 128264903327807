// Libraries
import React from 'react';

// Components
import { setData } from '../../functions/storage';

// maak hier alle constanten aan die je in actions wilt gebruiken
export const SET_NAME = 'SET_NAME';
export const SET_NAME_ASKED = 'SET_NAME_ASKED';
export const SET_USER = 'SET_USER';
export const SET_TESTER = 'SET_TESTER';
export const SET_AUTH_LEVEL = 'SET_AUTH_LEVEL';
export const SET_ALL = 'SET_ALL';
export const SET_REGISTREER = 'SET_REGISTREER';
export const SET_IMAGE = 'SET_IMAGE';


// Alle initiële waardes
export const userInitialState = {
  arrayPersonen: [{
    id: 1,
    voornaam: '',
    achternaam: '',
    geboortedatum: '',
    email: '',
    gezin: 2,
    gezinsnaam: '',
    image: '',
    telefoon: '',
    straatnaam: '',
    huisnummer: '',
    toevoeging: '',
    postcode: '',
    woonplaats: '',
    profielfoto: '',
  }],
}

// actions, om fouten te voorkomen gebruik je uitsluitende constanten
export const userActions = ( state, action ) => {
  switch ( action.type ) {
    case SET_REGISTREER: 

    var userState = {...state, arrayPersonen: [{
      id: 1,
      voornaam: action.voornaam,
      achternaam: action.achternaam,
      geboortedatum: action.geboortedatum,
      email: action.email,
      gezin: 2,
      gezinsnaam: '',
      image: '',
      telefoon: '',
      straatnaam: '',
      huisnummer: '',
      toevoeging: '',
      postcode: '',
      woonplaats: '',
    }]}
    return { ...userState };

    case SET_IMAGE:
      return { ...state, profielfoto: action.value};
    
    case SET_ALL:
      return { ...state, ...action.value };
    default:
      return state;
  }
}
