// Libraries
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Variabele
//import { API_URL_PRODUCTION as path } from "@env";
import { API_URL_STAGING as path } from "@env";
//import { API_URL_LOCAL as path } from "@env";

//console.log("production")
console.log("staging")
//console.log("local")

const instance = axios.create({
    baseURL: path
});

instance.interceptors.request.use(
    async (config) => {
        const token = await AsyncStorage.getItem('bearerToken');
        //// console.log("bearer token:" + token);
        let bearer = '';
        if(token){
            bearer = token;
        }
        if (bearer !== ''){
            config.headers.common['Authorization'] = `Bearer ${token}`;
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export default instance;