// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import api from '../settings/AxiosSetup';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState.jsx';

// Components
import OrderItem from '../components/orderItem';
import { HVButton, HVInput } from '../components/formObjects';

// Styling
import Colors from '../styles/colors';

export default function BestellingDetails ({ navigation, route }) {

  const cartContext = useContext(GlobalContext).cartState;
  const cartItems = cartContext.cartItems;
  const id = route.params.id;
  const [sponsering, setSponsering] = useState(null);
  const [data, setData] = useState(null);
  const [subtotaal, setSubTotaal] = useState('0');
  const totaalBerekening = cartContext.totaalBerekening;
  const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
  // console.log(cartItems);

  const setDetails = async () => {
      try {
        const response = await api.get('bestelling/' + id + '?code='+ code);
        // Success
        // console.log(response.data);
        if (response.status === 200) {
            setData(response.data.data.details);
            setSubTotaal(response.data.data.subtotaal);
            setSponsering(response.data.data.sponsor_bedrag);
        }
      } catch (error) {
        // Error
        if (error.response) {
          if (error.response.status === 401) {
            // console.log('Geen toegang, niet ingelogd');
            await AsyncStorage.setItem('ingelogd', 'false');
            await AsyncStorage.removeItem('bearerToken');
            await AsyncStorage.removeItem('cartItems');
            navigation.push('EersteScherm');
          }
          if (error.response.status === 429) {
            // console.log('Overloaded, wait a couple of seconds and try again.');
            alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
          }
          if (error.response.status === 500) {
            // console.log('Er is iets fout gegaan');
          }
        }
      }
    }
  
    useEffect(() => {
        setDetails();
    },[]);

  const { styles, deviceSize } = useStyles();

  return (
    <View style={styles('main')}>
      <View style={styles('container')}>
        <View style={styles('navbar')}>
          <View>
            <Text style={styles('siteTitle')}>{'Bestelling #'+ id }</Text>
          </View>
          <View style={styles('menu')}>
            <TouchableOpacity style={styles('menuItem')} onPress={() => navigation.push('accountScreen')}>
              <Text style={styles('menuItemText')}>{'Terug naar Account'}</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles('orderList')}>
          { data ?
            data.map((item, index) => {
              return <OrderItem
                key={index}
                index={index}
                id={item.bestelling_id}
                image={item.afbeelding}
                title={item.naam}
                bestelBool={true}
                aantalProduct={item.aantal}
                price={item.bedrag}
                orderDate={item.datum}
                boughtFor={item.gekocht_voor ? item.gekocht_voor : null}
                variatieNaam={item.variatie ? item.variatie : null}
              />
            }) 
            : null }
        </View>
        { sponsering ? <View style={styles('card')}>
          <View style={styles('imageColumn')}>
            <Image source={require('../images/HemmervenLogo.png')} style={styles('imageStyle')} />
          </View>
          <View style={styles('productInfo')}>
            <Text style={styles('title')}>{"'t Hemmerven sponsoring"}</Text>
            <View style={styles('orderMeta')}>
              <Text style={styles('meta')}>Bedankt voor je steun.</Text>
              <Text style={styles('meta')}>- t' Hemmerven Team</Text>
            </View>
          </View>
          <View style={styles('productPrice')}>
          <View style={{ display: 'flex', justifyContent: 'flex-end', maxWidth: 100 }}>
            <Text style={{ fontSize: 21, color: 'black', fontWeight: '700'}}>{sponsering}</Text>
          </View>
          </View>
        </View> : null }
        <View style={{ alignItems: 'flex-end', width: '100%' }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 20, width: 180 }}>
            <Text style={{ fontWeight: '700', fontSize: 14, color: 'black' }}>{'Subtotaal'}</Text>
            <Text style={{ fontWeight: '700', fontSize: 14, color: 'black' }}>{subtotaal}</Text>
          </View>
        </View> 
      </View>
    </View>
  );
}


const useStyles = CreateResponsiveStyle(
  {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white'
    },
    container: {
      width: '100%',
      maxWidth: 1600,
      padding: 50
    },
    navbar: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#62BAE3',
      padding: 20,
      marginBottom: 20
    },
    siteTitle: {
      fontSize: 28,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: 'white'
    },
    menu: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    menuItem: {
      paddingLeft: 35,
      marginVertical: 25
    },
    menuItemText: {
      fontSize: 24,
      fontWeight: 'bold',
      color: 'white'
    },
    subMenuItemText: {
      fontSize: 18,
      fontWeight: 'bold',
      color: Colors[7]
    },
    card: {
      width: '100%',
      borderWidth: 1,
      borderColor: Colors[8],
      marginBottom: 20
    },
    imageColumn: {
      width: '100%',
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    },
    imageStyle: {
      width: '100%',
      height: 220,
      resizeMode: 'contain'
    },
    productInfo: {
      paddingHorizontal: 35
    },
    orderMeta: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    },
    title: {
      fontSize: 22,
      fontWeight: 'bold'
    },
    meta: {
      fontSize: 16,
      marginVertical: 12,
      marginRight: 35,
      color: '#5A5A5A'
    },
    productPrice: {
      padding: 20,
      flexDirection: 'row',
      textAlign: 'left'
    },
    price: {
      fontSize: 22,
      fontWeight: 'bold',
      alignSelf: 'center'
    },
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      card: {
        width: '100%',
        height: 200,
        borderWidth: 1,
        borderColor: Colors[8],
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
      },
      imageColumn: {
        width: '15%',
        height: 200,
        justifyContent: 'center',
        borderRightWidth: 1,
        borderColor: Colors[8],
      },
      productInfo: {
        width: '55%'
      },
      productPrice: {
        paddingRight: 35,
      }
    },
    [DEVICE_SIZES.LARGE_DEVICE]: {
      card: {
        width: '100%',
        height: 200,
        borderWidth: 1,
        borderColor: Colors[8],
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
      },
      imageColumn: {
        width: '20%',
        justifyContent: 'center',
        height: 200,
        borderRightWidth: 1,
        borderColor: Colors[8],
      },
      productInfo: {
        width: '65%'
      },
      productPrice: {
        paddingRight: 35,
        textAlign: 'right'
      }
    },
    [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
      card: {
        width: '100%',
        height: 200,
        borderWidth: 1,
        borderColor: Colors[8],
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
      },
      imageColumn: {
          width: '20%',
          height: 200,
          justifyContent: 'center',
          borderRightWidth: 1,
          borderColor: Colors[8],
      },
      productInfo: {
          width: '65%'
      },
      productPrice: {
          paddingRight: 35,
          textAlign: 'right'
      }
    },
  },
)
