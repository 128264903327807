import React, { useEffect, useState, useContext } from 'react';
import { Text, View, Image, TouchableOpacity, ActivityIndicator } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import Swiper from 'react-native-web-swiper';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import moment from 'moment';
import api from '../settings/AxiosSetup';
import { LinearGradient } from 'expo-linear-gradient';

// Global
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_CART } from '../context/actions/winkelActions';

// Components
import IconSetter from './iconSetter';

// Styles
import Colors from '../styles/colors';
import { useMemo } from 'react';

const ShopItem = ({ navigation, title, images, caption, price, priceInfo, setVisible, selectLabel, options, personen, id, account, maxAantal, uitverkocht, uitverkochtLabel }) => {

    const cartDispatch = useContext(GlobalDispatcher).cartDispatch
    const cartContext = useContext(GlobalContext).cartState;
    let cartItems = cartContext.cartItems;

    //// console.log('stop');

    //// console.log(options);


    let maxAantaldropdown = maxAantal;
    if(options.length > 0){
        maxAantaldropdown = options[0].pve_aantal;
    }

    const [showOptions, setShowOptions] = useState(false);
    const [selectedValue, setSelectedValue] = useState(options ? options[0] : null);
    const personenArray = personen.map((persoon) => {
        if(persoon.account == 1){
            return persoon.id;
        } else {
            return;
        }
    });
    const [selectedValueTwo, setSelectedValueTwo] = useState(personenArray[0]);
    const [realPrice, setPrice] = useState(price);
    const [accountId, setAccountId] = useState(1);
    const aantalOpties = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [maxHoeveelheid, setMaxHoeveelheid] = useState(maxAantaldropdown);
    const kledingstuk = false;
    const maten = ['XS', 'S', 'M', 'L', 'XL', 'XLL'];
    const [selectedMaat, setSelectedMaat] = useState('L');
    const [selectedValueAantal, setSelectedValueAantal] = useState(aantalOpties ? aantalOpties[0] : 1);

    useMemo(() => {
        if (selectedValueTwo !== personen[0]) {
            personen.map((persoon) => {
                if (persoon.id == selectedValueTwo) {
                    setPrice(persoon.prijs);
                    setAccountId(persoon.account); 
                }
            })
        }
    }, [selectedValueTwo]);


    const { styles, deviceSize } = useStyles();

    const addToCart = async () => {

        let favoriet = 0;

        //// console.log(selectedValueAantal);

        //// console.log(selectedValue);

        //Maak een nieuw cartObject aan, parseInt de id's omdat het anders soms string kan zijn en dan kan je niet goed vergelijken in de map
        let cartObject = {
            product_id: id,
            titel: title,
            afbeelding: images[0],
            variatie_id: selectedValue ? selectedValue.pve_id ? selectedValue.pve_id : parseInt(selectedValue) : null,
            account: accountId,
            ordered_on: moment().format('DD-MM-YYYY'),
            persoon_id: selectedValueTwo,
            aantal: selectedValueAantal,
            prijs: price,
        }
        /*
        let cartItems = null;
        await getData('cartItems', {
                winkelwagen: [],
                sponsor_bedrag: ""
            })
            .then(result => {
                cartItems = result;
                // console.log(result);
                //cartDispatch( { type: SET_CART_PREVIOUS_SESSION, value: result } );
            }
        );*/

        // console.log(cartItems);
        let cart = cartItems.winkelwagen;
        // console.log(cart);
        let sponsor_bedrag = cartItems.sponsor_bedrag;

        // console.log(cartObject);
        //// console.log(cart);
        let backupArray = [];
        //Als de deal met optie id niet voorkomt, gewoon toevoegen
        if (!cart) {
            backupArray.push(cartObject);

            if(!sponsor_bedrag){
                sponsor_bedrag = 0.00;
            }

            let completeCart = {
                winkelwagen: backupArray,
                sponsor_bedrag: sponsor_bedrag
            }
            //// console.log(completeCart);


            setWinkelwagen(completeCart);
            //cartDispatch({ type: SET_CART, value: completeCart });

        } else {
            cart.push(cartObject);
            cartItems.winkelwagen = cart;

            //Mag niet in een aparte functie van react native
            setWinkelwagen(cartItems);

            /*
            let completeCart = {
                winkelwagen: cart,
                sponsor_bedrag: sponsor_bedrag
            }*/

            //apiSetter(cartItems);
            //// console.log(completeCart);
            //apiSetter(cartItems);
            //cartDispatch({ type: SET_CART, value: cartItems });
        }
    }

    const setWinkelwagen = async (cartData) => {
        const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
        const response = await api.get('winkelwagen?code=' + code + '&json=' + encodeURIComponent(JSON.stringify(cartData)));
        // console.log(response.data);
        if (response.status == 401) {
            try {
                const response = await api.post('uitloggen?code=' + code);
                // console.log(response.data);
                if (response.status === 401) {
                    await AsyncStorage.setItem('ingelogd', 'false');
                    await AsyncStorage.removeItem('bearerToken');
                    await AsyncStorage.removeItem('cartItems');
                    navigation.push('EersteScherm');
                }
                if (response.status == 429) {
                    // console.log('Overloaded, wait a couple of seconds and try again.');
                    alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
                } else if (response.data.success) {
                    await AsyncStorage.setItem('ingelogd', 'false');
                    await AsyncStorage.removeItem('bearerToken');
                    await AsyncStorage.removeItem('cartItems');
                    navigation.push('EersteScherm');
                };
            }
            catch (err) {
                // console.log(err);
            };
            return;
        } else if (response.status == 429) {
            // console.log('Overloaded, wait a couple of seconds and try again.');
            alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
            return;
        } if (response.data.success) {
            // console.log('Winkelwagen opslaan gelukt');
            const winkelwagen = response.data.data;
            // console.log(winkelwagen);

            cartDispatch({ type: SET_CART, value: winkelwagen });
            //return winkelwagen;
        } else {
            // console.log('Niet gelukt')
        }
    }

    const getAantal = (index) => {
        // console.log(index);
        // console.log(options[index].pve_id);
        setMaxHoeveelheid(options[index].pve_aantal);
    }

    return (
        <View style={styles('card')}>
                            { uitverkocht === 1 ? <Text style={{ position: 'absolute', color: 'red', fontSize: 25, alignSelf: 'center', zIndex: 99999999, paddingTop: '45%' }}>{uitverkochtLabel}</Text>: null}
            <View style={{ height: 300 }}>
                { uitverkocht === 1 ? <View style={{ position: 'absolute', justifyContent: 'center', height: '100%', width: '100%', backgroundColor: '#DEDEDE', opacity: 0.5, zIndex: 9999}}/>: null}
                {images ? <Swiper
                    loop
                    controlsProps={{
                        prevPos: false,
                        nextPos: false
                    }}
                >
                    {images ?
                        images.map((image, index) => {
                            return (
                                <View key={index} style={{ height: '100%', width: '100%' }}>
                                    <Image source={{ uri: global.app_url + image }} style={{ height: '100%', width: '100%', alignSelf: 'center', resizeMode: 'contain' }} />
                                </View>
                            )
                        })
                        : null
                    }
                </Swiper> : <ActivityIndicator color={Colors[8]} style={{ marginTop: 150 }} />}
            </View>

            {
                !showOptions ?
                    <View style={styles('itemContent')}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                            <View style={{ width: '70%'}}>
                            <Text style={styles('title')}>{title}</Text>
                            </View>
                            <View style={{ width: '30%', alignItems: 'flex-end'}}>
                            <Text style={styles('price')}>{realPrice}</Text>
                            </View>
                        </View>
                        <View style={{ maxHeight: 100, overflow: 'hidden'}}>{ caption.length > 130 ? <LinearGradient colors={['transparent', 'white']} style={{ width: '100%', height: 70, position: 'absolute', bottom: 0}}/> : null }<Text style={styles('caption')}>{caption}</Text></View>
                        <Text style={styles('priceInfo')}>{priceInfo}</Text>
                        { uitverkocht === 0 ? <TouchableOpacity style={styles('cta')} onPress={options.length === 0 && personen.length === 0 && maxAantal === null ? () => [ addToCart(), setVisible()] :
                        () => setShowOptions(true)}>
                            <Text style={styles('ctaText')}>{options.length === 0 && personen.length === 0 && maxAantal === null ? 'Aan winkelmand toevoegen' : 'Opties bekijken en kopen'}</Text>
                        </TouchableOpacity> : null}
                    </View>
                    :
                    <View style={styles('itemContentOptionsAlt')}>
                        <TouchableOpacity onPress={() => setShowOptions(false)} style={{zIndex: 99999, backgroundColor: Colors[8], borderRadius: 30, height: 30, width: 30, alignItems: 'center', justifyContent: 'center', position: 'absolute', top: -15, alignSelf: 'center'}}><IconSetter name='chevron-down' family='Entypo' size={21} color='white' /></TouchableOpacity>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                            <View style={{ width: '65%'}}>
                            <Text style={styles('title')}>{title}</Text>
                            </View>
                            <View style={{ width: '35%', alignItems: 'flex-end'}}>
                            <Text style={styles('price')}>{realPrice}</Text>
                            </View>
                        </View>
                        <Text style={styles('caption')}>{caption}</Text>
                        <Text style={styles('priceInfo')}>{priceInfo}</Text>
                        <View style={styles('actions')}>
                            <Text style={styles('selectLabel')}>{selectLabel}</Text>
                            {options.length > 1 ? <View> 
                                <Text >{'Opties'}</Text>
                                <Picker
                                selectedValue={selectedValue}
                                style={{ height: 50, width: '100%', padding: 10 }}
                                onValueChange={(itemValue, itemIndex) => [setSelectedValue(itemValue), getAantal(itemIndex)]}
                            >

                                {options ?
                                    options.map((item, index) => {
                                        return <Picker.Item
                                            key={index}
                                            label={item.pve_naam}
                                            value={item.pve_id}
                                        />
                                    }) : null
                                }
                            </Picker> </View> : null}
                            <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
                                { kledingstuk ? <Text >{'Selecteer Maat'}</Text>: null }
                                { maxAantal >= 1 ? <Text style={{ marginLeft: kledingstuk ? 52 : 0 }}>{'Aantal'}</Text>: null}
                            </View>
                            <View style={{ flexDirection: 'row', width: '100%', marginBottom: 10 }}>
                                {kledingstuk ? <Picker
                                    selectedValue={selectedMaat}
                                    style={{ height: 50, width: '30%', padding: 10, marginRight: 20 }}
                                    onValueChange={(itemValue, itemIndex) => setSelectedMaat(itemValue)}
                                >

                                    {maten ?
                                        maten.map((item, index) => {
                                            return <Picker.Item
                                                key={index}
                                                label={item}
                                                value={item}
                                            />
                                        }) : null
                                    }
                                </Picker> : null}
                                { maxHoeveelheid === 0 ? <Text style={{color: '#fe424d'}}>{'Dit product is uitverkocht'}</Text> : 
                                maxAantal ? <Picker
                                    selectedValue={selectedValueAantal}
                                    style={{ height: 50, width: '20%', padding: 10 }}
                                    onValueChange={(itemValue, itemIndex) => setSelectedValueAantal(itemValue)}
                                >
                                    { aantalOpties.slice(0, maxHoeveelheid).map((item, index) => {
                                            return <Picker.Item
                                                key={index}
                                                label={item}
                                                value={item}
                                            />
                                        })}
                                </Picker> : null}
                            </View>
                            
                            {personen.length > 0 ? 
                            <View>
                                <Text >{'Voor wie is dit product?'}</Text>
                                <Picker
                                    selectedValue={selectedValueTwo}
                                    style={{ height: 50, width: '100%', padding: 10 }}
                                    onValueChange={(itemValue, itemIndex) => [setSelectedValueTwo(parseInt(itemValue)), console.log(itemValue)]}
                                >
                                    {personen ?
                                        personen.map((item, index) => {
                                            return <Picker.Item
                                                key={index}
                                                label={item.naam}
                                                value={item.id}
                                            />
                                        }) : null
                                    }
                                </Picker> 
                            </View>: null}
                        </View>

                        {maxHoeveelheid === 0 ? null : <TouchableOpacity style={ showOptions ? styles('ctaAlt') : styles('cta')} onPress={() => [
                            addToCart(),
                            setVisible()]
                    }>
                            <Text style={styles('ctaText')}>Aan winkelmand toevoegen</Text>
                        </TouchableOpacity>}
                    </View>
            }
        </View>
    );
};

const useStyles = CreateResponsiveStyle(
    {
        card: {
            position: 'relative',
            width: '100%',
            minHeight: 500,
            backgroundColor: 'white',
            borderColor: '#7F959F',
            borderWidth: 1
        },
        image: {
            width: '100%',
            height: 300,
        },
        itemContent: {
            borderColor: '#7F959F',
            borderTopWidth: 1,
            padding: 20,
            backgroundColor: 'white',
            minHeight: 200,
            maxHeight: 400
        },
        itemContentOptions: {
            borderColor: '#7F959F',
            borderTopWidth: 1,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: 20,
            backgroundColor: 'white',
            minHeight: 250
        },
        itemContentOptionsAlt: {
            borderColor: '#7F959F',
            borderTopWidth: 1,
            position: 'absolute',
            bottom: 40,
            left: 0,
            right: 0,
            padding: 20,
            backgroundColor: 'white',
            minHeight: 250
        },
        title: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 15
        },
        caption: {
            fontSize: 16,
            color: '#5A5A5A',
            marginBottom: 15
        },
        price: {
            fontSize: 16,
            fontWeight: 'bold',
            color: '#62BAE3',
            marginBottom: 5
        },
        priceInfo: {
            fontSize: 15,
            color: '#5A5A5A'
        },
        actions: {
            marginTop: 15
        },
        selectLabel: {
            fontSize: 18,
            marginBottom: 10
        },
        cta: {
            width: '90%',
            padding: 10,
            marginTop: 20,
            backgroundColor: '#62BAE3',
            textAlign: 'center',
            alignSelf: 'center',
            position: 'absolute',
            bottom: 20
        },
        ctaAlt : {
            width: '90%',
            padding: 10,
            marginTop: 50,
            backgroundColor: '#62BAE3',
            textAlign: 'center',
            alignSelf: 'center',
            position: 'absolute',
            bottom: -20
        },
        ctaText: {
            color: 'white',
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center'
        }
    },
    {
        [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
            card: {
                marginBottom: 35
            }
        },
        [DEVICE_SIZES.MEDIUM_DEVICE]: {
            card: {
                width: '48%',
            }
        },
        [DEVICE_SIZES.LARGE_DEVICE]: {
            card: {
                width: '32%',
            }
        },
        [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
            card: {
                width: '23.5%',
            }
        },
    },
)


export default ShopItem;
