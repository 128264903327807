// Libraries
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity, TouchableWithoutFeedback, StyleSheet, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../settings/AxiosSetup';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';


// Components
import LidToevoegen from '../components/lidToevoegenFormulier';
import TabsGezin from '../components/tabsGezin';
import { HVButton } from '../components/formObjects';

// Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState';
import { SET_NAME } from '../context/actions/userActions';

// Styles
import Colors from '../styles/colors';

// SVG images

export default function GezinToevoegen({ navigation, route }) {
    
    const newUser = route.params.newUser;
    const userId = route.params.userId;

    const code = '8589527458258235798';
    const userDispatch = useContext(GlobalDispatcher).userDispatch;
    const userCommon = useContext(GlobalContext).userState;
    const profileImage = userCommon.profielfoto;
    const [gezinsLedenArray, setGezinsLedenArray] = useState([1]);
    const [posting, setPosting] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { styles } = useStyles();

    // UserVariables
    const [image, setImage] = useState(null);
    const [telefoon, setTelefoon] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [surName, setSurName] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [postcode, setPostcode] = useState('');
    const [huisnummer, setHuisNummer] = useState('');
    const [toevoeging, setToevoeging] = useState('');
    const [straatnaam, setStraatNaam] = useState('');
    const [woonplaats, setWoonplaats] = useState('');


    const postNieuwLid = async () => {
        setPosting(true);
        const regex = /(^[A-Za-zÀ-ȕ ]+$)/;
        const str = firstName + surName;
        let t;

        // console.log(str);
        if ((t = regex.exec(str)) !== null) {
            // The result can be accessed through the `m`-variable.
            t.forEach((match, groupIndex) => {
                // console.log(`Found match, group ${groupIndex}: ${match}`);
            });

            const regex = /(^[^@]+@[^@]+\.[a-zA-Z]{2,}$)/;
            const str = email;
            let m;

            if ((m = regex.exec(str)) !== null) {
                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    // console.log(`Found match, group ${groupIndex}: ${match}`);

                });

                var stringToValidate = birthDate;
                var rgexp = /(^(((0[1-9]|1[0-9]|2[0-8])[-](0[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
                var isValidDate = rgexp.test(stringToValidate);

                if (birthDate !== null) {
                    if (!isValidDate) {
                        setError(true);
                        setErrorMessage('Geen valide Datum');
                        setPosting(false);
                        return;
                    }
                }
                if (surName === '') {
                    setError(true);
                    setErrorMessage('Achternaam is verplicht');
                    setPosting(false);
                    return;
                }
                if (firstName === '' && surName === '') {
                    setError(true);
                    setErrorMessage('Namen zijn verplicht');
                    setPosting(false);
                    return;
                } else if (firstName === '' && surName === '' && email === '') {
                    setError(true);
                    setErrorMessage('Alle velden met een rode * zijn verplicht');
                    setPosting(false);
                    return;
                } else if (email === '') {
                    setError(true);
                    setErrorMessage('Alle velden met een rode * zijn verplicht');
                    setPosting(false);
                    return;
                } else {
                    setError(false);
                    try {
                        const response = await api.post('registreer?voornaam=' + firstName + '&achternaam=' + surName);
                        // console.log(response.data);
                        if (response.status == 401) {
                            try {
                                const response = await api.post('uitloggen?code=' + code);
                                // console.log(response.data);

                                if (response.status === 401) {
                                    await AsyncStorage.setItem('ingelogd', 'false');
                                    await AsyncStorage.removeItem('bearerToken');
                                    await AsyncStorage.removeItem('cartItems');
                                    navigation.push('EersteScherm');
                                }
                                if (response.status == 429) {

                                    // console.log('Overloaded, wait a couple of seconds and try again.');
                                    alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');

                                } else if (response.data.success) {
                                    await AsyncStorage.setItem('ingelogd', 'false');
                                    await AsyncStorage.removeItem('bearerToken');
                                    await AsyncStorage.removeItem('cartItems');
                                    navigation.push('EersteScherm');
                                };
                            }
                            catch (err) {
                                // console.log(err);
                            };
                            return;
                        } else if (response.status == 429) {

                            // console.log('Overloaded, wait a couple of seconds and try again.');
                            alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
                            return;

                        } else if (response.data.success) {
                            userDispatch({ type: SET_NAME, value: firstName });
                            setSuccessMessage(response.data.message);
                            setError(false);
                            // setRegistered(true);
                            await AsyncStorage.setItem('registered', 'true');
                            await AsyncStorage.setItem('ingelogd', 'true');
                            await AsyncStorage.setItem('bearerToken', response.data.data);
                            navigation.push('shopScreen');
                            // const email = await AsyncStorage.getItem('email');
                            // navigation.push('EmailBevestigen', { email: email})
                        } else {
                            setError(true);
                            setPosting(false);
                            setErrorMessage(response.data.error);
                        };
                    }
                    catch (err) {
                        // console.log(err);
                    }
                }
            }
        } else {
            if (firstName === '' && surName === '') {
                setError(true);
                setErrorMessage('Alle velden met een rode * zijn verplicht');
                setPosting(false);
                return;
            } else if (firstName === '') {
                setError(true);
                setErrorMessage('Voornaam is verplicht');
                setPosting(false);
            }
            setError(true);
            setErrorMessage('Er is iets fout gegaan.');
        }
    };

    const [arrayData, setArrayData] = useState(null);

    const fetchRegistreerData = async () => {
        let voornaam = await AsyncStorage.getItem('voornaam');
        let achternaam = await AsyncStorage.getItem('achternaam');
        let geboortedatum = await AsyncStorage.getItem('geboortedatum');
        let email = await AsyncStorage.getItem('email');
        let telefoon = await AsyncStorage.getItem('telefoon');
        let postcode = await AsyncStorage.getItem('postcode');
        let huisnummer = await AsyncStorage.getItem('huisnummer');
        let toevoeging = await AsyncStorage.getItem('toevoeging');
        if (toevoeging === '') {
            toevoeging = null;
        }
        let straatnaam = await AsyncStorage.getItem('straatnaam');
        let woonplaats = await AsyncStorage.getItem('woonplaats');
        let gezinsnaam = await AsyncStorage.getItem('gezinsnaam');
        var gezin = 1;
        if (gezinsnaam === '') {
            gezinsnaam = null;
            var gezin = 0;
        }

        const newUserArray = [{
            id: parseInt(userId),
            voornaam: voornaam,
            achternaam: achternaam,
            geboortedatum: geboortedatum,
            pasfoto: profileImage,
            pasfotoToCrop: profileImage,
            email: email,
            telefoon: telefoon,
            gezin: gezin,
            gezinsnaam: gezinsnaam,
            afwijkend_adres: 1,
            straat: straatnaam,
            huisnummer: huisnummer,
            toevoeging: toevoeging,
            postcode: postcode,
            woonplaats: woonplaats,
            verwijderen: 0
        },
        {
            id: null,
            voornaam: 'Naam',
            achternaam: '',
            geboortedatum: '',
            pasfoto: '',
            pasfotoToCrop: '',
            telefoon: '',
            postcode: '',
            huisnummer: '',
            toevoeging: null,
            straat: '',
            woonplaats: '',
            verwijderen: 0,
            afwijkend_adres: 0,
            new: true
        }];
        setArrayData(newUserArray);
    }
    
    useEffect(() => {
        fetchRegistreerData();
    }, [])
    return (
        <View>
            {posting ? <View style={{ alignSelf: 'center', justifyContent: 'center', top: '30%' }}>{successMessage ? <Text style={{ fontFamily: 'Regular', fontSize: 16, color: Colors[8], marginVertical: 20, textAlign: 'center', marginBottom: 40 }}>{successMessage}</Text> : <Text style={{ fontFamily: 'Regular', fontSize: 16, fontWeight: '700', color: Colors[8], marginVertical: 20, marginBottom: 40 }}>{'Posting...'}</Text>}</View>
                : <View style={styles('tabsRegister')}>
                    <Text style={{ fontFamily: 'Bold', fontSize: 18, fontWeight: '700', color: Colors[8], marginVertical: 20 }}>{'STAP 3: GEZINSLEDEN TOEVOEGEN'}</Text>
                    {error ? <Text style={{ fontWeight: '700', fontSize: 14, color: 'red' }}>{errorMessage}</Text> : null}
                    <View>
                        <View>
                            {
                                arrayData ?
                                <TabsGezin 
                                    navigation={navigation}
                                    newUserData={arrayData}
                                    newUser={newUser}
                                    currentTab={1}
                                    userId={userId}
                                />
                                :
                                null
                            }
                            
                        </View>
                    </View>
                </View>}
        </View>
    )
}

const useStyles = CreateResponsiveStyle(
    {
        container: {
            width: '100%',
        },
        content: {
            width: '100%',
            alignItems: 'flex',
            padding: 10
        },
        grid: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: 15,
        },
        buttonWidth: {
            width: '50%'
        },
        tabsRegister: {
            padding: 35
        }
    },
    {
        [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
            buttonWidth: {
                width: '20%'
            }
        },
        [DEVICE_SIZES.LARGE_DEVICE]: {
            buttonWidth: {
                width: '30%'
            }
        },
        [DEVICE_SIZES.MEDIUM_DEVICE]: {
            buttonWidth: {
                width: '40%'
            }
        },
    },
)