// Libraries
import React, { useState, useContext, useEffect} from "react";
import { View, TouchableOpacity, Text, Dimensions, ActivityIndicator } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../settings/AxiosSetup';

// Global
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_CART } from "../context/actions/winkelActions";
import { SET_INSTELLINGEN } from "../context/actions/commonActions";

// Styling
import Colors from "../styles/colors";

export default function LoadingScreen ({ navigation }){
    const cartDispatch = useContext(GlobalDispatcher).cartDispatch;
    const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
    const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';

    const fetchWinkelmand = async () => {
        /*const response = await AsyncStorage.getItem('cartItems');
        // console.log(response);
        if(response){
          cartDispatch({ type: SET_CART, value: JSON.parse(response)});*/
          await fetchInlogStatus();
        /*} else {
            navigation.push('EersteScherm');
        }*/
      };

    const fetchInstellingen = async () => {
        const response = await api.get('instelling?code=' + code);
        // console.log(response.data);
        if (response.status === 429) {
            // console.log('Overloaded, wait a couple of seconds and try again.');
            alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
          }
          if (response.status === 500) {
            // console.log('Er is iets fout gegaan');
          }
        if(response.data.success) {
            commonDispatch({ type: SET_INSTELLINGEN, maxGezin: response.data.data[0].waarde,  pasFotoBreedte: response.data.data[1].waarde, pasFotoHoogte: response.data.data[2].waarde, factuurBedrag: response.data.data[3].waarde});
        }
    };

    const fetchInlogStatus = async () => {
        const response = await AsyncStorage.getItem('ingelogd');
        const token = await AsyncStorage.getItem('bearerToken');
        if(response === 'true' && token){
            navigation.push('accountScreen');
        }else {
            navigation.push('EersteScherm');
        }

    }

    useEffect(() => {
        fetchWinkelmand();
        fetchInstellingen();
    },[])


    return (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1,}}>
            <ActivityIndicator size='large' color={Colors[8]}/>
        </View>
    )
}