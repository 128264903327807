// Libraries
import React, { useState, useEffect, useContext} from 'react';
import { StyleSheet, Text, View, ActivityIndicator } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import LinkingConfiguration from './src/navigation/LinkingConfiguration';
import * as Font from 'expo-font';
import AsyncStorage from '@react-native-async-storage/async-storage'
import { APP_URL_STAGING, API_URL_STAGING, DOMAIN_STAGING, API_CODE_STAGING, DOMAIN_PRODUCTION, API_URL_PRODUCTION, 
  APP_URL_PRODUCTION, API_CODE_PRODUCTION, DOMAIN_LOCAL, API_CODE_LOCAL, API_URL_LOCAL, APP_URL_LOCAL} from '@env';

// Fonts
import fontfamilies from './src/settings/fontFamilies';

// Navigators
import StackNavigatorMain from './src/navigation/Stacknavigator';

//Components
import GlobalState from './src/context/globalState';
import ReadStorage from './src/components/readStorage';

// Variabele
global.url = window.location.href;

if( global.url.includes( DOMAIN_LOCAL ) ){
  // console.log('local');
  global.api_url = API_URL_LOCAL;
  global.app_url = APP_URL_LOCAL;
  global.api_code = API_CODE_LOCAL;
  global.domain = DOMAIN_LOCAL;
}

if( global.url.includes( DOMAIN_STAGING ) ){
  // console.log('staging');
  global.api_url = API_URL_STAGING;
  global.app_url = APP_URL_STAGING;
  global.api_code = API_CODE_STAGING;
  global.domain = DOMAIN_STAGING;
}

if( global.url.includes( DOMAIN_PRODUCTION ) ){
  // console.log('production');
  global.api_url = API_URL_PRODUCTION;
  global.app_url = APP_URL_PRODUCTION;
  global.api_code = API_CODE_PRODUCTION;
  global.domain = DOMAIN_PRODUCTION;
}

//// console.log("laad app");

export default function App() {

  const [fontsLoaded, setFontsLoaded] = useState( true );
  const fonts = fontfamilies();

  useEffect(() => {
    Font.loadAsync( fonts ).then(() => setFontsLoaded( true ));
  }, []);

  // AsyncStorage.clear();

  if ( !fontsLoaded ) {
    return <ActivityIndicator color={'#1A8DFB'}/>;
  } else {
 return (
    <GlobalState>
      <ReadStorage>
        <NavigationContainer
          linking={LinkingConfiguration}>
          <StackNavigatorMain/>
        </NavigationContainer>
      </ReadStorage>
    </GlobalState>
  );
}
}