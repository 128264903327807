// Libraries
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Dimensions, StyleSheet, ScrollView, TouchableOpacity, Image, Text, View, ImageBackground } from 'react-native';
import api from '../settings/AxiosSetup';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Styles
import Colors from '../styles/colors';

//Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState.jsx';
import { SET_CART } from '../context/actions/winkelActions';
import Loader from '../components/loader';

// SVG Images

// Components
import { HVButton, HVInput } from '../components/formObjects';
import DropDownHeader from '../components/dropDown';

export default function DankjeScherm({ navigation, route }, props) {
  const betaalCode = route.params.betaal_code;
  const cartDispatch = useContext(GlobalDispatcher).cartDispatch;
  const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const emptyCart = {
    winkelwagen: [],
    sponser_bedrag: 0.00
  }

  const setWinkelwagen = async (cartData) => {
    const response = await api.get('winkelwagen?code=' + code + '&json=' + encodeURIComponent(JSON.stringify(cartData)));
    // console.log(response.data);
    if (response.status == 401) {
      try {
        const response = await api.post('uitloggen?code=' + code);
        // console.log(response.data);
        if (response.status === 401) {
          await AsyncStorage.setItem('ingelogd', 'false');
          await AsyncStorage.removeItem('bearerToken');
          await AsyncStorage.removeItem('cartItems');
          navigation.push('EersteScherm');
        }
        if (response.status == 429) {
          // console.log('Overloaded, wait a couple of seconds and try again.');
          alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
        } else if (response.data.success) {
          await AsyncStorage.setItem('ingelogd', 'false');
          await AsyncStorage.removeItem('bearerToken');
          await AsyncStorage.removeItem('cartItems');
          navigation.push('EersteScherm');
        };
      }
      catch (err) {
        // console.log(err);
        await AsyncStorage.setItem('ingelogd', 'false');
        await AsyncStorage.removeItem('bearerToken');
        await AsyncStorage.removeItem('cartItems');
        navigation.push('EersteScherm');
      };
      return;
    } else if (response.status == 429) {
      // console.log('Overloaded, wait a couple of seconds and try again.');
      alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
      return;
    } if (response.data.success) {
      // console.log('Winkelwagen opslaan gelukt');
      const winkelwagen = response.data.data;
      // console.log(winkelwagen);

      cartDispatch({ type: SET_CART, value: winkelwagen });

      navigation.push('accountScreen')
      //return winkelwagen;
    } else {
      // console.log('Niet gelukt')
    }
  };

  const fetchBetaalStatus = async () => {
    try {
      const response = await api.get('betaald?code=' + code + '&betaal_code=' + betaalCode);
      // console.log(response.data);
      if (response.status == 401) {
        try {
          const response = await api.post('uitloggen?code=' + code);
          // console.log(response.data);
          if (response.status === 401) {
            await AsyncStorage.setItem('ingelogd', 'false');
            await AsyncStorage.removeItem('bearerToken');
            await AsyncStorage.removeItem('cartItems');
            navigation.push('EersteScherm');
          }
          if (response.status == 429) {
            // console.log('Overloaded, wait a couple of seconds and try again.');
            alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
          } else if (response.data.success) {
            await AsyncStorage.setItem('ingelogd', 'false');
            await AsyncStorage.removeItem('bearerToken');
            await AsyncStorage.removeItem('cartItems');
            navigation.push('EersteScherm');
          };
        }
        catch (err) {
          // console.log(err);
          await AsyncStorage.setItem('ingelogd', 'false');
          await AsyncStorage.removeItem('bearerToken');
          await AsyncStorage.removeItem('cartItems');
          navigation.push('EersteScherm');
        };
        return;
      } else if (response.status == 429) {
        // console.log('Overloaded, wait a couple of seconds and try again.');
        alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
        return;
      } if (response.data.success) {
        // console.log("komt in deze ding");
        // console.log(response.data.data);
        setStatus(response.data.data);
        setLoading(false);
      } else {
        // console.log('Niet gelukt')
      }
    } catch (err) {
      // console.log(err);
    }
  }

  useEffect(() => {
    fetchBetaalStatus();
  },[])

  return useMemo(()=> {
    // console.log(status);
    if( loading ){
      return <Loader/>;
    }
    else{
      return (
        <View>
          <View>
            <View style={{ zIndex: 2, width: 700, backgroundColor: 'white', padding: 50 }}>
              <View style={{ alignSelf: 'center' }}>

              </View>
              <Text style={{ color: Colors[8], fontWeight: '700', paddingBottom: 20, fontFamily: 'Regular', fontSize: 41, alignSelf: 'center', textAlign: 'center' }}>{status == 1 ? 'BEDANKT VOOR JE AANKOOP' : 'AANKOOP IS HELAAS MISLUKT'}</Text>
              <Text style={{ color: Colors[8], fontWeight: '700', paddingBottom: 20, fontFamily: 'Regular', fontSize: 26, alignSelf: 'center' }}>{status == 1 ? 'VEEL ZWEM PLEZIER!' : 'PROBEER HET LATER NOG EENS'}</Text>
              <View style={{ alignItems: 'center' }}>
                <HVButton caption={'Terug naar mijn account'} color={Colors[8]} padding={20} width={'50%'} onPress={() => status == 1 ? setWinkelwagen(emptyCart) : navigation.push('Winkelmand')} />
              </View>
            </View>
          </View>
        </View>
      );
    }
  },[loading, status])
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  title: {
    fontFamily: 'Regular', fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
