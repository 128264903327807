// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { Text, View, TouchableOpacity, Touchable, Image } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import api from '../settings/AxiosSetup';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState.jsx';
import { SET_CART } from '../context/actions/winkelActions';

// Components
import TabsGezin from '../components/tabsGezin.jsx';
import Footer from '../components/footer';

// Styling
import Colors from '../styles/colors';

//Icons
import { AntDesign } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons'; 

export default function GezinsScreen({ navigation, route }) {
  const cartDispatch = useContext(GlobalDispatcher).cartDispatch;

  const params = route.params;
  var activeTab = 0;
  var refresher = 0;
  if (params) {
    activeTab = params.activeTab;
    refresher = params.refresh;
  } 

  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    if(refresher === 1) {
      // console.log('mooi');
      setTimeout(() => {
        location.reload();
      }, 200);
    }
  },[])

  const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';

  const uitloggen = async () => {
    const emptyCart = { 
      winkelwagen: [],
      sponser_bedrag: 0.00
    }
    // console.log(emptyCart);
    setWinkelwagen(emptyCart);
  }

  const setWinkelwagen = async (cartData) => {
      const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
      const response = await api.get('winkelwagen?code=' + code + '&json=' + encodeURIComponent(JSON.stringify(cartData)));
      // console.log(response.data);
      if (response.status == 401) {
          try {
              const response = await api.post('uitloggen?code=' + code);
              // console.log(response.data);
              if (response.status === 401) {
                  await AsyncStorage.setItem('ingelogd', 'false');
                  await AsyncStorage.removeItem('bearerToken');
                  navigation.push('Home');
              }
              if (response.status == 429) {
                  // console.log('Overloaded, wait a couple of seconds and try again.');
                  alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
              } else if (response.data.success) {
                  // console.log('Pushed');
              };
          }
          catch (err) {
              // console.log(err);
          };
          return;
      } else if (response.status == 429) {
          // console.log('Overloaded, wait a couple of seconds and try again.');
          alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
          return;
      } if (response.data.success) {
          // console.log('Winkelwagen opslaan gelukt');
          const winkelwagen = response.data.data;
          // console.log(winkelwagen);

          cartDispatch({ type: SET_CART, value: winkelwagen });

          try {
            const response = await api.post('uitloggen?code=' + code);
            // console.log(response.data);
            if( response.status === 401){
              await AsyncStorage.setItem('ingelogd', 'false');
              await AsyncStorage.removeItem('bearerToken');
              await AsyncStorage.removeItem('cartItems');
              navigation.push('EersteScherm');
            }
            if (response.status == 429) {
              // console.log('Overloaded, wait a couple of seconds and try again.');
              alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
            } else if (response.data.success) {

                await AsyncStorage.setItem('ingelogd', 'false');
                await AsyncStorage.removeItem('bearerToken');
                await AsyncStorage.removeItem('cartItems');
                navigation.push('EersteScherm');
            };
          }
          catch (err) {
            // console.log(err);
          };
      } else {
          // console.log('Niet gelukt')
      }
  }

  const cartContext = useContext(GlobalContext).cartState;
  const cartItems = cartContext.cartItems.winkelwagen;
  const { styles, deviceSize } = useStyles();

  return (
    <View style={styles('main')}>
      <View style={styles('container')}>
        <View style={styles('logobar')}>
          <Image source={require('../images/HemmervenLogo.png')} style={styles('imageStyle')} />
        </View>
        <View style={styles('navbar')}>
          <View style={styles('header')}>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={() => location.replace('https://www.hemmerven.nl/')} style={{ marginRight: 20}}><Text style={styles('siteTitle')}>Home</Text></TouchableOpacity>
              <Text style={styles('siteTitle')}>Mijn account</Text>
            </View>
            <View style={styles('menuItems')}>
                <TouchableOpacity style={styles('menuItem')} onPress={() => navigation.push('Winkelmand')}>
                  <Text style={styles('menuItemText')}>{cartItems ? 'Winkelmand(' + cartItems.length + ')' : 'Winkelmand (0)'}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles('menuItem')} onPress={() => navigation.push('shopScreen')}>
                  <Text style={styles('menuItemText')}>Shop</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles('menuItem')} onPress={() => navigation.push('GegevensScreen')}>
                  <Text style={styles('menuItemText')}><AntDesign name="right" size={20} color="white" style={styles('indicator')}/>Account</Text>
                </TouchableOpacity>
                {/* <TouchableOpacity style={styles('menuItem')} onPress={() => location.replace('https://www.hemmerven.nl/shop/')}>
                  <Text style={styles('menuItemText')}>Terug naar 't Hemmerven</Text>
                </TouchableOpacity> */}
            </View>
          </View>
        </View>
        <View style={styles('navsubbar')}>
          <View style={styles('navbaritems')}>
            <TouchableOpacity style={styles('subItem')} onPress={() => navigation.push('GezinsScreen')}><Text style={styles('subMenuItemText')}><AntDesign name="right" size={18} style={styles('indicator')}/>Mijn gegevens</Text></TouchableOpacity>
            <TouchableOpacity style={styles('subItem')} onPress={() => navigation.push('accountScreen')}><Text style={styles('subMenuItemText')}>Mijn bestellingen</Text></TouchableOpacity>
            <TouchableOpacity style={styles('subItem')} onPress={() => navigation.push('wwWijzigen')}><Text style={styles('subMenuItemText')}>Wachtwoord wijzigen</Text></TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity style={styles('logout')} onPress={() => uitloggen()}><Text style={styles('subMenuItemText')}>Uitloggen <AntDesign name="logout" size={18} /></Text></TouchableOpacity>
          </View>
        </View>
        <TabsGezin 
          navigation={navigation}
          currentTab={activeTab}
        />
      </View>
      <View style={{ maxWidth: 1600, width: '100%',}}>
        <Footer navigation={navigation}/>
        </View> 
    </View>
  );
}


const useStyles = CreateResponsiveStyle(
  {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#E8E8E8'
    },
    logobar: {
      width: '100%',
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imageStyle: {
      width: 250,
      height: 150,
      resizeMode: 'contain'
    },
    container: {
      width: '100%',
      maxWidth: 1600,
      padding: 25,
      backgroundColor: 'white'
    },
    header: {
      width: '100%'
    },
    navbar: {
      backgroundColor: '#62BAE3',
      padding: 30,
      width: '103.3%',
      right: 25
    },
    menuItem: {
      marginBottom: 10
    },
    menuItemText: {
      fontSize: 20,
      fontWeight: 'bold',
      color: 'white'
    },
    subMenuItemText: {
      fontSize: 18,
      fontWeight: 'bold',
      color: Colors[7]
    },
    navsubbar: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      marginVertical: 15,
      marginBottom: 50,
      paddingHorizontal: 25
    },
    navbaritems: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    subItem: {
      marginVertical: 10,
      position: 'relative'
    },
    indicator: {
      position: 'absolute',
      left: -20,
      top: 0
    },
    logout: {
      marginTop: 10
    },
    siteTitle: {
      fontSize: 28,
      marginBottom: 20,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: 'white'
    },
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 25
      }
    },
    [DEVICE_SIZES.LARGE_DEVICE]: {
      siteTitle: {
        marginBottom: 0
      },
      header: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      menuItems: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      menuItem: {
        marginBottom: 0,
        marginLeft: 35
      },
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 35
      }
    },
    [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
      siteTitle: {
        marginBottom: 0
      },
      header: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      menuItems: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      menuItem: {
        marginBottom: 0,
        marginLeft: 35
      },
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 45
      }
    },
  },
)
