// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet, TouchableWithoutFeedback} from "react-native";
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';

// Styles
import Colors from '../styles/colors';

// Components
import IconSetter from './iconSetter';

export default function DropDownHeader (props) {
    const selectedOptie = props.selectedOptie;
    const onPress = props.onPress;
    const border = props.border;
    const smaller = props.smaller || false;
    const width = props.width || '45%';
    return (
        <View style={{ backgroundColor: 'white', height: 40, width: width, marginVertical: 12, borderRadius:6, padding: 10,  borderWidth: 1.5, borderColor: Colors[8] }}>        
            <TouchableOpacity style={{ flexDirection: 'row',}}
                onPress={onPress}>
                { smaller ? selectedOptie.length > 18 ? 
                <Text style={{ fontFamily: 'Book', fontSize: 11, fontWeight: '700', top: 3.5, color: Colors[8], width: "100%" }}>{selectedOptie}</Text> 
                : 
                <Text style={{ fontFamily: 'Book', fontSize: 13, fontWeight: '700', color: Colors[8], width: "100%" }}>{selectedOptie}</Text>
                 : <Text style={{ fontFamily: 'Book', fontSize: 13, fontWeight: '700', color: Colors[8] }}>{selectedOptie}</Text>}
                <Ionicons  style={{ right: -3, top: -5, position: 'absolute' }}name="md-chevron-down-outline" size={24} color="black" />
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    modalBackground: {
        flexGrow: 1,
        position: 'absolute',
      },
      modalView: {
        backgroundColor: '#FFFFFF',
        marginTop: 20,
        
        paddingTop: 30,
        paddingHorizontal: 20,
        paddingBottom: 0,
      },
      modalPosition: {
        width: "30%",
        alignSelf: 'center',
      },
});