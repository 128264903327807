// Libraries
import React, { useState, useContext } from 'react';
import { Dimensions, StyleSheet, ScrollView, TouchableOpacity, Image, Text, View, ImageBackground, ActivityIndicator } from 'react-native';
import api from '../settings/AxiosSetup';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Styles
import Colors from '../styles/colors';

//Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState.jsx';

// SVG Images

// Components
import { HVButton, HVInput } from '../components/formObjects';
import DropDownHeader from '../components/dropDown';

export default function WachtwoordHerstellen({ navigation }) {
  const commonContext = useContext(GlobalContext).commonState;
  const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [secureToggle, setSecureToggle] = useState(true);
  const [secureToggleTwee, setSecureToggleTwee] = useState(true);

  // UserVariables
  const [email, setEmail] = useState('');

  // WW Vergeten
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [eenmaligeCode, setEenmaligeCode] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [disabled, setDisabled] = useState(false);

  const wwHerstellen = async () => {
    try {
      const response = await api.post('wijzig/wachtwoord/eenmalig?code=' + code + '&eenmalige_code=' + eenmaligeCode + '&nieuw_wachtwoord='+ encodeURIComponent(password) +'&nieuw_wachtwoord_herhalen='+ encodeURIComponent(rePassword));
      // console.log(response.data);
      if (response.status == 429) {

        // console.log('Overloaded, wait a couple of seconds and try again.');
        alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');

      }
      if (response.data.success) {
        setSuccessMessage(response.data.message);


      }else {
        setErrorMessage(response.data.error);
      }
    } catch (err) {
      // console.log(err);
    }
  }

  return (
    <View>
      <View>
        <View style={{ zIndex: 2, width: 480, backgroundColor: 'white', padding: 50 }}>
          <Text style={{ color: Colors[8], fontWeight: '700', paddingBottom: 20, fontFamily: 'Regular', fontSize: 26, alignSelf: 'center' }}>{'WACHTWOORD HERSTELLEN'}</Text>
          {errorMessage || successMessage ? <Text style={{ fontWeight: '700', fontSize: 14, color: successMessage ? 'green' : 'red', alignSelf: 'center' }}>{successMessage ? successMessage : errorMessage}</Text> : null}
          { successMessage ? null :<View>
            <View style={{ left: 12, flexDirection: 'row' }}>
                <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'Code'}</Text>
                <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                </View>
                <View>
              <HVInput onChangeText={(input) => setEenmaligeCode(input)} value={eenmaligeCode} border={true} placeHolder={'Code'} width={'100%'} />
            </View>
              <View style={{ left: 12, flexDirection: 'row' }}>
                <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'Nieuw wachtwoord'}</Text>
                <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                </View>
                <View>
              <HVInput onChangeText={(input) => setPassword(input)} register={true} value={password} password={true} secureToggle={secureToggle} onPress={() => setSecureToggle(!secureToggle)} border={true} placeHolder={'Wachtwoord'} width={'100%'} />
            </View>
            <View style={{ left: 12, flexDirection: 'row' }}>
              <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'Herhaal nieuw wachtwoord'}</Text>
              <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
            </View>
            <View style={{ marginVertical: -5 }}>
              <HVInput onChangeText={(input) => setRePassword(input)} value={rePassword} password={true} secureToggle={secureToggleTwee} onPress={() => setSecureToggleTwee(!secureToggleTwee)} border={true} placeHolder={'Wachtwoord'} width={'100%'} />
            </View>
        </View>}
        { successMessage ? null : 
        <View>
          { disabled ? <ActivityIndicator color={'white'} style={{ position: 'absolute', top: 15, alignSelf: 'center', zIndex: 999}} size={20}/> : null }
          <HVButton caption={disabled ? ' ' : 'Nieuw wachtwoord instellen'} color={Colors[8]} width={'100%'} onPress={() => [
          wwHerstellen(),
          setDisabled(true),
          setTimeout(() => {
            setDisabled(false)
          }, 1000)
        ]} disabled={disabled} />
        </View>}
        <TouchableOpacity style={{ marginTop: 30 }} onPress={() => navigation.push('Login')}>
          <Text style={{ color: Colors[7], fontFamily: 'Regular', fontSize: 16, alignSelf: 'center' }}>Terug naar inloggen</Text>
        </TouchableOpacity>
      </View>
    </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  title: {
    fontFamily: 'Regular', fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
