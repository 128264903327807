import React, { useState, useEffect, useContext, createRef, forwardRef, useMemo } from 'react';
import { Text, View, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RadioGroup from 'react-native-radio-buttons-group';
import { TextInputMask } from 'react-native-masked-text'
import api from '../settings/AxiosSetup';


// Components
import { HVButton, HVCheckbox, HVInput, AltButton } from '../components/formObjects';
import ImageCrop from '../components/imageCrop';

//Icons
import { Ionicons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

// Styles
import Colors from '../styles/colors';

// Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState';

export default function TabsGezin({ navigation, currentTab, newUserData, newUser, userId }) {

    const [testVoornaam, setTestVoornaam] = useState('');

    const [maxAdults, setMaxAdults] = useState(false);
    const [gezinStatus, setGezinStatus] = useState(0);
    const [valid, setValid] = useState(true);
    const [dataReady, setDataReady] = useState(false);
    const [posting, setPosting] = useState(false);
    const commonState = useContext(GlobalContext).commonState;
    const maxGezin = commonState.maxAantalGezinsleden;
    const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';

    //State arrayPersons to fill with data
    const [arrayPersons, updatePersons] = useState(null);
    const [activeCount, setActiveCount] = useState(0);

    //// console.log("Test");

    //Function to update specific value for specific person
    const updatePerson = (index, type, regex) => (value) => {
        if(value !== null){
            if (regex.test(value) || value === "") {
                let newArr = [...arrayPersons];
                newArr[index][type] = value;
                updatePersons(newArr);
            }
        }
    }

    //Update afwijkend adres via radio buttons
    const updatePersonAdres = (index, type, value) => {
        let newArr = [...arrayPersons];
        newArr[index][type] = value;
        updatePersons(newArr);
    }
    //Update pasfoto via child component
    const updatePasfoto = (index, type, value) => {
        let newArr = [...arrayPersons];
        newArr[index][type] = value;
        updatePersons(newArr);
    }

    const setStorageGezinsLeden = () => {
        updatePersons(newUserData);
        setActiveCount(newUserData.length);
        setActiveTab(currentTab);
        setDataReady(true);
        setGezinStatus(newUserData[0].gezin);
    };
    
    const fetchGezinsleden = async () => {

        try {
            const response = await api.get('gezinsleden/ophalen?code=' + code);
            // console.log(response.data);
            // Success
            if (response.status === 200) {
                response.data.data.map((item, index) => {
                    response.data.data[index].verwijderen = 0;
                    if(response.data.data[index].gezin){
                        setGezinStatus(response.data.data[0].gezin);
                    }
                })

                updatePersons(response.data.data);
                setActiveCount(response.data.data.length);
                setDataReady(true);
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {
                // console.log('Geen toegang, niet ingelogd');
                await AsyncStorage.setItem('ingelogd', 'false');
                await AsyncStorage.removeItem('bearerToken');
                navigation.push('EersteScherm');
              }
              if (error.response.status === 429) {
                // console.log('Overloaded, wait a couple of seconds and try again.');
                alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
              }
              if (error.response.status === 500) {
                // console.log('Er is iets fout gegaan');
              }
            }
          }
      }

    const pushPersons = async (arrayPersons, deletingUser) => {
        arrayPersons.map((item, index) => {
            delete(arrayPersons[index].pasfotoToCrop);
            if (item.pasfoto && item.pasfoto.startsWith("/fotos/")) {
                arrayPersons[index].pasfoto = null;
            }
            if (item.verwijderen === 1 && item.new) {
                arrayPersons.splice(index, 1);
            }
        })

        arrayPersons = JSON.stringify(arrayPersons);

        try {
            //If existing user or new user
            if (newUser === 'true' || newUser) {
                var response = await api.post('gezinsleden/toevoegen/' + userId + '?code=' + code, ({ json: arrayPersons }));
            } else {
                var response = await api.post('gezinsleden/wijzigen?code=' + code, ({ json: arrayPersons }));
            }
            // Success
            if (response.data.success === true) {
                if (newUser === 'true' || newUser) {
                    await AsyncStorage.setItem('registered', 'true');
                    await AsyncStorage.setItem('ingelogd', 'true');
                    await AsyncStorage.setItem('bearerToken', response.data.data);
                    navigation.push('GezinsScreen');
                } else {
                    if(deletingUser) {
                        navigation.push('GezinsScreen', {
                            activeTab: activeTab -1
                        });
                    } else {
                    navigation.push('GezinsScreen', {
                        activeTab: activeTab
                    });
                    }
                }
            } else {
                console.log(response.data);
                if (response.data.success === false && response.data.error === 'Je mag maximaal 2 volwassenen in een gezin hebben') {
                    setMaxAdults(true);
                }
                setPosting(false);
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {
                // console.log('Geen toegang, niet ingelogd');
                await AsyncStorage.setItem('ingelogd', 'false');
                await AsyncStorage.removeItem('bearerToken');
                navigation.push('EersteScherm');
              }
              if (error.response.status === 429) {
                // console.log('Overloaded, wait a couple of seconds and try again.');
                alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
              }
              if (error.response.status === 500) {
                // console.log('Er is iets fout gegaan');
              }
            }
          }
    }

    //Validate
    const validateFields = (arrayPersons, deletingUser) => {
        setTimeout(function () {
            var isFormValid = true;
            arrayPersons.map((item, index) => {

                if (!item.voornaam) {
                    setValid(false);
                    setPosting(false);
                    isFormValid = false;
                }
                if (!item.achternaam) {
                    setValid(false);
                    setPosting(false);
                    isFormValid = false;
                }
                if (!item.geboortedatum) {
                    setValid(false);
                    setPosting(false);
                    isFormValid = false;
                }
                if (!item.telefoon) {
                    arrayPersons[index].telefoon = null;
                }
                if (item.huisnummer) {
                    arrayPersons[index].huisnummer = parseInt(item.huisnummer);
                }
                if (item.afwijkend_adres === 0) {
                    arrayPersons[index].postcode = null;
                    arrayPersons[index].huisnummer = null;
                    arrayPersons[index].toevoeging = null;
                    arrayPersons[index].straat = null;
                    arrayPersons[index].woonplaats = null;
                }
                if (item.afwijkend_adres === 1) {
                    if (!item.postcode || !item.huisnummer || !item.straat || !item.woonplaats) {
                        setValid(false);
                        setPosting(false);
                        isFormValid = false;
                    }
                }
                if( item.gezin ) {
                    if(item.gezin === 0){
                        arrayPersons[index].gezinsnaam = null;
                    }
                }
                if (item.new === true && !item.pasfoto) {  
                    setValid(false);
                    setPosting(false);
                    isFormValid = false;
                }
            })
            if (isFormValid) {
                pushPersons(arrayPersons, deletingUser);
            }
            }, 500);
    }

    const fetchAdres = async (postcode, huisnummer) => {
        // console.log(postcode, huisnummer);
        // try {
        //     const response = await api.get('https://api.postcodeapi.nu/v3/lookup/' + postcode + '/' + huisnummer + '');
        //     // Success
        //     if (response.status === 200) {
        //         response.data.data.map((item, index) => {
        //             response.data.data[index].verwijderen = 0;
        //         })
        //         updatePersons(response.data.data);
        //         setActiveCount(response.data.data.length);
        //         setDataReady(true);
        //     }
        //   } catch (error) {
        //     // Error
        //     if (error.response) {
        //       if (error.response.status === 401) {
        //         // console.log('Geen toegang, niet ingelogd');
        //         await AsyncStorage.setItem('ingelogd', 'false');
        //         await AsyncStorage.removeItem('bearerToken');
        //         navigation.push('EersteScherm');
        //       }
        //       if (error.response.status === 429) {
        //         // console.log('Overloaded, wait a couple of seconds and try again.');
        //         alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
        //       }
        //       if (error.response.status === 500) {
        //         // console.log('Er is iets fout gegaan');
        //       }
        //     }
        //   }
    };

    useEffect(() => {
        if (newUser) {
            setStorageGezinsLeden();
        } else {
            fetchGezinsleden();
        }
    }, [])

    console.log(arrayPersons);

    //State activeTab
    const [activeTab, setActiveTab] = useState(parseInt(currentTab));

    //Variable for styles library
    const { styles, deviceSize } = useStyles();

    //Handle callback from cropImage
    const handleCallback = (childData) =>{
        this.setState({data: childData})
    }

  return (
      <View>
          {
            dataReady ?
        <View style={styles('main')}>
            <View style={styles('intro')}>
                <Text style={{fontSize: 16}}>Hier kun je gegevens van jezelf en verschillende gezinsleden toevoegen en/of wijzigen.</Text>
                <Text style={{fontSize: 16}}>Kies voor '+ Toevoegen' om een gezinslid toe te voegen. Klik op het rode icoontje om een gezinslid te verwijderen.</Text>
                <Text style={{fontSize: 16}}>Als je klaar bent kies dan onderin voor 'Gegevens opslaan'.</Text>
                <Text style={{fontSize: 16, color: 'red'}}>Let op! Sommige opties zijn alleen beschikbaar als je een gezin aanmaakt.</Text>
                <Text style={{fontSize: 16}}>(Dit kan door de 'ja' optie bij gezin aan te klikken en een gezinsnaam in te vullen.)</Text>
            </View>
            
            <View style={styles('familyTabs')}>
                <MaterialCommunityIcons name="gesture-swipe-horizontal" size={42} color="#62bae3" style={styles('swipeIcon')}/>
                <View style={styles('tabsRow')}>
                {
                    arrayPersons.map((item, index) => {
                        return <View key={index}>
                                {
                                    item.verwijderen === 0 ?
                                    <View>
                                    {
                                        (index !== 0) ?
                                        <TouchableOpacity style={ (index === activeTab ? styles('tabActive') : styles('tab')) } onPress={() => {
                                            setActiveTab(index);
                                        }}>
                                            {
                                                (item.voornaam != '') ?
                                                <Text style={styles('tabText')}>{item.voornaam}</Text>
                                                :
                                                <Text style={styles('tabText')}>Naam</Text>
                                            }
                                            <TouchableOpacity style={styles('remove')} onPress={() => {
                                                if (confirm(item.voornaam + ' verwijderen?') == true) {
                                                    const personId = index;
                                                    arrayPersons.map((item, index) => {
                                                        //item.id = index;
                                                        if (personId === index) {
                                                            item.verwijderen = 1;
                                                            item.geboortedatum = '01-01-2022';
                                                        }
                                                        if (item.verwijderen === 1 && item.new) {

                                                            arrayPersons.splice(index, 1);
                                                            
                                                        }
                                                    })
                                                    updatePersons(arrayPersons);
                                                    setActiveCount(activeCount - 1);
                                                    setActiveTab(index - 1);
                                                    let deletingUser = true;
                                                    validateFields(arrayPersons, deletingUser);
                                                }
                                            }}>
                                            <Ionicons name="remove-circle" size={24} color="red" />
                                            </TouchableOpacity>
                                        </TouchableOpacity>
                                        :
                                        <TouchableOpacity style={(index === activeTab ? styles('tabOneActive') : styles('tabOne'))} onPress={() => {
                                            setActiveTab(index);
                                        }}>
                                            {
                                                (item.voornaam != '') ?
                                                <Text style={styles('tabText')}>{item.voornaam}</Text>
                                                :
                                                <Text style={styles('tabText')}>Naam</Text>
                                            }
                                        </TouchableOpacity>
                                    }
                                    </View>
                                :
                                null
                                }
                            </View>
                    })
                }
                {
                    (activeCount < maxGezin) && gezinStatus === 1 ?
                    <TouchableOpacity style={styles('addTab')} onPress={() => {
                        setActiveTab(arrayPersons.length);
                        setActiveCount(activeCount + 1);
                        const newPerson = {
                            id: null,
                            voornaam: 'Naam',
                            achternaam: '',
                            geboortedatum: '',
                            pasfoto: '',
                            pasfotoToCrop: '',
                            telefoon: '',
                            postcode: '',
                            huisnummer: '',
                            toevoeging: '',
                            straat: '',
                            woonplaats: '',
                            verwijderen: 0,
                            afwijkend_adres: 0,
                            new: true
                        };
                        updatePersons([...arrayPersons, newPerson]);
                    }}>
                        <Text style={styles('textAdd')}>+ Toevoegen</Text>
                    </TouchableOpacity>
                    :
                    null
                }
                </View> 
                {
                    arrayPersons.map((item, index) => {

                        //Radiobuttons data
                        var yesSelectedTwo = false;
                        var noSelectedTwo = true;
                        if (item.gezin === 1) {
                            noSelectedTwo = false;
                            yesSelectedTwo = true;
                        } else {
                            noSelectedTwo = true;
                            yesSelectedTwo = false;
                        }

                        const radioButtonsTwoData = [{
                            id: 1, // acts as primary key, should be unique and non-empty string
                            borderColor: Colors[7],
                            size: 21,
                            color: Colors[8],
                            label: 'Ja',
                            value: 1,
                            selected: yesSelectedTwo
                        }, {
                            id: 2,
                            borderColor: Colors[7],
                            size: 21,
                            color: Colors[8],
                            label: 'Nee',
                            value: 0,
                            selected: noSelectedTwo
                        }]
                    
                        function onPressRadioTwoButton(radioButtonsArray) {
                            const person = index;
                            radioButtonsArray.map((option, index) => {
                                //Set afwijkend adres true
                                if (option.label === 'Ja' && option.selected) {
                                    updatePersonAdres(person, 'gezin', 1);
                                }
                                //Set afwijkend adres false
                                if (option.label === 'Nee' && option.selected) {
                                    updatePersonAdres(person, 'gezin', 0);
                                }
                            })
                        }
                        var yesSelected = false;
                        var noSelected = true;
                        if (item.afwijkend_adres === 1) {
                            noSelected = false;
                            yesSelected = true;
                        } else {
                            noSelected = true;
                            yesSelected = false;
                        }
                        
                        const radioButtonsData = [{
                            id: 1,
                            borderColor: Colors[7],
                            size: 21,
                            color: Colors[8],
                            label: 'Ja',
                            value: 1,
                            selected: yesSelected
                        }, {
                            id: 2,
                            borderColor: Colors[7],
                            size: 21,
                            color: Colors[8],
                            label: 'Nee',
                            value: 0,
                            selected: noSelected
                        }];

                        
                        function onPressRadioButton(radioButtonsArray) {
                            const person = index;
                            radioButtonsArray.map((option, index) => {
                                //Set afwijkend adres true
                                if (option.label === 'Ja' && option.selected) {
                                    updatePersonAdres(person, 'afwijkend_adres', 1);
                                }
                                //Set afwijkend adres false
                                if (option.label === 'Nee' && option.selected) {
                                    updatePersonAdres(person, 'afwijkend_adres', 0);
                                }
                            })
                        }

                        const setCropImage = (data) => {
                            updatePasfoto(index, 'pasfotoToCrop', data);
                        }
                        const setCroppedImage = (data) => {
                            updatePasfoto(index, 'pasfoto', data);
                        }

                        var loadPasfoto = global.app_url + item.pasfoto;
                        if (item.pasfoto && item.pasfoto.startsWith("data:")) {
                            loadPasfoto = item.pasfoto;
                        }

                        // if (item.postcode && item.huisnummer && !item.straatnaam && !item.woonplaats) {
                        //     fetchAdres(item.postcode, item.huisnummer);
                        // }

                        return <View key={index}>
                            {
                                (index === activeTab) ?
                                <View style={styles('tabContent')} key={index}>
                                    <View style={styles('form')}>
                                        <View style={styles('column')}>

                                            {/* Voornaam */}
                                            <View>
                                                <View style={styles('formLabel')}>
                                                    <Text style={styles('formLabelText')}>{'Voornaam'}</Text>
                                                    <Text style={styles('required')}>{'*'}</Text>
                                                </View>
                                                <View>
                                                    <HVInput 
                                                        onChangeText={updatePerson(index, 'voornaam', /(^[A-Za-zÀ-ȕ_ _-]+$)/)}
                                                        value={item.voornaam}
                                                        border={true}
                                                        placeHolder={'Voornaam'}
                                                        width='100%'
                                                    />
                                                </View>
                                            </View>
                                            {/* Voornaam */}

                                            {/* Achternaam */}
                                            <View>
                                                <View style={styles('formLabel')}>
                                                    <Text style={styles('formLabelText')}>{'Achternaam'}</Text>
                                                    <Text style={styles('required')}>{'*'}</Text>
                                                </View>
                                                <View>
                                                    <HVInput 
                                                        onChangeText={updatePerson(index, 'achternaam', /(^[A-Za-zÀ-ȕ_ _-]+$)/)} 
                                                        value={item.achternaam}
                                                        border={true}
                                                        placeHolder={'Achternaam'}
                                                        width='100%'
                                                    />
                                                </View>
                                            </View>
                                            {/* Achternaam */}


                                            {/* Geboortedatum */}
                                            <View>
                                                <View style={styles('formLabel')}>
                                                    <Text style={styles('formLabelText')}>{'Geboortedatum'}</Text>
                                                    <Text style={styles('required')}>{'*'}</Text>
                                                </View>
                                                <View>
                                                    <TextInputMask
                                                        type={'datetime'}
                                                        style={styles('inputWithBorder')}
                                                        options={{
                                                            format: 'DD-MM-YYYY'
                                                        }}
                                                        value={item.geboortedatum}
                                                        onChangeText={updatePerson(index, 'geboortedatum', /^[0-9._-]{0,10}$/)}
                                                        placeholder={'DD-MM-JJJJ'}
                                                    />
                                                </View>
                                            </View>
                                            {/* Geboortedatum */}

                                            {/* E-mailadres */}
                                            {
                                                index === 0 ?
                                                <View>
                                                    <View style={styles('formLabel')}>
                                                        <Text style={styles('formLabelText')}>{'E-mailadres'}</Text>
                                                        <Text style={styles('required')}>{'*'}</Text>
                                                    </View>
                                                    <View>
                                                        <HVInput 
                                                            onChangeText={updatePerson(index, 'emailadres', /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)} 
                                                            value={item.email}
                                                            border={true}
                                                            placeHolder={'email@email.nl'}
                                                            width='100%'
                                                        />
                                                    </View>
                                                </View>
                                                :
                                                null
                                            } 
                                            {/* E-mailadres */}

                                            {/* Telefoonnummer */}
                                            <View>
                                                <View style={styles('formLabel')}>
                                                    <Text style={styles('formLabelText')}>{'Telefoonnummer'}</Text>
                                                </View>
                                                <View>
                                                    <HVInput 
                                                        onChangeText={updatePerson(index, 'telefoon', /^[0-9._-]{0,11}$/)} 
                                                        value={item.telefoon}
                                                        border={true}
                                                        placeHolder={'06-12345678'}
                                                        width='100%'
                                                    />
                                                </View>
                                            </View>
                                            {/* Telefoonnummer */}

                                            
                                            {/* Pasfoto */}
                                                <View style={styles('formLabel')}>
                                                    <Text style={styles('formLabelText')}>{'Pasfoto zwemkaart'}</Text>
                                                    <Text style={styles('required')}>{'*'}</Text>
                                                </View>
                                                <ImageCrop
                                                    showUserData={true}
                                                    imageFile={item.pasfotoToCrop}
                                                    croppedImage={item.new ? '' : loadPasfoto}
                                                    setCropImage={setCropImage}
                                                    setCroppedImage={setCroppedImage}
                                                    voornaam={item.voornaam}
                                                    achternaam={item.achternaam}
                                                />
                                            {/* Pasfoto */}

                                        </View>

                                        <View style={styles('column')}>

                                            {/* Gezinsnaam */}
                                            {
                                                index === 0 ?
                                                <View>
                                                   {item.gezin === 0 ? <View>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7] }}>{'Gezin'}</Text>
                                                    </View>
                                                   <RadioGroup
                                                        containerStyle={{ marginVertical: 10, right: 10 }}
                                                        radioButtons={radioButtonsTwoData}
                                                        onPress={onPressRadioTwoButton}
                                                        layout={'row'}
                                                    /> </View>: null }
                                                    {item.gezin === 1 ? <>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7] }}>{'Gezins naam'}</Text>
                                                            <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                                                        </View>
                                                        <View style={{ marginVertical: -5 }}>
                                                        <HVInput
                                                                onChangeText={updatePerson(index, 'gezinsnaam', /(^[A-Za-zÀ-ȕ_ _-]+$)/)}
                                                                value={item.gezinsnaam}
                                                                border={true}
                                                                placeHolder={'Gezins naam'}
                                                                width='100%'
                                                            />
                                                        </View>
                                                    </> : null}
                                                </View>
                                                :
                                                null
                                            } 
                                            {/* Gezinsnaam */}

                                            {
                                                index > 0 ?
                                                    <View>
                                                    <View style={styles('formLabel')}>
                                                        <Text style={styles('formLabelText')}>{'Afwijkend adres'}</Text>
                                                    </View>
                                                    <View>
                                                        <RadioGroup
                                                            containerStyle={{ marginVertical: 10, right: 10 }}
                                                            radioButtons={radioButtonsData}
                                                            onPress={onPressRadioButton}
                                                            layout={'row'}
                                                        />
                                                    </View>
                                                </View>
                                                :
                                                null
                                            }
                                            

                                            {
                                                item.afwijkend_adres || index === 0 ?
                                                <View>
                                                    {/* Postcode */}
                                                    <View>
                                                        <View style={styles('formLabel')}>
                                                            <Text style={styles('formLabelText')}>{'Postcode'}</Text>
                                                            <Text style={styles('required')}>{'*'}</Text>
                                                        </View>
                                                        <View>
                                                            <HVInput
                                                                onChangeText={updatePerson(index, 'postcode', /^[A-Za-z0-9._-]{0,6}$/)}
                                                                value={item.postcode}
                                                                border={true}
                                                                placeHolder={'Postcode'}
                                                                width='100%'
                                                            />
                                                        </View>
                                                    </View>
                                                    {/* Postcode */}

                                                    {/* Huisnummer + toevoeging */}
                                                    <View style={styles('huisnummer')}>
                                                        <View style={styles('nummer')}>
                                                            <View style={styles('formLabel')}>
                                                                <Text style={styles('formLabelText')}>{'Huisnummer'}</Text>
                                                                <Text style={styles('required')}>{'*'}</Text>
                                                            </View>
                                                            <View>
                                                                <HVInput 
                                                                    onChangeText={updatePerson(index, 'huisnummer', /^[0-9]+$/)} 
                                                                    value={item.huisnummer}
                                                                    border={true}
                                                                    placeHolder={'Huisnummer'}
                                                                    width='100%'
                                                                />
                                                            </View>
                                                        </View>
                                                        <View style={styles('toevoeging')}>
                                                            <View style={styles('formLabel')}>
                                                                <Text style={styles('formLabelText')}>{'Toevoeging'}</Text>
                                                            </View>
                                                            <View>
                                                                <HVInput 
                                                                    onChangeText={updatePerson(index, 'toevoeging', /^[A-Za-z0-9._-_ ]+$/)} 
                                                                    value={item.toevoeging}
                                                                    border={true}
                                                                    placeHolder={'Toevoeging'}
                                                                    width='100%'
                                                                />
                                                            </View>
                                                        </View>
                                                    </View>
                                                    {/* Huisnummer + toevoeging */}

                                                    {/* Straatnaam */}
                                                    <View>
                                                        <View style={styles('formLabel')}>
                                                            <Text style={styles('formLabelText')}>{'Straatnaam'}</Text>
                                                            <Text style={styles('required')}>{'*'}</Text>
                                                        </View>
                                                        <View>
                                                            <HVInput 
                                                                onChangeText={updatePerson(index, 'straat', /^[A-Za-z0-9._-_ _-]+$/)} 
                                                                value={item.straat}
                                                                border={true}
                                                                placeHolder={'Straatnaam'}
                                                                width='100%'
                                                            />
                                                        </View>
                                                    </View>
                                                    {/* Straatnaam */}

                                                    {/* Woonplaats */}
                                                    <View>
                                                        <View style={styles('formLabel')}>
                                                            <Text style={styles('formLabelText')}>{'Woonplaats'}</Text>
                                                            <Text style={styles('required')}>{'*'}</Text>
                                                        </View>
                                                        <View>
                                                            <HVInput 
                                                                onChangeText={updatePerson(index, 'woonplaats', /^[A-Za-z_ _-]+$/)} 
                                                                value={item.woonplaats}
                                                                border={true}
                                                                placeHolder={'Woonplaats'}
                                                                width='100%'
                                                            />
                                                        </View>
                                                    </View>
                                                    {/* Woonplaats */}
                                                </View>
                                                :
                                                null
                                            }

                                        </View>
                                    </View>
                                </View>
                                :
                                null
                            }   
                            </View> 
                    })
                }
            </View>
            {
                posting ? 
                <ActivityIndicator size="large" />
                :
                <View style={styles('save')}>
                    {
                        valid ?
                        null
                        :
                        <Text style={styles('errorMessage')}>Alle velden met een * zijn verplicht of 1 van de velden is niet correct ingevuld.</Text>
                    }
                    {
                        maxAdults ?
                        <Text style={styles('errorMessage')}>Je kunt maximaal 2 volwassenen in een gezin hebben.</Text>
                        :
                        null
                    }
                    <HVButton caption={'Gegevens opslaan'} color={Colors[8]} width={'100%'} onPress={() => {
                        validateFields(arrayPersons);
                        setValid(true);
                        setPosting(true);
                        setMaxAdults(false);
                    }} />
                </View>
            }
        </View>
        :
        <ActivityIndicator size="large" />
        }
    </View>
  );
}


const useStyles = CreateResponsiveStyle(
  {
    main: {
      display: 'flex',
    },
    intro: {
        fontSize: 16,
        textAlign: 'left',
        marginBottom: 30
    },
    familyTabs: {
        marginTop: 5,
        width: '100%',
    },
    tabsRow: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        overflow: 'auto'
    },
    swipeIcon: {
        textAlign: 'center',
        marginBottom: 25
    },
    tabOne: {
        padding: 15,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
    },
    tabOneActive: {
        backgroundColor: '#EFF8FC',
        padding: 15,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
    },
    tab: {
        padding: 15,
        paddingRight: 35,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
    },
    tabActive: {
        backgroundColor: '#EFF8FC',
        padding: 15,
        paddingRight: 35,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
    },
    tabText: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    remove: {
        position: 'absolute',
        top: 10,
        right: 5
    },
    addTab: {
        padding: 15,
    },
    textAdd: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#62BAE3'
    },
    tabContent: {
        padding: 35,
        backgroundColor: '#EFF8FC'
    },
    tabContentTitle: {
        fontSize: 28
    },
    formLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    formLabelText: {
        fontWeight: '500', 
        fontSize: 14, 
        color: Colors[7]
    },
    required: {
        fontWeight: '700', 
        fontSize: 16, 
        color: 'red', 
        marginLeft: 5,
    },
    errorMessage: {
        textAlign: 'center',
        marginVertical: 15,
        color: 'red',
        fontWeight: 'bold',
        fontSize: 18
    },
    huisnummer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    nummer: {
        width: '60%'
    },
    toevoeging: {
        width: '35%'
    },
    save: {
        display: 'flex',
        width: '100%'
    },
    inputWithBorder: {
        fontFamily: 'Regular', fontSize: 15,
        backgroundColor: 'white',
        color: 'black',
        marginVertical: 10,
        padding: 10,
        borderWidth: 1.5, 
        borderColor: Colors[1]
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
        form: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        column: {
            width: '48%'
        }
    },
    [DEVICE_SIZES.LARGE_DEVICE]: {
        swipeIcon: {
            display: 'none'
        },
        form: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        column: {
            width: '48%'
        }
    },
    [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
        swipeIcon: {
            display: 'none'
        },
        form: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        column: {
            width: '48%'
        }
    },
  },
)
