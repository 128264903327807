// Libraries
import React from 'react';
import { TouchableOpacity, Text, View } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

// Schermen
import LoadingScreen from '../screens/loadingScreen';
import EersteScherm from '../screens/EersteScherm';
import RegistreerScherm from '../screens/registreerScherm';
import InlogScherm from '../screens/loginScherm';
import WachtwoordVergeten from '../screens/wwVergeten';
import GezinToevoegen from '../screens/gezinsLidToevoegen';
import GegevensAanvullen from '../screens/gegevensAanvullen';
import shopScreen from '../screens/shopScreen';
import accountScreen from '../screens/accountScreen';
import GezinsScreen from '../screens/gezinScreen';
import WachtwoordWijzigen from '../screens/wwWijzigen';
import WachtwoordHerstellen from '../screens/wwHerstellen';
import WinkelMand from '../screens/winkelmand';
import EmailBevestigen from '../screens/emailBevestigen';
import BestellingDetails from '../screens/bestellingDetails';
import DankjeScherm from '../screens/dankjeScreen';
import Contact from '../screens/contact';
import Privacyverklaring from '../screens/privacyverklaring';
import AlgemeneVoorwaarden from '../screens/algemeneVoorwaarden';
import Retourbeleid from '../screens/retourbeleid';

//Niet bestaande schermen worden opgevangen door NOTFOUND
import NotFoundScreen from '../screens/geenSchermGevonden';

const Stack = createNativeStackNavigator();

export default function StackNavigatorMain() {

    const defaultOptions = {
        headerShown: false, 
        title: "Zwembad 't Hemmerven in Hem, Noord-Holland"
    }

    return(
        <Stack.Navigator>
            <Stack.Screen name='Loading' component= { LoadingScreen } options={defaultOptions}/>
            <Stack.Screen name='EersteScherm' component= { EersteScherm } options={defaultOptions}/>
            <Stack.Screen name='Login' component= { InlogScherm } options={defaultOptions}/>
            <Stack.Screen name='Registreer' component= { RegistreerScherm } options={defaultOptions}/>
            <Stack.Screen name='GegevensAanvullen' component= { GegevensAanvullen } options={defaultOptions}/>
            <Stack.Screen name='WwVergeten' component={ WachtwoordVergeten } options={defaultOptions}/>
            <Stack.Screen name='GezinToevoegen' component={ GezinToevoegen } options={defaultOptions}/>
            <Stack.Screen name='shopScreen' component={ shopScreen } options={defaultOptions}/>
            <Stack.Screen name='accountScreen' component={ accountScreen } options={defaultOptions}/>
            <Stack.Screen name='GezinsScreen' component={ GezinsScreen } options={defaultOptions}/>
            <Stack.Screen name='wwWijzigen' component={ WachtwoordWijzigen } options={defaultOptions}/>
            <Stack.Screen name='wwHerstellen' component={ WachtwoordHerstellen } options={defaultOptions}/>
            <Stack.Screen name='Winkelmand' component={ WinkelMand } options={defaultOptions}/>
            <Stack.Screen name='EmailBevestigen' component={ EmailBevestigen } options={defaultOptions}/>
            <Stack.Screen name='BestellingDetails' component={ BestellingDetails } options={defaultOptions}/>
            <Stack.Screen name='DankjeScherm' component={ DankjeScherm } options={defaultOptions}/>
            <Stack.Screen name='Privacyverklaring' component={ Privacyverklaring } options={defaultOptions}/>
            <Stack.Screen name='Contact' component={ Contact } options={defaultOptions}/>
            <Stack.Screen name='AlgemeneVoorwaarden' component={ AlgemeneVoorwaarden } options={defaultOptions}/>
            <Stack.Screen name='Retourbeleid' component={ Retourbeleid } options={defaultOptions}/>
            {/* Niet bestaande schermen worden opgevangen door NOTFOUND */}
            <Stack.Screen name="NotFound" component={ NotFoundScreen } options={{ title: 'Oops!', headerShown: false }} />
        </Stack.Navigator>
    )
}