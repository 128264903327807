import React, { useContext, useState } from 'react';
import { Text, View, Image, TouchableOpacity, Picker } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { NavigationHelpersContext } from '@react-navigation/native';

export default function BestellingCard (props) {
    const navigation = props.navigation;
    const title = props.bestelling_id;
    const orderDate = props.orderDate;
    const aantalArtikelen = props.hoeveelheidArtikelen;
    const price = props.price;

    const { styles, deviceSize } = useStyles();

    return(
        <TouchableOpacity style={styles('card')} onPress={() => navigation.push('BestellingDetails', { id: title})}>
            <View style={styles('productInfo')}>
                <Text style={styles('title')}>{'Bestellingsnummer #'+ title}</Text>
                <View style={styles('orderMeta')}>
                    <Text style={styles('meta')}>Besteld op: {orderDate}</Text>
                    <Text style={styles('meta')}>{aantalArtikelen + ' artikelen'}</Text>
                </View>
            </View>
            <View style={styles('productPrice')}>
                <Text style={styles('price')}>{price}</Text>
            </View>
        </TouchableOpacity>
    );
};

const useStyles = CreateResponsiveStyle(
    {
        card: {
            width: '100%',
            borderWidth: 1,
            borderRadius: 1,
            borderColor: '#7F959F',
            marginBottom: 20,
            paddingVertical: 30,

        },
        productInfo: {
            paddingHorizontal: 35
        },
        orderMeta: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start'
        },
        title: {
            fontSize: 22,
            fontWeight: 'bold'
        },
        meta: {
            fontSize: 16,
            marginVertical: 12,
            marginRight: 35,
            color: '#5A5A5A'
        },
        productPrice: {
            flexDirection: 'row',
            textAlign: 'left'
        },
        price: {
            fontSize: 22,
            fontWeight: 'bold',
            alignSelf: 'center'
        }
    },
    {
        [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
        },
        [DEVICE_SIZES.MEDIUM_DEVICE]: {
            card: {
                width: '100%',
                borderWidth: 1,
                borderColor: '#7F959F',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20
            },
            productInfo: {
                width: '55%'
            },
            productPrice: {
                paddingRight: 35,
            }
        },
        [DEVICE_SIZES.LARGE_DEVICE]: {
            card: {
                width: '49%',
                borderWidth: 1,
                borderColor: '#7F959F',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20
            },
            productInfo: {
                width: '55%'
            },
            productPrice: {
                paddingRight: 35,
                textAlign: 'right'
            },
        },
        [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
            card: {
                width: '49%',
                borderWidth: 1,
                borderColor: '#7F959F',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20
            },
            productInfo: {
                width: '65%'
            },
            productPrice: {
                paddingRight: 35,
                textAlign: 'right'
            }
        },
    },
  )
  
