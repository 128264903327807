// Libraries
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity, TouchableWithoutFeedback, StyleSheet, Image, ActivityIndicator} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import api from '../settings/AxiosSetup';

// Components
import { HVButton, HVCheckbox, HVInput, AltButton } from '../components/formObjects';
import IconSetter from '../components/iconSetter';

// Functions
import { getData } from '../functions/storage';

// Global
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_CART } from '../context/actions/winkelActions';
import { SET_INSTELLINGEN } from '../context/actions/commonActions';

// Styles
import Colors from '../styles/colors';

// SVG images

// Dit zijn animatie instellingen
// const runTiming = (clock, value, dest) => {
//     const state = {
//         finished: new Value(0),
//         position: new Value(0),
//         time: new Value(0),
//         frameTime: new Value(0),
//     };

//     const config = {
//         duration: 500,
//         toValue: new Value(0),
//         easing: EasingNode.inOut(EasingNode.ease),
//     };

//     return [
//         cond(clockRunning(clock), 0, [
//             // If the clock isn't running we reset all the animation params and start the clock
//             set(state.finished, 0),
//             set(state.time, 0),
//             set(state.position, value),
//             set(state.frameTime, 0),
//             set(config.toValue, dest),
//             startClock(clock),
//         ]),
//         // we run the step here that is going to update position
//         timing(clock, state, config),
//         // if the animation is over we stop the clock
//         cond(state.finished, stopClock(clock)),
//         // we made the block return the updated position
//         state.position,
//     ];
// }

export default function InlogScherm ({ navigation }) {
    const cartDispatch = useContext(GlobalDispatcher).cartDispatch;
    const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
    const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
    const [animatieTrigger, setAnimatieTrigger] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [secureToggle, setSecureToggle] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [standard, setStandard] = useState(false);
    const [disabled, setDisabled] = useState(false);

    /*const fetchWinkelmand = async () => {
        const response = await AsyncStorage.getItem('cartItems');
        // console.log(response);
        if(response){
          cartDispatch({ type: SET_CART, value: JSON.parse(response)});
        }
      };*/

    const postLogin = async () => {

        const regex = /(^[^@]+@[^@]+\.[a-zA-Z]{2,}$)/;
        const str = email;
        let t;

        if ((t = regex.exec(str)) !== null) {
            // The result can be accessed through the `m`-variable.
            t.forEach((match, groupIndex) => {
                // console.log(`Found match, group ${groupIndex}: ${match}`);
            });
            try {
                if (password === '') {
                    setError(true);
                    setErrorMessage('Wachtwoord is verplicht');
                    return;
                }
                const response = await api.post('login?code=' + code + '&email=' + email + '&wachtwoord=' + encodeURIComponent(password));
                console.log(response.data);
                if (response.status == 429) {
                    // console.log('Overloaded, wait a couple of seconds and try again.');
                    alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
                    return;

                } else if (response.data.success) {
                    // setLoggedIn(true);
                    if (response.data.data === 0) {
                        setError(true);
                        setDisabled(false);
                        setErrorMessage(response.data.error);
                        return;
                    }
                    setError(false);
                    await AsyncStorage.setItem('registered', 'true');
                    await AsyncStorage.setItem('ingelogd', 'true');
                    await AsyncStorage.setItem('bearerToken', response.data.data);
                    const instellingen = await api.get('instelling?code=' + code);
                    if (instellingen.status === 429) {
                        // console.log('Overloaded, wait a couple of seconds and try again.');
                        alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
                    }
                    if (instellingen.status === 500) {
                        // console.log('Er is iets fout gegaan');
                    }
                    if (instellingen.data.success) {
                        commonDispatch({ type: SET_INSTELLINGEN, maxGezin: instellingen.data.data[0].waarde, pasFotoBreedte: instellingen.data.data[1].waarde, pasFotoHoogte: instellingen.data.data[2].waarde, factuurBedrag: instellingen.data.data[3].waarde });
                    }

                    if (checked) {
                        await AsyncStorage.setItem('onthouden', 'true');
                        await AsyncStorage.setItem('email', email);
                        await AsyncStorage.setItem('password', password);
                    } else {
                        await AsyncStorage.setItem('onthouden', 'false');
                    }
                    // fetchWinkelmand();
                    if(response.data.message === false){
                        console.log('Ja');
                        await AsyncStorage.setItem('userId', response.data.data.gebruiker_id);
                        await AsyncStorage.setItem('voornaam', response.data.data.voornaam);
                        await AsyncStorage.setItem('achternaam', response.data.data.achternaam);
                        await AsyncStorage.setItem('geboortedatum', response.data.data.geboortedatum);
                        await AsyncStorage.setItem('email', response.data.data.email);
                        await AsyncStorage.setItem('password', response.data.data.password);
                        navigation.push('GegevensAanvullen', {refresher: 1});
                    }else {
                        console.log('Nee');
                        navigation.push('shopScreen');
                    }
                } else {
                    setError(true);
                    setDisabled(false);
                    setErrorMessage(response.data.error);
                };

            }
            catch (err) {
                // console.log(err);
            };
        } else {
            if (password === '' && email === '') {
                setError(true);
                setDisabled(false);
                setErrorMessage('Alle velden met een rode * zijn verplicht');
                return;
            } else if (email === '') {
                setError(true);
                setDisabled(false);
                setErrorMessage('Email is verplicht');
                return;
            }
            setError(true);
            setDisabled(false);
            setErrorMessage('Dit is geen geldig email adres');
        }
    }
    // Dit zijn nog meer animatie instellingen
    // const clock = new Clock();
    // const transY = new Value(0);

    // const transYAnimated = set(
    //     transY,
    //     runTiming(clock, -400, -120)
    // );

    useEffect(() => {
        const fetchUserInfo = async () => {
            let onthouden = await AsyncStorage.getItem('onthouden');
            if (JSON.parse(onthouden)) {
                let userEmail = await AsyncStorage.getItem('email');
                let userPassword = await AsyncStorage.getItem('password');
                setEmail(userEmail);
                setPassword(userPassword);
                setStandard(true);
            }
        }
        fetchUserInfo();
    }, []);

    const { styles, deviceSize } = useStyles();

    return(
        <View style={styles('main')}>
        <View style={styles('login')}>
            {confirmed ?
                <Animated.View style={[{ position: 'absolute', width: '108.5%', justifyContent: 'center', backgroundColor: Colors[7], top: 9, zIndex: 10, borderTopLeftRadius: 5, borderTopRightRadius: 5, paddingHorizontal: 50, paddingVertical: 20, right: -20 }, animatieTrigger ? { transform: [{ translateY: transYAnimated }] } : null]}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ fontSize: 16, fontWeight: '700', color: 'white', marginVertical: 10, fontFamily: 'Regular' }}>{'Gelukt!'}</Text>
                        <TouchableOpacity onPress={() => setConfirmed(false)} style={{ top: 5 }}>
                            <IconSetter family={'Fontello'} name='close' size={16} color={'white'} />
                        </TouchableOpacity>
                    </View>
                    <Text style={{ fontSize: 16, color: 'white', fontFamily: 'Regular' }}>{'Je e-mailadres is bevestigd. Probeer gerust in te loggen, hierbij kan je de site nu volledig gebruiken.'}</Text>
                </Animated.View>
                : null}
            {error ? <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', alignSelf: 'center' }}>{errorMessage}</Text> : null}
                <View style={{ height: 40, marginBottom: 30, flexDirection: 'row', justifyContent: 'space-evenly'}}>
                    <View style={{ width: '50%', backgroundColor: '#d7f1f7', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ color: Colors[8] }}>{'Inloggen'}</Text>
                    </View>
                    <TouchableOpacity onPress={() => navigation.push('Registreer')} style={{ width: '50%', backgroundColor: Colors[8], height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ color: 'white' }}>Registreren</Text>
                    </TouchableOpacity>
                </View>
            <View style={{ flexDirection: 'row', alignItems: 'flex-start ' }}>
                <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[1] }}>{'E-mailadres'}</Text>
                <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
            </View>
            <View style={{ marginVertical: -5 }}>
                <HVInput onChangeText={(input) => [setEmail(input), setError(false), setAnimatieTrigger(false)]} value={email} border={true} placeHolder={'E-mailadres'} width={'100%'} />
            </View>
            <View style={{ marginVertical: 5, }}>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[1] }}>{'Wachtwoord'}</Text>
                <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
            </View>
            <View style={{ marginVertical: -5 }}>
            </View>
            <View style={{ marginVertical: 5, }}>
                <HVInput onChangeText={(input) => [setPassword(input), setError(false), setAnimatieTrigger(false)]} value={password} password={true} secureToggle={secureToggle} onPress={() => setSecureToggle(!secureToggle)} border={true} placeHolder={'Wachtwoord'} width={'100%'} onEndEdit={() => postLogin()} />
            </View>
            { standard ? <HVCheckbox standard={true} onPress={() => setChecked(!checked)}>{'Onthoud mij'}</HVCheckbox> : <HVCheckbox onPress={() => setChecked(!checked)}>{'Onthoud mij'}</HVCheckbox>}
            <View>
            { disabled ? <ActivityIndicator color={'white'} style={{ position: 'absolute', top: 15, alignSelf: 'center', zIndex: 999}} size={20}/> : null }
            <HVButton caption={ disabled ? ' ' : 'Inloggen' } color={Colors[8]} width={'100%'} onPress={() =>   [  
                postLogin(), 
                setDisabled(true),
                setTimeout(() => {
                    setDisabled(false)
                }, 1000)]} 
                disabled={disabled}/>
            </View>
            <View style={{ height: 10 }} />
            <TouchableOpacity style={{ marginTop: 20, marginBottom: 30 }} onPress={() => navigation.push('WwVergeten')}>
                <Text style={{ color: Colors[8], fontFamily: 'Regular', fontSize: 16, alignSelf: 'center', fontWeight: '700' }}>Wachtwoord vergeten?</Text>
            </TouchableOpacity>
        </View>
        {/* <TouchableOpacity style={{ zIndex: 2, flexDirection: 'row', alignSelf: 'center', position: 'absolute', bottom: 15, zIndex: 1 }} onPress={() => navigation.goBack()}>
                    <View style={{ marginRight: 5, bottom: -4 }}>
                        <IconSetter name='back' family='Fontello' size={14} color={Colors[8]} />
                    </View>
                    <Text style={{ color: Colors[8], fontFamily: 'Regular', fontSize: 16 }}>{"Terug naar t' Hemmerven "}</Text>
                </TouchableOpacity> */}
    </View>
    )
}


const useStyles = CreateResponsiveStyle(
    {
        main: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F2F2F2'
        },
        login: {
            marginTop: 50,
            backgroundColor: 'white', 
            padding: 35,
            width: '100%',
            maxWidth: 500
        }
    },
    {
      [DEVICE_SIZES.MEDIUM_DEVICE]: {
        
      },
      [DEVICE_SIZES.LARGE_DEVICE]: {
        
      },
      [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
        
      },
    },
)