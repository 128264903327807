// Libraries
import React, { useState, useEffect, useContext, useMemo } from 'react';
import * as Network from 'expo-network';

// Components
import Loader from '../components/loader';

// Functions
import { adjustHelpTekst, adjustFaq } from '../functions/common';
import WinkelMandFetch from "../functions/winkelMandFetch";
import { setData, getData } from '../functions/storage';
import { registerForPushNotificationsAsync } from '../functions/expoPushToken'; 

// Global
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_ALL as SET_ALL_COMMON, SET_DEVICE_ID } from '../context/actions/commonActions';
import { SET_ALL as SET_ALL_USER } from '../context/actions/userActions';
import { SET_CART_PREVIOUS_SESSION } from '../context/actions/winkelActions';

// Default component
let hasNetwork = false;

const ReadStorage = ( props ) => {
  const commonDispatch = useContext( GlobalDispatcher ).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;
  const userDispatch = useContext( GlobalDispatcher ).userDispatch;
  const userContext = useContext(GlobalContext).userState;
  const code = commonContext.code;
  var hasPermissions = false;
  const [ loading, setLoading ] = useState( true );
  const [ readStorageDone, setReadStorageDone ] = useState( false );



  if( !readStorageDone ){
    setReadStorageDone( true );


    WinkelMandFetch();

    Network.getNetworkStateAsync()
      .then(result => {
        if (result.isConnected && result.isInternetReachable) {
          hasNetwork = true;
          getData('deviceId', 'leeg')
            .then(deviceId => {
              // nog geen id in de storage, dus ophalen
              /*if (deviceId === 'leeg') {
                // console.log('id maken via expoPushToken');
                registerForPushNotificationsAsync()
                  .then(deviceId => {
                    // bewaren in de context
                    commonDispatch({ type: SET_DEVICE_ID, value: { deviceId, permissions: hasPermissions } });
                    getData('common', commonContext)
                      .then(common => {
                        commonData({ deviceId, common })
                      })
                  }
                  );
              }
              else {*/
                // bewaren in de context

              //}
              setLoading(false);
            })
        }
        else {
          // geen netwerk verbinding
          hasNetwork = false;
          setLoading(false);
        }
      }
      )
  }

  if( loading ){
    return <Loader/>;
  }
  else {
    if ( hasNetwork ) {
      return (
        <>
          { props.children }
        </> 
      )
    }
    else {
      return     
    }
  }
}

export default ReadStorage;

