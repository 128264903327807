// Libraries
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Text, View, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import api from '../settings/AxiosSetup';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RadioGroup from 'react-native-radio-buttons-group';

// Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState.jsx';
import { SET_CART } from '../context/actions/winkelActions';

// Components
import OrderItem from '../components/orderItem';
import { HVButton, HVInput } from '../components/formObjects';
import Loader from '../components/loader';
import Footer from '../components/footer';

// Styling
import Colors from '../styles/colors';

//Icons
import { AntDesign } from '@expo/vector-icons';


export default function WinkelMand({ navigation }) {

  const cartContext = useContext(GlobalContext).cartState;
  const commonContext = useContext(GlobalContext).commonState;
  const cartDispatch = useContext(GlobalDispatcher).cartDispatch;
  const cartItems = cartContext.cartItems;

  const factuurBedrag = commonContext.factuurBedrag;

  const pakketkortingLabel = cartItems.pakketkorting_label;
  const gebruikerNietCompleet = cartItems.gebruiker_niet_compleet;
  const totaalBedrag = cartItems.totaal_bedrag;
  const disabledKnop = cartItems.disable_knop;

  const [sponsering, setSponsering] = useState(cartContext.cartItems.sponsor_bedrag);
  //const [pakketKorting, setPakketKorting] = useState('');
  const [apiTrigger, setApiTrigger] = useState(false);
  //const [totaalBedrag, setTotaalBedrag] = useState(accounting.formatMoney(0, { symbol: "€ " }));
  const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
  const [disabled, setDisabled] = useState(false);
  //// console.log(cartItems.winkelwagen);
  const [factuur, setFactuur] = useState(0);

  const [loading, setLoading] = useState(true);

  const radioButtonsData = [{
    id: 1,
    borderColor: Colors[7],
    size: 21,
    color: Colors[8],
    label: 'Ja',
    value: 1,
    selected: false
  }, {
    id: 2,
    borderColor: Colors[7],
    size: 21,
    color: Colors[8],
    label: 'Nee',
    value: 0,
    selected: true
  }];

const [radioButtons, setRadioButtons] = useState(radioButtonsData)

const onPressRadioButton = (radioButtonsArray) => {
  setRadioButtons(radioButtonsArray);

  radioButtonsArray.map((rdButton, index) => {
    if(rdButton.selected){
      // console.log(rdButton.value);
      setFactuur(rdButton.value);
    }
  })
}

  /*const apiTotaalBerekening = async () => {
      let sponser_bedrag = 0.00;
      if(sponsering){
        sponser_bedrag = sponsering;
      }

      let cartItems = null;
      await getData('cartItems', {
              winkelwagen: [],
              sponsor_bedrag: ""
          })
          .then(result => {
              cartItems = result;
              // console.log(result);
              //cartDispatch( { type: SET_CART_PREVIOUS_SESSION, value: result } );
          }
      );


      cartDispatch({ type: SET_CART, value: JSON.parse(cartItems)});

      /*let json = {};
      if(cartItems.winkelwagen){
        json = encodeURIComponent(JSON.stringify({ winkelwagen: cartItems.winkelwagen, sponsor_bedrag: sponser_bedrag }));
      }
      //// console.log(json);

      //// console.log('winkelwagen?code=' + code + '&json=' + JSON.stringify({ winkelwagen: cartItems, sponsor_bedrag: sponser_bedrag }));
    try {
      const response = await api.get('winkelwagen?code=' + code + '&json=' + json);
      // console.log(response.data);
      // Success
      if (response.data.success) {
        setPakketKorting(response.data.data.pakketkorting_label);
        setTotaalBedrag(response.data.data.totaal_bedrag);
      }
    } catch (error) {
      // Error
      if (error.response) {
        if (error.response.status === 401) {
          // console.log('Geen toegang, niet ingelogd');
          await AsyncStorage.setItem('ingelogd', 'false');
          await AsyncStorage.removeItem('bearerToken');
          navigation.push('EersteScherm');
        }
        if (error.response.status === 429) {
          // console.log('Overloaded, wait a couple of seconds and try again.');
          alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
        }
        if (error.response.status === 500) {
          // console.log('Er is iets fout gegaan');
        }
      }
    }
  }*/

  const afrekenen = async () => {

    let sponser_bedrag = 0.00;
    if (sponsering != '0,00' && sponsering != 0.00) {
      sponser_bedrag = sponsering;
    }
    //// console.log("sponsor_bedrag: " + sponsor_bedrag);

    const bearerToken = await AsyncStorage.getItem('bearerToken');

    const url = global.api_url + 'betaal?code=' + code + '&json=' + encodeURIComponent(JSON.stringify({ winkelwagen: cartItems.winkelwagen, sponsor_bedrag: sponser_bedrag, factuur: factuur })) + "&bearerToken=" + bearerToken;
    //// console.log("url: " + url);

    //await api.get('betaal?code=' + code + '&json=' + encodeURIComponent(JSON.stringify({ winkelwagen: cartItems, sponsor_bedrag: null })));
    window.location.href = url;
    return false;
  }


  const verwerkSponsering = (sponsering) => {
    if (/^[0-9_,]+$/.test(sponsering) || sponsering === "") {
      cartItems.sponsor_bedrag = sponsering;
      setWinkelwagen(cartItems);
      setSponsering(sponsering);
    }
  }

  const setWinkelwagen = async (cartItems) => {
      // // console.log(cartItems);

      const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
      const response = await api.get('winkelwagen?code=' + code + '&json=' + encodeURIComponent(JSON.stringify(cartItems)));
      // // console.log(response.data);
      if (response.status == 401) {
          try {
              const response = await api.post('uitloggen?code=' + code);
              // console.log(response.data);
              if (response.status === 401) {
                  await AsyncStorage.setItem('ingelogd', 'false');
                  await AsyncStorage.removeItem('bearerToken');
                  navigation.push('Home');
              }
              if (response.status == 429) {
                  // console.log('Overloaded, wait a couple of seconds and try again.');
                  alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
              } else if (response.data.success) {
                  // console.log('Pushed');
              };
          }
          catch (err) {
              // console.log(err);
          };
          return;
      } else if (response.status == 429) {
          // console.log('Overloaded, wait a couple of seconds and try again.');
          alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
          return;
      } if (response.data.success) {
          //// console.log('Winkelwagen opslaan gelukt');
          const winkelwagen = response.data.data;
          //// console.log(winkelwagen);

          cartDispatch({ type: SET_CART, value: winkelwagen });
          //return winkelwagen;
      } else {
          // console.log('Niet gelukt')
      }
  }

  /*useEffect(() => {
    //apiTotaalBerekening();
  }, [apiTrigger, sponsering])
*/
  setTimeout(function(){
    setLoading(false);
  }, 500);


  const { styles, deviceSize } = useStyles();

  return useMemo(()=> {

    if( loading ){
      return <Loader/>;
    }
    else{
  return (
    <View style={styles('main')}>
      <View style={styles('container')}>
        <View style={styles('navbar')}>
          <View style={styles('header')}>
            <View>
              <Text style={styles('siteTitle')}>Winkelmand</Text>
            </View>
            <View style={styles('menuItems')}>
              <TouchableOpacity style={styles('menuItem')} onPress={() => navigation.push('shopScreen')}>
                <Text style={styles('menuItemText')}><AntDesign name="left" size={20} color="white" style={styles('indicator')} />Terug naar shop</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <View style={styles('orderList')}>
          {cartItems.winkelwagen ?
            cartItems.winkelwagen.map((item, index) => {
              return <OrderItem
                navigation={navigation}
                setApiTrigger={() => setApiTrigger(!apiTrigger)}
                key={index}
                abboMelding={item.abonnement_melding}
                minimaleLeeftijdMelding={item.minimale_leeftijd_melding}
                index={index}
                id={item.product_id}
                variatie_id={item.variatie_id}
                image={item.afbeelding}
                title={item.product_naam}
                price={item.subtotaal}
                aantal={item.variatie_id ? item.aantal : null}
                maxAantal={item.max_aantal ? item.max_aantal : null}
                boughtFor={item.gekocht_voor ? item.gekocht_voor : null}
                variatieNaam={item.product_variatie_naam ? item.product_variatie_naam : null}
              />
            }) : null
          }
        </View>
        <View style={styles('card')}>
          <View style={styles('imageColumn')}>
            <Image source={require('../images/HemmervenLogo.png')} style={styles('imageStyle')} />
          </View>
          <View style={styles('productInfo')}>
            <Text style={styles('title')}>{"'t Hemmerven sponsoring"}</Text>
            <View style={styles('orderMeta')}>
              <Text style={styles('meta')}>Steun 't Hemmerven door middel van een donatie.</Text>
              <Text style={styles('meta')}>Dit is niet verplicht</Text>
            </View>
          </View>
          <View style={styles('productPrice')}>
          <View style={{ display: 'flex', justifyContent: 'flex-end', maxWidth: 100 }}>
            <HVInput onChangeText={(input) => verwerkSponsering(input)} value={(sponsering == 0.00 || sponsering == undefined ? "" : sponsering.replace(".", ","))} border={true} placeHolder={'€ 0,00'} width={'100%'} />
          </View>
          </View>
        </View>
        <View style={{ alignItems: 'flex-end', width: '100%' }}>
          { parseInt(sponsering) > parseInt(factuurBedrag) ? <View>
          <Text style={{ fontWeight: '700', fontSize: 20, color: Colors[7] }}>{'Wilt u een factuur ontvangen?'}</Text>
            <RadioGroup
            radioButtons={radioButtons} 
            onPress={onPressRadioButton}
            layout={'row'}
            />
            </View>
          : null }
          {pakketkortingLabel ? <Text style={{ fontSize: 18, fontFamily: 'Regular' }}>{pakketkortingLabel}</Text> : null}
          {gebruikerNietCompleet ? <Text style={{ fontSize: 20, color: 'red', marginVertical: 6}}>{gebruikerNietCompleet}</Text> : null}
          <Text style={{ fontSize: 18 }}>Door op Afrekenen te klikken, plaats ik een bestelling met betaalplicht en ga ik akkoord met de <TouchableOpacity style={{ color: Colors[8] }} onPress={() => window.open('https://www.hemmerven.nl/wp-content/uploads/2022/04/algemene-voorwaarden-zwembad-het-Hemmerven.pdf')}>algemene voorwaarden.</TouchableOpacity></Text>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 20, width: 180 }}>
            <Text style={{ fontWeight: '700', fontSize: 20, color: Colors[7] }}>{'Totaal'}</Text>
            <Text style={{ fontWeight: '700', fontSize: 20, color: Colors[7] }}>{totaalBedrag}</Text>
          </View>
          { totaalBedrag !== "€ 0,00" && !disabledKnop ?  <View style={{ width: 200 }}>
          { disabled ? <ActivityIndicator color={'white'} style={{ position: 'absolute', top: 15, alignSelf: 'center', zIndex: 999}} size={20}/> : null }
            <HVButton caption={disabled ? ' ' : 'Afrekenen'} color={Colors[8]} padding={15} fontSize={20} height={55} width={'100%'} onPress={() => [
              afrekenen(),
              setDisabled(true),
              setTimeout(() => {
                  setDisabled(false)
              }, 1000)]} 
              disabled={disabled}/>
          </View> : null }
        </View>
      </View>
      <View style={{ maxWidth: 1600, width: '100%',}}>
        <Footer navigation={navigation}/>
        </View> 
    </View>
  );
}
  }, [loading, cartItems, sponsering, factuur])//Voeg de states toe zodat ze ook worden opgeslagen als er wat gewijzigd word in de inputs
}


const useStyles = CreateResponsiveStyle(
  {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white'
    },
    container: {
      width: '100%',
      maxWidth: 1600,
      padding: 25
    },
    header: {
      width: '100%'
    },
    navbar: {
      backgroundColor: '#62BAE3',
      padding: 30,
    },
    menuItem: {
      marginBottom: 10
    },
    menuItemText: {
      fontSize: 20,
      fontWeight: 'bold',
      color: 'white'
    },
    subMenuItemText: {
      fontSize: 18,
      fontWeight: 'bold',
      color: Colors[7]
    },
    navsubbar: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      marginVertical: 15,
      marginBottom: 50,
      paddingHorizontal: 25
    },
    navbaritems: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    subItem: {
      marginVertical: 10,
      position: 'relative'
    },
    indicator: {
      position: 'absolute',
      left: -20,
      top: 0
    },
    logout: {
      marginTop: 10
    },
    siteTitle: {
      fontSize: 28,
      marginBottom: 20,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: 'white'
    },
    orderList: {
      marginTop: 25
    },
    card: {
      width: '100%',
      borderWidth: 1,
      borderColor: Colors[8],
      marginBottom: 20
    },
    imageColumn: {
      width: '100%',
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    },
    imageStyle: {
      width: '100%',
      height: 220,
      resizeMode: 'contain'
    },
    productInfo: {
      paddingHorizontal: 35
    },
    orderMeta: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    },
    title: {
      fontSize: 22,
      fontWeight: 'bold'
    },
    meta: {
      fontSize: 16,
      marginVertical: 12,
      marginRight: 35,
      color: '#5A5A5A'
    },
    productPrice: {
      padding: 20,
      flexDirection: 'row',
      textAlign: 'left'
    },
    price: {
      fontSize: 22,
      fontWeight: 'bold',
      alignSelf: 'center'
    },
    aantal: {
      fontSize: 18,
      fontWeight: '500',
      alignSelf: 'center'
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 25
      },
      card: {
        width: '100%',
        height: 200,
        borderWidth: 1,
        borderColor: Colors[8],
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
      },
      imageColumn: {
        width: '15%',
        height: 200,
        justifyContent: 'center',
        borderRightWidth: 1,
        borderColor: Colors[8],
      },
      productInfo: {
        width: '55%'
      },
      productPrice: {
        paddingRight: 35,
      }
    },
    [DEVICE_SIZES.LARGE_DEVICE]: {
      siteTitle: {
        marginBottom: 0
      },
      header: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      menuItems: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      menuItem: {
        marginBottom: 0,
        marginLeft: 35
      },
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 35
      },
      card: {
        width: '100%',
        height: 200,
        borderWidth: 1,
        borderColor: Colors[8],
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
      },
      imageColumn: {
        width: '20%',
        justifyContent: 'center',
        height: 200,
        borderRightWidth: 1,
        borderColor: Colors[8],
      },
      productInfo: {
        width: '65%'
      },
      productPrice: {
        paddingRight: 35,
        textAlign: 'right'
      }
    },
    [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
      siteTitle: {
        marginBottom: 0
      },
      header: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      menuItems: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      menuItem: {
        marginBottom: 0,
        marginLeft: 35
      },
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 45
      },
      card: {
        width: '100%',
        height: 200,
        borderWidth: 1,
        borderColor: Colors[8],
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
      },
      imageColumn: {
          width: '20%',
          height: 200,
          justifyContent: 'center',
          borderRightWidth: 1,
          borderColor: Colors[8],
      },
      productInfo: {
          width: '65%'
      },
      productPrice: {
          paddingRight: 35,
          textAlign: 'right'
      }
    },
  },
)
