// Libraries
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Text, View, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import api from '../settings/AxiosSetup';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RadioGroup from 'react-native-radio-buttons-group';

// Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState.jsx';
import { SET_CART } from '../context/actions/winkelActions';

// Components
import OrderItem from '../components/orderItem';
import { HVButton, HVInput } from '../components/formObjects';
import Loader from '../components/loader';
import Footer from '../components/footer';

// Styling
import Colors from '../styles/colors';

//Icons
import { AntDesign } from '@expo/vector-icons';


export default function AlgemeneVoorwaarden ({ navigation }) {

    const [loading, setLoading] = useState(false);

  const sendContactForm = async () => {
    // console.log('Doe iets');
//       const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
//       const response = await api.get('winkelwagen?code=' + code + '&json=' + encodeURIComponent(JSON.stringify(cartItems)));
//       // // console.log(response.data);
//       if (response.status == 401) {
//           try {
//               const response = await api.post('uitloggen?code=' + code);
//               // console.log(response.data);
//               if (response.status === 401) {
//                   await AsyncStorage.setItem('ingelogd', 'false');
//                   await AsyncStorage.removeItem('bearerToken');
//                   navigation.push('Home');
//               }
//               if (response.status == 429) {
//                   // console.log('Overloaded, wait a couple of seconds and try again.');
//                   alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
//               } else if (response.data.success) {
//                   // console.log('Pushed');
//               };
//           }
//           catch (err) {
//               // console.log(err);
//           };
//           return;
//       } else if (response.status == 429) {
//           // console.log('Overloaded, wait a couple of seconds and try again.');
//           alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
//           return;
//       } if (response.data.success) {
//           //// console.log('Winkelwagen opslaan gelukt');
//           const winkelwagen = response.data.data;
//           //// console.log(winkelwagen);

//           cartDispatch({ type: SET_CART, value: winkelwagen });
//           //return winkelwagen;
//       } else {
//           // console.log('Niet gelukt')
//       }
  }
  setTimeout(function(){
    setLoading(false);
  }, 500);


  const { styles, deviceSize } = useStyles();

  return useMemo(()=> {

    if( loading ){
      return <Loader/>;
    }
    else{
  return (
    <View style={styles('main')}>
      <View style={styles('container')}>
        <View style={styles('navbar')}>
          <View style={styles('header')}>
            <View>
              <Text style={styles('siteTitle')}>Algemene Voorwaarden</Text>
            </View>
            <View style={styles('menuItems')}>
              <TouchableOpacity style={styles('menuItem')} onPress={() => navigation.push('shopScreen')}>
                <Text style={styles('menuItemText')}><AntDesign name="left" size={20} color="white" style={styles('indicator')} />Terug naar shop</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row'}}>
            <View>
                
            </View>
            <View>
                
            </View>
        </View>
      </View>
      <View style={{ maxWidth: 1600, width: '96.5%',}}>
        <Footer navigation={navigation}/>
        </View> 
    </View>
  );
}
  }, [loading])//Voeg de states toe zodat ze ook worden opgeslagen als er wat gewijzigd word in de inputs
}


const useStyles = CreateResponsiveStyle(
  {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white'
    },
    container: {
      width: '100%',
      maxWidth: 1600,
      padding: 25
    },
    header: {
      width: '100%'
    },
    navbar: {
      backgroundColor: '#62BAE3',
      padding: 30,
    },
    menuItem: {
      marginBottom: 10
    },
    menuItemText: {
      fontSize: 20,
      fontWeight: 'bold',
      color: 'white'
    },
    subMenuItemText: {
      fontSize: 18,
      fontWeight: 'bold',
      color: Colors[7]
    },
    navsubbar: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      marginVertical: 15,
      marginBottom: 50,
      paddingHorizontal: 25
    },
    navbaritems: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    subItem: {
      marginVertical: 10,
      position: 'relative'
    },
    indicator: {
      position: 'absolute',
      left: -20,
      top: 0
    },
    logout: {
      marginTop: 10
    },
    siteTitle: {
      fontSize: 28,
      marginBottom: 20,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: 'white'
    },
    orderList: {
      marginTop: 25
    },
    card: {
      width: '100%',
      borderWidth: 1,
      borderColor: Colors[8],
      marginBottom: 20
    },
    imageColumn: {
      width: '100%',
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    },
    imageStyle: {
      width: '100%',
      height: 220,
      resizeMode: 'contain'
    },
    productInfo: {
      paddingHorizontal: 35
    },
    orderMeta: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    },
    title: {
      fontSize: 22,
      fontWeight: 'bold'
    },
    meta: {
      fontSize: 16,
      marginVertical: 12,
      marginRight: 35,
      color: '#5A5A5A'
    },
    productPrice: {
      padding: 20,
      flexDirection: 'row',
      textAlign: 'left'
    },
    price: {
      fontSize: 22,
      fontWeight: 'bold',
      alignSelf: 'center'
    },
    aantal: {
      fontSize: 18,
      fontWeight: '500',
      alignSelf: 'center'
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 25
      },
      card: {
        width: '100%',
        height: 200,
        borderWidth: 1,
        borderColor: Colors[8],
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
      },
      imageColumn: {
        width: '15%',
        height: 200,
        justifyContent: 'center',
        borderRightWidth: 1,
        borderColor: Colors[8],
      },
      productInfo: {
        width: '55%'
      },
      productPrice: {
        paddingRight: 35,
      }
    },
    [DEVICE_SIZES.LARGE_DEVICE]: {
      siteTitle: {
        marginBottom: 0
      },
      header: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      menuItems: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      menuItem: {
        marginBottom: 0,
        marginLeft: 35
      },
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 35
      },
      card: {
        width: '100%',
        height: 200,
        borderWidth: 1,
        borderColor: Colors[8],
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
      },
      imageColumn: {
        width: '20%',
        justifyContent: 'center',
        height: 200,
        borderRightWidth: 1,
        borderColor: Colors[8],
      },
      productInfo: {
        width: '65%'
      },
      productPrice: {
        paddingRight: 35,
        textAlign: 'right'
      }
    },
    [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
      siteTitle: {
        marginBottom: 0
      },
      header: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      menuItems: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      menuItem: {
        marginBottom: 0,
        marginLeft: 35
      },
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 45
      },
      card: {
        width: '100%',
        height: 200,
        borderWidth: 1,
        borderColor: Colors[8],
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
      },
      imageColumn: {
          width: '20%',
          height: 200,
          justifyContent: 'center',
          borderRightWidth: 1,
          borderColor: Colors[8],
      },
      productInfo: {
          width: '65%'
      },
      productPrice: {
          paddingRight: 35,
          textAlign: 'right'
      }
    },
  },
)
