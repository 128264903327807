import React, { useState, useEffect, useContext} from 'react';
import { Text, View, TouchableOpacity, ActivityIndicator, Image} from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import api from '../settings/AxiosSetup';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState.jsx';
import { SET_CART } from '../context/actions/winkelActions';

//Components
import ShopItem from '../components/shopItem';
import AankoopModal from '../components/aankoopModal';
import Footer from '../components/footer';

// Styling
import Colors from '../styles/colors';

//Icons
import { AntDesign } from '@expo/vector-icons'; 

export default function ShopScreen({ navigation }) {

  const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';
  const cartContext = useContext(GlobalContext).cartState;
  const cartDispatch = useContext(GlobalDispatcher).cartDispatch;
  const cartItems = cartContext.cartItems.winkelwagen;
  const [isModalVisible, setModalVisible] = useState(false);
  const [artikelNaam, setArtikelNaam] = useState('');
  const [data, setData] = useState(null);

  const fetchProducts = async () => {
    try {
      const response = await api.get('product?code='+ code);
      // Success
      if (response.data.success) {
        setData(response.data.data);
        //// console.log(response.data.data);
      }
    } catch (error) {
      // Error
      if (error.response) {
        if (error.response.status === 401) {
          // console.log('Geen toegang, niet ingelogd');
          await AsyncStorage.setItem('ingelogd', 'false');
          await AsyncStorage.removeItem('bearerToken');
          navigation.push('EersteScherm');
        }
        if (error.response.status === 429) {
          // console.log('Overloaded, wait a couple of seconds and try again.');
          alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
        }
        if (error.response.status === 500) {
          // console.log('Er is iets fout gegaan');
        }
      }
    }
  }

  // const fetchWinkelmand = async () => {
  //   const response = await AsyncStorage.getItem('cartItems');
  //   // console.log(response);
  //   if(response){
  //     cartDispatch({ type: SET_CART, value: JSON.parse(response)});
  //   } else {

  //   }
  // };

  useEffect(() => {
    fetchProducts();
  },[]);

  const { styles, deviceSize } = useStyles();

  return (
    <View style={styles('main')}>
      <View style={styles('container')}>
      <View style={styles('logobar')}>
          <Image source={require('../images/HemmervenLogo.png')} style={styles('imageStyle')} />
        </View>
        <View style={styles('navbar')}>
          <View style={styles('header')}>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={() => location.replace('https://www.hemmerven.nl/')} style={{ marginRight: 20}}><Text style={styles('siteTitle')}>Home</Text></TouchableOpacity>
            <Text style={styles('siteTitle')}>Shop</Text>
            </View>
            <View style={styles('menuItems')}>
                <TouchableOpacity style={styles('menuItem')} onPress={() => navigation.push('Winkelmand')}>
                  <Text style={styles('menuItemText')}>{cartItems ? 'Winkelmand(' + cartItems.length + ')' : 'Winkelmand (0)'}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles('menuItem')} onPress={() => navigation.push('shopScreen')}>
                  <Text style={styles('menuItemText')}><AntDesign name="right" size={20} color="white" style={styles('indicator')}/>Shop</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles('menuItem')} onPress={() => navigation.push('GezinsScreen', { refresh: 1, activeTab: 0})}>
                  <Text style={styles('menuItemText')}>Account</Text>
                </TouchableOpacity>
                {/* <TouchableOpacity style={styles('menuItem')} onPress={() => window.close()}>
                  <Text style={styles('menuItemText')}>Terug naar 't Hemmerven</Text>
                </TouchableOpacity> */}
            </View>
          </View>
        </View>
        <AankoopModal navigation={navigation} isModalVisible={isModalVisible} setVisible={() => setModalVisible(false)} artikelNaam={artikelNaam}/>

        <View style={styles('grid')}>
          { data ?
            data.map((item, index) => {
                return <ShopItem 
                  key={index}
                  navigation={navigation} 
                  id={item.id}
                  images={item.afbeeldingen}
                  uitverkocht={item.uitverkocht}
                  uitverkochtLabel={item.uitverkocht_label}
                  title={item.naam}
                  caption={item.omschrijving}
                  price={item.prijs}
                  setVisible={() => [setModalVisible(true), setArtikelNaam(item.naam)]}
                  priceInfo={item.prijs_omschrijving}
                  selectLabel={item.selectLabel}
                  options={item.variaties}
                  personen={item.personen}
                  maxAantal={item.aantal}
                  style={styles('shopItem')} 
                />
              }) : <ActivityIndicator color={Colors[8]} size="large" style={{ marginTop: 150, textAlign: 'center' }} /> 
          }
        </View>
        <View style={{ marginTop: 220, maxWidth: 1600}}>
          <Footer navigation={navigation}/>
        </View> 
      </View>
    </View>
  );
}


const useStyles = CreateResponsiveStyle(
  {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#E8E8E8'
    },
    container: {
      width: '100%',
      maxWidth: 1600,
      padding: 25,
      backgroundColor: 'white'
    },
    header: {
      width: '100%'
    },
    navbar: {
      backgroundColor: '#62BAE3',
      padding: 30,
      width: '103.3%',
      right: 25
    },
    menuItem: {
      marginBottom: 10
    },
    menuItemText: {
      fontSize: 20,
      fontWeight: 'bold',
      color: 'white'
    },
    subMenuItemText: {
      fontSize: 18,
      fontWeight: 'bold',
      color: Colors[7]
    },
    logobar: {
      width: '100%',
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imageStyle: {
      width: 250,
      height: 150,
      resizeMode: 'contain'
    },
    navsubbar: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      marginVertical: 15,
      marginBottom: 50,
      paddingHorizontal: 25
    },
    navbaritems: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    subItem: {
      marginVertical: 10,
      position: 'relative'
    },
    indicator: {
      position: 'absolute',
      left: -20,
      top: 0
    },
    logout: {
      marginTop: 10
    },
    siteTitle: {
      fontSize: 28,
      marginBottom: 20,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: 'white'
    },
    grid: {
      marginTop: 25,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 25
      },
      grid: {
        gap: 20
      }
    },
    [DEVICE_SIZES.LARGE_DEVICE]: {
      siteTitle: {
        marginBottom: 0
      },
      header: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      menuItems: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      menuItem: {
        marginBottom: 0,
        marginLeft: 35
      },
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 35
      },
      grid: {
        gap: 20
      }
    },
    [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
      siteTitle: {
        marginBottom: 0
      },
      header: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      menuItems: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      menuItem: {
        marginBottom: 0,
        marginLeft: 35
      },
      navbaritems: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      subItem: {
        marginRight: 45
      },
      grid: {
        gap: 20
      }
    },
  },
)
