// Libraries
import React, { useContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../settings/AxiosSetup';

// Components
import { setData } from '../../functions/storage';

// maak hier alle constanten aan die je in actions wilt gebruiken
export const SET_CART = 'SET_CART';
export const SET_CART_PREVIOUS_SESSION = 'SET_CART_PREVIOUS_SESSION';

const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';

/*
const apiSetter = async (jsonData) => {
    //// console.log(jsonData);
    const response = await api.get('winkelwagen?code=' + code + '&json=' + encodeURIComponent(JSON.stringify(jsonData)));
    // console.log(response.data);
    if (response.status == 401) {
        try {
            const response = await api.post('uitloggen?code=' + code);
            // console.log(response.data);
            if (response.status === 401) {
                await AsyncStorage.setItem('ingelogd', 'false');
                await AsyncStorage.removeItem('bearerToken');
                await AsyncStorage.removeItem('cartItems');
                navigation.push('EersteScherm');
            }
            if (response.status == 429) {
                // console.log('Overloaded, wait a couple of seconds and try again.');
                alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
            } else if (response.data.success) {
                await AsyncStorage.setItem('ingelogd', 'false');
                await AsyncStorage.removeItem('bearerToken');
                await AsyncStorage.removeItem('cartItems');
                navigation.push('EersteScherm');
            };
        }
        catch (err) {
            // console.log(err);
        };
        return;
    } else if (response.status == 429) {
        // console.log('Overloaded, wait a couple of seconds and try again.');
        alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');
        return;
    } if (response.data.success) {
        //// console.log('Gelukt');
        const winkelwagen = response.data.data;
        setData('cartItems', winkelwagen);
        // console.log(winkelwagen);
        //return winkelwagen;
    } else {
        // console.log('Niet gelukt')
    }
}*/


// Alle initiële waardes
export const cartInitialState = {
    itemsCount: 0,
    cartItems: {
        winkelwagen: [],
        sponsor_bedrag: ""
    },

}

// actions, om fouten te voorkomen gebruik je uitsluitende constanten
export const cartActions = ( state, action ) => {
    //// console.log(action);
    //// console.log(cartInitialState);

    switch ( action.type ) {
        case SET_CART:
            //// console.log(action.value);

            setData('cartItems', action.value);

            //apiSetter(action.value);
            //// console.log(winkelwagen);

            return { ...state, cartItems: action.value };
        case SET_CART_PREVIOUS_SESSION:
            return{ ...state, cartItems: action.value }  
        default:
            return state;
    }

}
