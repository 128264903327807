// Libraries
import React, { useState, useContext } from 'react';
import { Dimensions, StyleSheet, ScrollView, TouchableOpacity, Image, Text, View, ImageBackground } from 'react-native';
import api from '../settings/AxiosSetup';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Styles
import Colors from '../styles/colors';

//Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState.jsx';

// Components
import { HVButton, HVInput } from '../components/formObjects';

export default function EmailBevestigen({ navigation, route}) {
    const commonContext = useContext(GlobalContext).commonState;
    const code = 'df2asd5L3Ks2@d$FD3sds5FD@$dsqJ9sasa$jfSxxcxA5r22z';

    // LoginVariables
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [email, setEmail] = useState(route.params.email);

    // UserVariables
    const [confirmCode, setConfirmCode] = useState('');

    const postEmailConfirm = async () => {
        try {
            const response = await api.post('verifieer?code=' + code + '&verificatie_code=' + confirmCode);
            // console.log(response.data);
            if (response.status == 429) {

                // console.log('Overloaded, wait a couple of seconds and try again.');
                alert('Status 429, de server heeft het druk. Wacht een paar minuten en probeer het opnieuw');

            }
            if (response.data.success) {
                setSuccessMessage(response.data.message);
            } else {
                setErrorMessage(response.error)
            }
        } catch (err) {
            // console.log(err);
        }
    }
    return (
        <View>
            <View>
                <View style={{ zIndex: 2, width: 480, backgroundColor: 'white', padding: 50 }}>
                    <Text style={{ color: Colors[8], fontWeight: '700', paddingBottom: 10, fontFamily: 'Regular', fontSize: 26, alignSelf: 'flex-start' }}>{'EMAIL BEVESTIGEN'}</Text>
                    {errorMessage || successMessage ? <Text style={{ fontWeight: '500', fontSize: 14, color: successMessage ? Colors[7] : 'red', alignSelf: 'flex-start', textAlign: 'left', marginTop: 10, marginBottom: 5}}>{successMessage ? successMessage : errorMessage}</Text> : null}
                    {successMessage ? null :<Text style={{ fontWeight: '500', fontSize: 14, color: Colors[7], alignSelf: 'flex-start', textAlign: 'left', marginBottom: 20 }}>{'Bedankt voor het registreren. Er is een code gestuurd naar het volgende email: '+ email +'. Vul deze hieronder in.'}</Text>}
                    <View style={{ flexDirection: 'row', alignItems: 'flex-start ' }}>
                        <Text style={{ fontWeight: '700', fontSize: 14, color: Colors[7] }}>{'Code'}</Text>
                        <Text style={{ fontWeight: '700', fontSize: 14, color: 'red', marginLeft: 8, }}>{'*'}</Text>
                    </View>
                    <View style={{ marginBottom: 10, marginTop: -5 }}>
                        <HVInput onChangeText={(input) => setConfirmCode(input)} value={confirmCode} border={true} placeHolder={'Code'} width={'100%'} />
                    </View>
                    {successMessage ? null : <HVButton caption={'Email bevestigen'} color={Colors[8]} width={'100%'} onPress={() => postEmailConfirm()} />}
                    <TouchableOpacity style={{ marginTop: 30 }} onPress={() => navigation.push('Login')}>
                        <Text style={{ color: Colors[7], fontFamily: 'Regular', fontSize: 16, alignSelf: 'center' }}>Terug naar inloggen</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white'
    },
    title: {
        fontFamily: 'Regular', fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});
