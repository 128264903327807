// Libraries
import React, { useReducer } from 'react';

// Global Context en Dispatcher
export const GlobalContext = React.createContext({});
export const GlobalDispatcher = React.createContext({});

// importeer alle Actions
import { commonActions, commonInitialState } from './actions/commonActions';
import { userActions, userInitialState } from './actions/userActions';
import { cartActions, cartInitialState } from './actions/winkelActions';

const GlobalState = props => {

  // Maak Reducers voor alle actions
  const [ commonState, commonDispatch ] = useReducer( commonActions, commonInitialState );
  const [ userState, userDispatch ] = useReducer( userActions, userInitialState );
  const [ cartState, cartDispatch ] = useReducer( cartActions, cartInitialState );


  // In de context plaats je alle states 
  // en in de dispatcher alles dispatches
  return(
    <GlobalContext.Provider value={{ commonState, userState, cartState }}>
      <GlobalDispatcher.Provider value={{ commonDispatch, userDispatch, cartDispatch }}>
        { props.children }
      </GlobalDispatcher.Provider>
    </GlobalContext.Provider>
  )
}

export default GlobalState;
