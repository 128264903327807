// Libraries
import React, { useContext, useState, forwardRef} from 'react';
import { View, Text, TouchableOpacity, TextInput, StyleSheet, Dimensions} from 'react-native';


// Components
import IconSetter from './iconSetter';

// Styles
import Colors from '../styles/colors';

// Global
import { GlobalContext } from '../context/globalState';


export function HVButton ( props ) {
    const commonContext = useContext( GlobalContext ).commonState;
    const primaryColor = 'blue';
    const secondaryColor = 'red';
    const color = props.color || primaryColor || Colors[8];
    const padding = props.padding || 0;
    const alt = props.alt || false;
    const onPress = props.onPress;
    const fontSize = props.fontSize || 16;
    const width = props.width || '80%';
    const height = props.height || 50;
    const caption = props.caption || 'Forgot Caption';
    const insideOut = props.insideOut || false;
    const icon = props.icon;
    const widthStyle = props.widthStyle;
    const disabled = props.disabled;

    return(
        <TouchableOpacity disabled={disabled} onPress={onPress} style={[{ height: height, backgroundColor: alt ? secondaryColor : insideOut ? 'white' : color, alignContent: 'center', justifyContent: 'center',  flexDirection: 'row', alignItems: 'center'}, insideOut ? { borderWidth: 1.5, borderColor: primaryColor} : {}, widthStyle ? widthStyle : {maxWidth: width}, {padding: padding}]}>
            { icon ? <IconSetter family='Fontello' name={icon} size={16} color={primaryColor}/>: null }
            <Text style={{ marginLeft: icon ? 10: 0, fontFamily: 'Regular', fontSize: fontSize, fontWeight: '700', color: insideOut || alt ? primaryColor : 'white', alignSelf: 'center'}}>{caption}</Text>
        </TouchableOpacity>
    )
}

export function AltButton ( props ) {
    const commonContext = useContext( GlobalContext ).commonState;
    const primaryColor = 'blue';
    const color = props.color
    const secondaryColor = 'red';
    const altColor = props.altColor
    const alt = props.alt || false;
    const onPress = props.onPress;
    const width = props.width || '80%';
    const caption = props.caption || 'Forgot Caption';
    const insideOut = props.insideOut || false;
    const icon = props.icon;
    const widthStyle = props.widthStyle;

    return(
        <TouchableOpacity onPress={onPress} style={[{ height: 50, backgroundColor: alt ? altColor : insideOut ? 'white' : color, alignContent: 'center', justifyContent: 'center',  flexDirection: 'row', alignItems: 'center'}, insideOut ? { borderWidth: 1.5, borderColor: color} : {}, widthStyle ? widthStyle : {width: width},]}>
            { icon ? <IconSetter family='Fontello' name={icon} size={16} color={color}/>: null }
            <Text style={{ marginLeft: icon ? 10: 0, fontFamily: 'Regular', fontSize: 16, fontWeight: '700', color: insideOut || alt ? color : 'white', alignSelf: 'center'}}>{caption}</Text>
        </TouchableOpacity>
    )
}

export function HVCheckbox(props) {
    const commonContext = useContext(GlobalContext).commonState;
    const [checked, setChecked] = useState(props.standard ? props.standard : false);
    const primaryColor = 'blue';
    const onPress = props.onPress;

    return (
        <View style={{ flexDirection: 'row', marginVertical: 10 }}>
            <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => [setChecked(!checked), onPress ? onPress() : null]}>
                <View style={checked ? { marginTop: 2, height: 15, width: 15, backgroundColor: Colors[8], borderWidth: 1, borderColor: 'black' } : { marginTop: 2, height: 15, width: 15, borderWidth: 1, borderColor: 'black'}}>
                    {checked ? <View style={{ alignItems: 'center'}}><IconSetter size={12} family='Ionicons' name='checkmark' color={'white'}/></View>  : null}
                </View>
                <Text style={{ marginLeft: 5, fontWeight: '700', fontSize: 14, color: Colors[1] }}>{props.children}</Text>
            </TouchableOpacity>
        </View>
    );
}

export const HVInput = forwardRef((props, ref) => {
    const register = props.register;
    const height = props.height;
    const searchBar = props.searchBar || false;
    const placeHolder = props.placeHolder;
    const secureToggle = props.secureToggle;
    const password = props.password;
    const onPress = props.onPress;
    const onEndEditing = props.onEndEditing;
    const onChangeText = props.onChangeText;
    const border = props.border;
    const onEndEdit = props.onEndEdit;
    const width = props.width || '50%';
    const maxLength = props.maxLength || 200;
    const multiLine = props.multiLine || false;
    const searchClear = props.searchClear;
    const inputX = props.inputX;
    const value = props.value;
    return (
        <View style={{width: width}}>
            <TextInput ref={ref} value={value} secureTextEntry={secureToggle} autoCapitalize={'none'} multiline={ multiLine ? true : false} style={ multiLine ?  [styles.inputWithMultiLine, height ? {height: height, textAlignVertical: 'top'} : {}] : border ? styles.inputWithBorder  : styles.input } placeholder={placeHolder} onChangeText={onChangeText} onSubmitEditing={ password ? onEndEdit ? onEndEdit : null : onPress} maxLength={maxLength}/>
            {searchBar ? 
            <TouchableOpacity style={{ height: 24, width: 24, position: 'absolute', top: 26, right: 10}} onPress={onPress}>
                <IconSetter family='Fontello' name='search' size={13}/>
            </TouchableOpacity>: null}
            { searchBar ? 
            <TouchableOpacity style={{ height: 24, width: 24, position: 'absolute', top: 26.5, right: 28}} onPress={searchClear}>
                <IconSetter family='Fontello' name='close-alt' size={12} color={Colors[8]}/>
            </TouchableOpacity>
            : null }
            {password ? 
            <TouchableOpacity style={{ height: 24, width: 24, position: 'absolute', top: 25, right: 18}} onPress={onPress}>
                {secureToggle ? <IconSetter family='Fontello' name='showpassword' size={14} color={Colors[8]} /> : <IconSetter family='Fontello' name='hidepassword' size={16} color={Colors[8]}/>}
             </TouchableOpacity>: null}
        </View>
    )
});

const styles = StyleSheet.create({
    input: {
        fontFamily: 'Regular', fontSize: 15,
        backgroundColor: 'white',
        color: 'black',
        marginVertical: 10,
        padding: 10,
    },
    inputWithBorder: {
        fontFamily: 'Regular', fontSize: 15,
        backgroundColor: 'white',
        color: 'black',
        marginVertical: 10,
        padding: 10,
        borderWidth: 1.5, 
        borderColor: Colors[1]
    },
    inputWithMultiLine: {
        fontFamily: 'Regular', fontSize: 15,
        backgroundColor: 'white',
        color: 'black',
        margin: 12,
        
        padding: 10,
        borderWidth: 1.5, 
        borderColor: Colors[1]
    }
  });