// Libraries 
import React, { useContext, useEffect, useState} from 'react';
import { View, TouchableOpacity, Text } from 'react-native';

// Styling
import Colors from '../styles/colors';

export default function Footer (props) {
    const navigation = props.navigation;

    return (
        <View style={{ backgroundColor: Colors[8], flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: 57, alignItems: 'center', paddingHorizontal: 50}}>
            <Text style={{color: 'white', fontSize: 14, fontWeight: 'bold'}}>{"COPYRIGHT 2023 'T HEMMERVEN"}</Text>
            <View style={{flexDirection: 'row'}}>
                <TouchableOpacity onPress={() => window.open('https://cms.hemmerven.nl/privacyverklaring-hemmerven.pdf')} style={{ marginRight: 25 }}>
                    <Text style={{color: 'white', fontSize: 14, fontWeight: 'bold'}}>{'Privacyverklaring'}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation.push('Contact')} >
                    <Text style={{color: 'white', fontSize: 14, fontWeight: 'bold'}}>{'Contact'}</Text>
                </TouchableOpacity>
                {/* <TouchableOpacity onPress={() => navigation.push('Retourbeleid')} style={{ marginHorizontal: 33}}>
                <Text style={{color: 'white', fontSize: 14}}>{'Retourbeleid'}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation.push('AlgemeneVoorwaarden')}>
                <Text style={{color: 'white', fontSize: 14}}>{'Algemene voorwaarden'}</Text>
                </TouchableOpacity> */}
            </View>
        </View>
    )
}