// Libraries
import React, { useContext, useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Settings
import api from '../settings/AxiosSetup';

// Functions 
import { setData, getData } from '../functions/storage';

// Global
import { GlobalContext, GlobalDispatcher } from '../context/globalState.jsx';
import { SET_CART } from '../context/actions/winkelActions';

export default async function WinkelMandFetch (data) {
  const cartDispatch = useContext( GlobalDispatcher ).cartDispatch;
  const cartContext = useContext(GlobalContext).cartState;  
  const commonContext = useContext(GlobalContext).commonState;

  const fetchWinkelmand = async () => {
    const response = await AsyncStorage.getItem('cartItems');
    //// console.log(response);
    //// console.log("winkelmand fetch");
    if(response){
      cartDispatch({ type: SET_CART, value: JSON.parse(response)});
    }
  };

  fetchWinkelmand();
}