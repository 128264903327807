// Libraries
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity, TouchableWithoutFeedback, StyleSheet, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import api from '../settings/AxiosSetup';

// Components
import { HVButton, HVCheckbox, HVInput, AltButton } from '../components/formObjects';
import DropDownHeader from '../components/dropDown';
import IconSetter from '../components/iconSetter';


// Global
import { GlobalDispatcher, GlobalContext } from '../context/globalState';

// Styles
import Colors from '../styles/colors';


export default function EersteScherm ({ navigation }) {

    const { styles, deviceSize } = useStyles();

    return (
        <View style={styles('main')}>
            <View style={styles('login')}>
                <Image style={{ alignSelf: 'center', backgroundColor: Colors[0], width: 295, height: 166, marginVertical: 40 }} source={require('../images/HemmervenLogo.png')}/>
                <View style={{ marginBottom: 15 }} />
                <HVButton caption={'Inloggen'} color={Colors[8]} width={'100%'} onPress={() => navigation.push('Login')} />
                <View style={{ marginBottom: 15 }} />
                <HVButton caption={'Account aanmaken'} color={Colors[7]} width={'100%'} onPress={() => navigation.push('Registreer')} />
                <View style={{ marginVertical: 5 }} />
                <TouchableOpacity style={{ zIndex: 2, flexDirection: 'row', alignSelf: 'center', position: 'absolute', bottom: -30, zIndex: 1 }} onPress={() => location.replace('https://www.hemmerven.nl/')}>
                    <View style={{ marginRight: 5, bottom: -4 }}>
                        <IconSetter name='back' family='Fontello' size={14} color={Colors[7]} />
                    </View>
                    <Text style={{ color: Colors[7], fontFamily: 'Regular', fontSize: 16 }}>{"Terug naar t' Hemmerven "}</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}
const useStyles = CreateResponsiveStyle(
    {
        main: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F2F2F2'
        },
        login: {
            marginTop: 50,
            backgroundColor: 'white', 
            padding: 35,
            width: '100%',
            maxWidth: 500
        }
    },
    {
      [DEVICE_SIZES.MEDIUM_DEVICE]: {
        
      },
      [DEVICE_SIZES.LARGE_DEVICE]: {
        
      },
      [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
        
      },
    },
  )